import React from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import PrintIcon from '@material-ui/icons/Print';
import ExitIcon from "@material-ui/icons/ExitToApp";
import Link from "@material-ui/icons/Link";
import LinkOff from "@material-ui/icons/LinkOff";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import Repeat from "@material-ui/icons/Repeat";
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import DescriptionIcon from '@material-ui/icons/Description';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RepeatIcon from '@material-ui/icons/Repeat';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const C1FormButtons = ({ options, children }) => {

    const history = useHistory();

    //Application saved as draft
    let elSave = null;
    if (options.save && options.save.show) {
        elSave = <Tooltip title="Save">
            <IconButton aria-label="save" color="primary" type="button" onClick={options.save.eventHandler}>
                <SaveIcon />
            </IconButton>
        </Tooltip>;
    }


    //Application submit form
    let elSubmit = null;
    if (options.submit) {
        elSubmit = <Tooltip title="Submit">
            <IconButton aria-label="Submit" type="submit" color="primary">
                <AssignmentTurnedIn />
            </IconButton>
        </Tooltip>;
    }

    //Application submit onClick
    let elSubmitBtn = null;
    if (options.submitOnClick && options.submitOnClick.show) {
        elSubmitBtn =
            <Tooltip title="Submit">
                <IconButton aria-label="Submit" type="button" color="primary" onClick={options.submitOnClick.eventHandler}>
                    <AssignmentTurnedIn />
                </IconButton>
            </Tooltip>;
    }

    //Application print only applicable for approved application
    let elPrint = null;
    if (options.print && options.print.show) {
        elPrint = <Tooltip title="Print">
            <IconButton aria-label="print" color="primary" onClick={options.print.eventHandler}>
                <PrintIcon />
            </IconButton>
        </Tooltip>;
    }

    //Application approval action
    let elApprove = null;
    if (options.approve && options.approve.show) {
        elApprove = <Tooltip title="Approve">
            <IconButton aria-label="approve" color="primary" onClick={options.approve.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    //Application reject action
    let elReject = null;
    if (options.reject && options.reject.show) {
        elReject = <Tooltip title="Reject">
            <IconButton aria-label="reject" color="primary" type="button" onClick={options.reject.eventHandler}>
                <CloseIcon />
            </IconButton>
        </Tooltip>;
    }

    //Application verify action
    let elVerify = null;
    if (options.verify && options.verify.show) {
        elVerify = <Tooltip title="Verify">
            <IconButton aria-label="Verify" color="primary" onClick={options.verify.eventHandler}>
                <Repeat />
            </IconButton>
        </Tooltip>;
    }

    //Application return action
    let elReturn = null;
    if (options.returnApp && options.returnApp.show) {
        elReturn = <Tooltip title="Return">
            <IconButton aria-label="return" color="primary" onClick={options.returnApp.eventHandler}>
                <KeyboardReturnIcon />
            </IconButton>
        </Tooltip>;
    }

    //Duplicate application
    let elDuplicate = null;
    if (options.duplicate && options.duplicate.show) {
        elDuplicate = <Tooltip title="Duplicate">
            <IconButton aria-label="duplicate" color="primary" onClick={options.duplicate.eventHandler}>
                <FileCopyIcon />
            </IconButton>
        </Tooltip>;
    }

    //Activate action
    let elActivate = null;
    if (options.activate && options.activate.show) {
        elActivate = <Tooltip title="Activate">
            <IconButton aria-label="activate" color="primary" onClick={options.activate.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    //Deactivate action
    let elDeactivate = null;
    if (options.deactivate && options.deactivate.show) {
        elDeactivate = <Tooltip title="Deactivate">
            <IconButton aria-label="deactivate" color="primary" onClick={options.deactivate.eventHandler}>
                <LinkOff />
            </IconButton>
        </Tooltip>;
    }

    //Application template download
    let elDownloadTemplate = null;
    if (options.downloadTemplate && options.downloadTemplate.show) {
        elDownloadTemplate = <Tooltip title="Download">
            <IconButton aria-label="download" type="button" color="primary" disabled={options.downloadTemplate.path === ''}
                href={options.downloadTemplate.path} target="_blank" download>
                <GetAppIcon />
            </IconButton>
        </Tooltip>;
    }

    //Upload whole application template
    let elUploadTemplate = null;
    if (options.uploadTemplate && options.uploadTemplate.show) {
        elUploadTemplate = <Tooltip title="Upload">
            <IconButton aria-label="upload" type="button" color="primary"
                disabled={options.downloadTemplate && options.downloadTemplate.path === ''}
                onClick={options.uploadTemplate.eventHandler}>
                <PublishIcon />
            </IconButton>
        </Tooltip>;
    }

    //Print preview of the application output cert or document.
    let elPrintPreview = null;
    if (options.printPreview && options.printPreview) {
        elPrintPreview = <Tooltip title={`View ${options.printPreview.docName}`}>
            <IconButton aria-label={`View ${options.printPreview.docName}`} type="button" onClick={options.printPreview.eventHandler} >
                <ReceiptIcon />
            </IconButton>
        </Tooltip>
    }

    //DetailsPreview is for the summary details preview
    let elAppPreview = null;
    if (options.appPreview && options.appPreview.show) {
        elAppPreview = <Tooltip title="Details Preview">
            <IconButton aria-label="Details Preview" type="button" color="primary" onClick={options.appPreview.eventHandler} >
                <DescriptionIcon />
            </IconButton>
        </Tooltip>;
    }

    //Download all supporting documents
    let elDlSuppDocs = null;
    if (options.downloadSd && options.downloadSd.show) {
        elDlSuppDocs = <Tooltip title="Download Supporting Docs">
            <IconButton aria-label="Download Supporting Docs" type="button" color="primary"
                href={options.downloadSd.path} target="_blank" download>
                <AttachmentIcon />
            </IconButton>
        </Tooltip>;
    }


    let elBack = null;
    if (options.back && options.back.show) {
        elBack = <Tooltip title="Exit">
            <IconButton aria-label="exit" type="button" onClick={options.back.eventHandler || history.goBack()} color="secondary">
                <ExitIcon />
            </IconButton>
        </Tooltip>;
    }

    let elCancel = null;
    if (options.cancel && options.cancel.show) {
        elCancel = <Tooltip title="Exit">
            <IconButton aria-label="exit" type="button" onClick={options.cancel.eventHandler || history.goBack()} color="secondary">
                <ExitIcon />
            </IconButton>
        </Tooltip>;
    }

    //Review action
    let elAccept = null;
    if (options.accept && options.accept.show) {
        elAccept = <Tooltip title="Accept">
            <IconButton aria-label="accept" color="primary" onClick={options.accept.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    //Validate action
    let elValidate = null;
    if (options.validate && options.validate.show) {
        elValidate = <Tooltip title="Validate">
            <IconButton aria-label="validate" color="primary" onClick={options.validate.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    //Regulator Report action
    let elRegReport = null;
    if (options.regReport && options.regReport.show) {
        elRegReport = <Tooltip title="Submit Report">
            <IconButton aria-label="regReport" color="primary" onClick={options.regReport.eventHandler}>
                <AssignmentTurnedIn />
            </IconButton>
        </Tooltip>;
    }

    //Recommend action
    let elRecommend = null;
    if (options.recommend && options.recommend.show) {
        elRecommend = <Tooltip title="Recommend">
            <IconButton aria-label="recommend" color="primary" onClick={options.recommend.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    let elWithdraw = null;
    if (options.withdraw && options.withdraw.show) {
        elWithdraw = <Tooltip title="Withdraw">
            <IconButton aria-label="withdraw" color="primary" onClick={options.withdraw.eventHandler}>
                <KeyboardBackspaceIcon />
            </IconButton>
        </Tooltip>;
    }
    
    //Confirm action
    let elConfirm = null;
    if (options.confirm && options.confirm.show) {
        elConfirm = <Tooltip title="Confirm">
            <IconButton aria-label="confirm" color="primary" onClick={options.confirm.eventHandler}>
                <CheckIcon />
            </IconButton>
        </Tooltip>;
    }

    //Appeal
    let elAppeal = null;
    if (options.appeal && options.appeal.show) {
        elAppeal = <Tooltip title="Appeal">
            <IconButton aria-label="appeal" color="primary" onClick={options.appeal.eventHandler}>
                <FileCopyIcon />
            </IconButton>
        </Tooltip>;
    }

    return (
        <React.Fragment>
            <div className="flex items-center" >
                <div className="flex-grow"></div>
                {elSave}
                {elSubmit}
                {elSubmitBtn}
                {elPrint}
                {elReturn}
                {elVerify}
                {elReject}
                {elApprove}
                {elActivate}
                {elDeactivate}
                {elDuplicate}
                {elDownloadTemplate}
                {elUploadTemplate}
                {elPrintPreview}
                {elAppPreview}
                {elDlSuppDocs}
                {elAccept}
                {elValidate}
                {elRegReport}
                {elRecommend}
                {elConfirm}
                {elAppeal}
                {elWithdraw}

                {children}

                {elBack}
                {elCancel}
            </div>
        </React.Fragment>
    );
}

C1FormButtons.propTypes = {
    options: PropTypes.exact({
        save: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        submit: PropTypes.bool,
        submitOnClick: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        approve: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        verify: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.eventHandler
        }),
        reject: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        duplicate: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        activate: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        deactivate: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func
        }),
        print: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func
        }),
        returnApp: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func
        }),
        downloadTemplate: PropTypes.exact({
            show: PropTypes.bool,
            path: PropTypes.string
        }),
        uploadTemplate: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        downloadSd: PropTypes.exact({
            show: PropTypes.bool,
            path: PropTypes.string
        }),
        appPreview: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func
        }),
        printPreview: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func
        }),
        back: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        cancel: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        accept: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        validate: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        regReport: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        recommend: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        confirm: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        appeal: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        }),
        withdraw: PropTypes.exact({
            show: PropTypes.bool,
            eventHandler: PropTypes.func,
        })
    })
}

C1FormButtons.defaultProps = {
    options: {
        save: {
            show: true
        },
        submit: true,
        submitOnclick: {
            show: true
        },
        approve: {
            show: true
        },
        verify: {
            show: true
        },
        reject: {
            show: true
        },
        duplicate: {
            show: true
        },
        activate: {
            show: true
        },
        deactivate: {
            show: true
        },
        print: {
            show: true
        },
        returnApp: {
            show: true
        },
        downloadTemplate: {
            show: true
        },
        uploadTemplate: {
            show: true
        },
        downloadSd: {
            show: true
        },
        appPreview: {
            show: true
        },
        printPreview: {
            show: true
        },
        back: {
            show: true
        },
        accept: {
            show: true
        },
        validate: {
            show: true
        },
        regReport: {
            show: true
        },
        recommend: {
            show: true
        },
        confirm: {
            show: true
        },
        appeal: {
            show: true
        },
        withdraw: {
            show: true
        },
        cancel: {
            show: true
        }
    }
}


export default C1FormButtons;