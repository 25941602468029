import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL
});

axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.request.use((config) => {
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (408 === error.response.status) {
      return Promise.reject({ err:{msg:sessionTimeout}});
    } else if (403 === error.response.status) {
      return Promise.reject({ err:{msg:noPermission}});
    } else {
      return Promise.reject(
        (error.response && error.response.data) || "Something went wrong!"
      );
    }
  }
);

export const sessionTimeout = "session timeout";
export const noPermission = "no permission";


export default axiosInstance;
