import React from "react";
const ManageUsers = "ManageUsers";

const configurationRoutes = [
  
  //Manage Accounts
  {
    path: "/accounts/manageAccount/list",
    component: React.lazy(() => import("./accounts/manage/ManageAccountsList")),
  },
  {
      path: "/accounts/manageAccount/:viewType/:accnId",
      component: React.lazy(() => import("./accounts/manage/ManageAccountFormDetails")),
  },
  {
    path: "/accounts/onboarding/list",
    component: React.lazy(() => import("./accounts/onboarding/AccountOnboadingList")),
  }, 
  {
      path: "/accounts/onboarding/:viewType/:paramId",
      component: React.lazy(() => import("./accounts/onboarding/AccountOnboadingForm")),
  },
  //Account Configurations
  {
      path: "/accounts/accountconfig/list",
      component: React.lazy(() => import("./accounts/configurations/AccountConfigList")),
  },
  {
      path: "/accounts/accountconfig/:viewType/:id",
      component: React.lazy(() => import("./accounts/configurations/AccountConfigFormDetails")),
  },

  //Account Applications
  {
      path: "/accounts/accountapps/list",
      component: React.lazy(() => import("./accounts/applications/AccountAppsList")),
  },
  {
      path: "/accounts/accountapps/:viewType/:id",
      component: React.lazy(() => import("./accounts/applications/AccountAppFormDetails")),
  },

  //Account Banks
  {
      path: "/accounts/accountbanks/list",
      component: React.lazy(() => import("./accounts/banks/AccountBanksList")),
  },
  {
      path: "/accounts/accountbanks/:viewType/:id",
      component: React.lazy(() => import("./accounts/banks/AccountBankFormDetails")),
  },

  //Account Contacts
  {
      path: "/accounts/accountcontacts/list",
      component: React.lazy(() => import("./accounts/contacts/AccountContactsList")),
  },
  {
      path: "/accounts/accountcontacts/:viewType/:id",
      component: React.lazy(() => import("./accounts/contacts/AccountContactFormDetails")),
  },

  //Account Activation
  {
      path: "/accounts/activation/list",
      component: React.lazy(() => import("./accounts/activation/AccountActivationList")),
  },
  {
      path: "/accounts/activation/:viewType/:id",
      component: React.lazy(() => import("./accounts/activation/AccountActivationFormDetails")),
  },

  //Account Approval
  {
      path: "/accounts/approve/list",
      component: React.lazy(() => import("./accounts/approve/AccountApproveList")),
  },
  {
      path: "/accounts/approve/:viewType/:id",
      component: React.lazy(() => import("./accounts/approve/AccountApproveFormDetails")),
  },

  // Manage Users
  {
      path: `/accountUser/${ManageUsers}/list`,
      component: React.lazy(() => import(`./users/manageusers/${ManageUsers}List`)),
  },
  {
      path: `/accountUser/${ManageUsers}/:viewType/:usrUid?`,
      component: React.lazy(() => import(`./users/manageusers/${ManageUsers}FormDetails`)),
  },

  //User Profile
  {
      path: "/profile/:viewType/:usrUid",
      component: React.lazy(() => import(`./users/manageusers/${ManageUsers}FormDetails`)),
  },

  //Update password
  {
      path: "/profile/updatePassword",
      component: React.lazy(() => import(`./users/manageusers/UpdatePasswordFormDetails`)),
  },

  // Application
  {
      path: "/accountUser/UserApplications/list",
      component: React.lazy(() => import("./users/userapplications/UserApplicationsList")),
  },
  {
      path: "/accountUser/UserApplications/:viewType/:userAppId?",
      component: React.lazy(() => import("./users/userapplications/UserApplicationsFormDetails")),
  },

  // Group
  {
      path: "/accountUser/UserGroups/list",
      component: React.lazy(() => import("./users/usergroups/UserGroupsList")),
  },
  {
      path: "/accountUser/UserGroups/:viewType/:userGroupId?",
      component: React.lazy(() => import("./users/usergroups/UserGroupsFormDetails")),
  },

  // UserRoles
  {
      path: "/accountUser/UserRoles/list",
      component: React.lazy(() => import("./users/userroles/UserRolesList")),
  },
  {
      path: "/accountUser/UserRoles/:viewType/:userRoleId?",
      component: React.lazy(() => import("./users/userroles/UserRolesFormDetails")),
  },

  // coreSysparam
  {
    path: "/core/sysparam/list",
    component: React.lazy(() => import("./core/sysparam/SysParamList")),
  },
  {
      path: "/core/sysparam/:viewType/:sysKey?",
      component: React.lazy(() => import("./core/sysparam/SysParamFormDetails")),
  },

  // role
  {
    path: "/core/role/list",
    component: React.lazy(() => import("./core/role/RoleList")),
  },
  {
      path: "/core/role/:viewType/:sysKey?",
      component: React.lazy(() => import("./core/role/RoleFormDetails")),
  },
  
];



export default configurationRoutes;
