import React from "react";
import { Assignment, FileCopy, Schedule } from "@material-ui/icons";


export const MST_CTRY_URL = "/api/co/master/entity/country/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=ctyCode&iColumns=1";
export const MST_ACCN_TYPE_URL = "/api/co/master/entity/accnType";
export const MST_CURRENCY_URL = "/api/co/master/entity/currency/list?sEcho=3&iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=ccyCode&iColumns=1";
export const MST_BANKS_URL = "/api/co/master/entity/bank";
export const MST_BANKS_BRANCH_URL = "/api/co/master/entity/bankbr";
export const MST_CONTACT_TYPE_URL = "/api/co/master/entity/contactType";
export const MST_UOM_URL = "/api/co/master/entity/uom";
export const MST_PORT_TYPE_URL = "/api/co/master/entity/portType";
export const MST_PORT = "/api/co/master/entity/port";
export const MST_PORT_KH = MST_PORT + "/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=portCode&iColumns=1&mDataProp_1=TMstCountry.ctyDescription&sSearch_1=Cambodia"
export const MST_HS_CODE_TYPE_URL = "/api/co/master/entity/hsCodeType";
export const MST_ADDRESS_TYPE_URL = "/api/co/master/entity/addrType";

export const CCM_ACCOUNT_ALL_URL = "/api/co/ccm/entity/accn";
export const CCM_ACCOUNT_BY_TYPEID_URL = "/api/co/ccm/entity/accn/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=desc&iSortingCols=1&mDataProp_0=accnId&iColumns=2&mDataProp_1=TMstAccnType.atypId&sSearch_1=";
//&sSearch_1=${acctTypeId}

export const CCM_USER_ALL_URL = "/api/co/ccm/entity/usr";
export const CCM_USER_BY_ACCNID_URL = "api/co/ccm/entity/usr/list?sEcho=3&iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=usrUid&iColumns=2&mDataProp_1=TCoreAccn.accnId&sSearch_1=";
//&sSearch_1=${accnId}`
export const CCM_USER_APP_CODE_URL = "api/co/ccm/entity/usrApp/list?sEcho=3&iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=id.uappUid&iColumns=2&mDataProp_1=id.uappUid&sSearch_1=";
export const CCM_GROUP_BY_ACCNID_URL = "/api/co/ccm/entity/group/list?sEcho=3&iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=id.grpAccnid&iColumns=2&mDataProp_1=id.grpAccnid&sSearch_1=";
//&sSearch_1=${grpAccnid}
export const CCM_APPS_CODE_URL = "/api/co/ccm/entity/apps";
export const CCM_MINISTRY_URL = "/api/co/ccm/entity/ministry/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=minCode&iColumns=1";
export const CCM_AGENCY_URL = "/api/co/ccm/entity/agency/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=agyCode&iColumns=1";

export const CAC_ROLE_BY_APP_CODE_URL = "api/co/cac/entity/role/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=desc&iSortingCols=1&mDataProp_0=id.roleId&iColumns=2&mDataProp_1=id.roleAppscode&sSearch_1="
export const CAC_URITYPE_URL = "/api/co/cac/entity/uriType";
export const CAC_PORTAL_URI_URL = "/api/co/cac/portaluri/";
export const CAC_PERMISSION_TYPE_URL = "/api/co/cac/entity/permissionType";
export const CAC_PERMISSIONS_BY_APPCODE_URL = "api/co/cac/entity/permission/list?sEcho=3&iDisplayStart=0&iDisplayLength=1000&iSortCol_0=0&sSortDir_0=desc&iSortingCols=1&mDataProp_0=permId&iColumns=2&mDataProp_1=appsCode&sSearch_1=";

export const CAN_NOTIFICATION_CHANNEL_TYPE_URL = "/api/co/can/entity/notificationChannel";
export const CAN_NOTIFICATION_CONTENT_TYPE_URL = "/api/co/can/entity/notificationContent";
export const CAN_NOTIFICATION_DEVICE_URL = "/api/co/can/entity/notificationDevice";
export const CAN_NOTIFICATION_TEMPLATE_URL = "/api/co/can/entity/notificationTemplate";

export const ANNOUNCEMENT_TYPE_URL = "/api/co/anncmt/entity/anncmtType";

export const COMMON_ATTACH_LIST_BY_REFID_URL = "/api/co/common/entity/attach/list?sEcho=3&iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&iSortingCols=1&mDataProp_0=attSeq&mDataProp_1=attReferenceid&iColumns=2&sSearch_1="


export const commonTabs = [
    { text: "Record Details", icon: <FileCopy /> },
    { text: "Properties", icon: < Assignment /> },
    { text: "Audits", icon: <Schedule /> }
];


export const registerTabs = [
    { text: "Company Details", icon: <FileCopy /> },
    { text: "Administrator Details", icon: < Assignment /> },
    { text: "Supporting Documents", icon: < Assignment /> },
];


export const AccountOperatorTypes = {
    ACC_TYPE_RAEO_OPERATOR: { code: "ACC_TYPE_RAEO_OPERATOR", desc: "Trader / Economic Operator" }
}

export const RegistrationStatus = {
    PENDING_ACTIVATION: { code: 'P', desc: "Pending Activation" },
    ACTIVATED: { code: 'A', desc: 'Activated' },
    REJECTED: { code: 'R', desc: 'Rejected' }
}


export const uploadFileType = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain"
        + ", application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        + ", application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        + ", application/pdf, image/*";

export const uploadFileTypeRegister = "application/pdf, image/*";