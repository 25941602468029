import React from "react";
import C1DataTable from 'app/c1component/C1DataTable';
import moment from "moment";
import PropTypes from 'prop-types';
import C1DataTableActions from 'app/c1component/C1DataTableActions';
import cacheDataService from "app/services/cacheDataService";
import * as SctConstant from "app/sctcomponent/SctConstant";

const filterByRole = (historyList, roleId) => {
    switch (roleId) {
        case SctConstant.RAEO_OPERATOR:
        case SctConstant.RAEO_OPERATOR_ADMIN: {
            return historyList.filter( h=> h.aeohRoleId?.include)
        }
        case SctConstant.RAEO_REVIEW_OFFICER: {
            break;
        }
        case SctConstant.RAEO_REGULATORY_AGENCY_ADMIN:
        case SctConstant.RAEO_REGULATORY_AGENCY_USER: {
            break;
        }
        case SctConstant.RAEO_VALIDATION_OFFICER: {
            break;
        }
        case SctConstant.RAEO_APPROVAL_OFFICER: {
            break;
        }
        case SctConstant.RAEO_COMMISSIONER: {
            break;
        }
        default:
    }
}


/**
 * @param filterId - id of the record to display the audits
 */
const RaeoAuditTab = ({
    filterId,
    historyList }) => {

    ///////////
    let appId = filterId;
    console.log("filterId 1 : ", appId, historyList);
    function compare(property) {
        return function (a, b) {
            var value1 = a[property];
            var value2 = b[property];
            //console.log(value2 - value1);
            return value2 - value1;
        }
    }
    if (historyList) {
        historyList.forEach(h => {
            h.TSctRaeoApp = { appId: appId };
        });
        // not working.
        //historyList.sort((a, b) => { return !(a.aeohDtCreate - b.aeohDtCreate) });
        historyList.sort(compare('aeohDtCreate'))
    }
    console.log("appId 2 : ", appId, historyList);
    ///////////
    let tabledata = { list: historyList }
    ///////////

    const viewHistoryEventHandler = (e, historyId) => {
        window.open("/applications/raeoApp/history/" + historyId);
    }
    ///////////
    window.getSctCacheDataService = () => {
        return cacheDataService;
    }

    const columns = [
        {
            name: "aeohId", // field name in the row object
            label: "Audit ID", // column title that will be shown in table
            options: {
                display: false,
                sort: false,

            }
        },
        {
            name: "TSctRaeoApp.appId", // field name in the row object
            label: "RAEO ID", // column title that will be shown in table
            options: {
                sort: false,
                filter: false,
            },

        },
        {
            name: "aeohFromStatus", // field name in the row object
            label: "From Status", // column title that will be shown in table
            options: {
                sort: false,
                filter: false,
            },

        },
        {
            name: "aeohAction", // field name in the row object
            label: "Action", // column title that will be shown in table
            options: {
                sort: false,
                filter: false,
            },

        },
        {
            name: "aeohToStatus", // field name in the row object
            label: "To Status", // column title that will be shown in table
            options: {
                sort: false,
                filter: false,
            },

        },
        {
            name: "aeohDtCreate",
            label: "Action Time",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('YYYY-MM-DD hh:mm:ss');
                },
            },
        },
        {
            name: "TCoreUsr.usrUid",
            label: "User Id",
            options: {
                sort: false,
                filter: false,
            },

        },
        {
            name: "TCoreUsr.usrName",
            label: "User Name",
            options: {
                sort: false,
                filter: false,
            },
        },
        {
            name: "action",
            label: "View",
            options: {
                filter: false,
                sort: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <C1DataTableActions
                        viewHistoryEventHandler={(e) => viewHistoryEventHandler(e, tableMeta.rowData[0])}
                    />
                },
            },
        },
    ];

    return (
        <React.Fragment>

            { /*!historyList && <C1DataTable url="/sct/api/entity/raeoAppHistory"
                columns={columns}
                defaultOrder="aeohDtCreate"
                isRowSelectable={false}
                defaultOrderDirection="desc"
                isShowToolbar={false}
                isServer={true}
                isShowFilterChip={false} /> */}

            <C1DataTable url="/sct/api/entity/raeoAppHistory"
                columns={columns}
                defaultOrder="aeohId"
                isRowSelectable={false}
                defaultOrderDirection="desc"
                isShowToolbar={false}
                isServer={false}
                isShowFilterChip={false}
                dbName={tabledata} />
        </React.Fragment>
    )
};


RaeoAuditTab.propTypes = {
    filterId: PropTypes.string
}

export default RaeoAuditTab;