import React, { useState } from "react";
import {
    Grid,
    Box,
    Radio,
    RadioGroup,
    FormGroup,
    FormControl,
    FormHelperText,
    FormLabel,
    FormControlLabel,
    MenuItem,
    Checkbox
} from "@material-ui/core";


import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import C1InputField from "app/c1component/C1InputField";
import C1SelectField from "app/c1component/C1SelectField";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";
import C1Container from "app/c1component/C1Container"
import { isRaeoOperator } from "app/sctcomponent/SctUtilities";

import useAuth from "app/hooks/useAuth";
import RaeoHistory from "./RaeoHistory";
import cacheDataService from "app/services/cacheDataService"

const RaeoAppCommitteeRecommendation = ({
    inputData,
    auditList,
    handleInputChange,
    handleInputChangeCR,
    handleInputChangeDeep,
    viewType,
    isDisabled,
    errors }) => {

    const classes = useStyles();

    const { user } = useAuth();
    const [refreshPage, setRefreshPage] = useState(0);

    let tSctRaeoAppCr = inputData.TSctRaeoAppCr;

    ////////////////////
    const getMethIdxInCr = (methId) => {

        if (tSctRaeoAppCr && tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
            for (let idx = 0; idx < tSctRaeoAppCr.TSctRaeoAppCrMthdvs.length; idx++) {
                if (tSctRaeoAppCr.TSctRaeoAppCrMthdvs[idx].crmthdvMthdvId === methId) {
                    return idx;
                }
            }
        }
        return -1;
    }
    ///////////////// sctRaeoMstRecommendType
    let recommendTypeList = cacheDataService.getItemData("sctRaeoMstRecommendType");
    if (recommendTypeList) {
        recommendTypeList.sort((a, b) => a.recomtSeq - b.recomtSeq);
    }

    let methodologyList = cacheDataService.getItemData("sctRaeoMstMthdology");
    if (methodologyList) {
        methodologyList.sort((a, b) => a.mthdSeq - b.mthdSeq);
    }
    //console.log("methodologyList 2", methodologyList);

    let sctRaeoMstMthvalidList = cacheDataService.getItemData("sctRaeoMstMthvalid");
    if (sctRaeoMstMthvalidList) {
        sctRaeoMstMthvalidList.sort((a, b) => a.mthdvSeq - b.mthdvSeq);
    }
    //console.log("sctRaeoMstMthvalidList", sctRaeoMstMthvalidList);

    let docReviewList = cacheDataService.getItemData("sctRaeoMstDocReview");
    if (docReviewList) {
        docReviewList.sort((a, b) => a.docreviewSeq - b.docreviewSeq);
    }
    //console.log("docReviewList", docReviewList);

    let riskLevelList = cacheDataService.getItemData("sctRaeoMstRiskLevel");
    if (riskLevelList) {
        riskLevelList.sort((a, b) => a.risklvlSeq - b.risklvlSeq);
    }
    //console.log("riskLevelList", riskLevelList);
    /*
        const getMethById = (methId) => {
            for (const meth of methodologyList) {
                if (meth.methId === methId) {
                    return meth;
                }
            }
        }
     */    /////////////////////
    const getMethExisting = (methId) => {
        if (tSctRaeoAppCr && tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
            for (const meth of tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
                if (meth.crmthdvMthdvId === methId) {
                    return true;
                }
            }
        }
        return false;
    }

    const handleInputChangeMeth = (e) => {
        //console.log("handleInputChangeCurrent", e.target.name, e.target.name);
        //setInputData({ ...inputData, "tSctRaeoAppCr": { ...inputData.tSctRaeoAppCr, [e.target.name]: e.target.value } });
        const methId = e.target.name;
        if (e.target.checked) {
            if (getMethExisting(methId)) {
                return // already existing
            }
            let crMeth = { "crmthdvMthdvId": methId, "crmthdvStatus": 'A' };
            tSctRaeoAppCr.TSctRaeoAppCrMthdvs.push(crMeth);
        } else {
            if (!getMethExisting(methId)) {
                return // not existing
            }
            let idx = getMethIdxInCr(methId);
            if (idx > -1) {
                tSctRaeoAppCr.TSctRaeoAppCrMthdvs.splice(idx, 1);
            }
        }
        console.log("tSctRaeoAppCr.TSctRaeoCrMeths.length:", tSctRaeoAppCr.TSctRaeoAppCrMthdvs.length);
        setRefreshPage(refreshPage + 1);
    };

    //////////// begin Meth ////
    const getMethRadioDisable = (mthdId) => {
        if (tSctRaeoAppCr && tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
            for (const meth of tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
                if (meth.crmthdvMthdvId === mthdId) {
                    return true;
                }
            }
        }
        return false;
    }
    const getMethRadioValue = (mthdId) => {
        if (tSctRaeoAppCr && tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
            for (const meth of tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
                if (meth.crmthdvMthdvId === mthdId) {
                    return meth.crmthdvVal;
                }
            }
        }
        return "";
    }
    const handleInputChangeMethRadio = (e) => {

        const methId = e.target.name;
        const aeomMethCheck = e.target.value;
        console.log("methId aeomMethCheck:", methId, aeomMethCheck);

        for (const meth of tSctRaeoAppCr.TSctRaeoAppCrMthdvs) {
            if (meth.crmthdvMthdvId === methId) {
                meth.crmthdvVal = aeomMethCheck;
                break;
            }
        }
        setRefreshPage(refreshPage + 1);
    }

    const handleGetMethValList = (mthdId) => {
        return sctRaeoMstMthvalidList.filter(mth => mth.TSctRaeoMstMthdology.mthdId === mthdId)
    }
    ////// end Meth ////
    ////// Begin Doc review ////
    const getDocReviewValInCr = (docreviewId) => {

        let docReview = getDocReviewInCr(docreviewId);
        if (docReview) {
            return docReview.docrValue;
        }
        return "";
    }
    const getDocReviewInCr = (docreviewId) => {
        if (tSctRaeoAppCr && tSctRaeoAppCr.TSctRaeoAppCrDocreviews) {
            for (const docReview of tSctRaeoAppCr.TSctRaeoAppCrDocreviews) {
                if (docReview.docrDocreviewId === docreviewId) {
                    return docReview;
                }
            }
        }
        return undefined;
    }

    const handleInputChangeDocReview = (e) => {
        const docreviewId = e.target.name;
        const docrValue = e.target.value;
        console.log("handleInputChangeDocReview:", docreviewId, docrValue);

        let docReview = getDocReviewInCr(docreviewId);
        console.log("docReview:", docReview);
        if (docReview) {
            docReview.docrValue = docrValue;
        } else {
            let docr = {
                "docrDocreviewId": docreviewId
                , "docrValue": docrValue
            };
            tSctRaeoAppCr.TSctRaeoAppCrDocreviews.push(docr);
        }
        setRefreshPage(refreshPage + 1);
    };

    ////// End Doc review ////

    ////// Begin risk level ////
    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        setRefreshPage(refreshPage + 1);
    }
    ///////////

    const defaultUserName = (!isDisabled) ? user.name : "";

    return (
        <React.Fragment>

            <C1HeaderTitle>Header Information</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1DateField
                        label="Recommended Date"
                        name="aeocRecommendDate"
                        value={tSctRaeoAppCr?.crDtLupd || ""}
                        required
                        disabled />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Recommended By"
                        name="usrName"
                        value={tSctRaeoAppCr?.TCoreUsr?.usrName || defaultUserName}
                        required
                        disabled />
                </Grid>
            </Grid>


            <C1HeaderTitle>Details</C1HeaderTitle>


            <Grid container alignItems="flex-start" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        multiline
                        label="Objectives of the Validation"
                        name="crValidObjectives"
                        rows={5}
                        value={tSctRaeoAppCr.crValidObjectives || ""}
                        type="input"
                        required
                        disabled={isDisabled}
                        inputProps={{ 'maxLength': 1024 }}
                        onChange={handleInputChangeCR}
                        error={errors['TSctRaeoAppCr.crValidObjectives'] !== undefined}
                        helperText={errors['TSctRaeoAppCr.crValidObjectives'] || ''}
                    />
                    <C1SelectField
                        label="Committee Recommendation"
                        name="TSctRaeoAppCr.TSctRaeoMstRecommendType.recomtId"
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        value={tSctRaeoAppCr.TSctRaeoMstRecommendType?.recomtId || ""}
                        error={errors['TSctRaeoAppCr.TSctRaeoMstRecommendType'] !== undefined}
                        helperText={errors['TSctRaeoAppCr.TSctRaeoMstRecommendType'] || ''}>
                        {recommendTypeList?.map((item, ind) => (
                            <MenuItem value={item.recomtId} key={ind}>{item.recomtName}</MenuItem>
                        ))}
                    </C1SelectField>
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        multiline
                        label="Validation Observations"
                        name="crValidObservation"
                        value={tSctRaeoAppCr.crValidObservation || ""}
                        rows={5}
                        type="input"
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeCR}
                        inputProps={{ 'maxLength': 1024 }}
                        error={errors['TSctRaeoAppCr.crValidObservation'] !== undefined}
                        helperText={errors['TSctRaeoAppCr.crValidObservation'] || ''}
                    />
                    <C1InputField
                        multiline
                        label="Reason for the Recommendation"
                        name="crRecommendReason"
                        value={tSctRaeoAppCr.crRecommendReason || ""}
                        rows={5}
                        type="input"
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeCR}
                        inputProps={{ 'maxLength': 1024 }}
                        error={errors['TSctRaeoAppCr.crRecommendReason'] !== undefined}
                        helperText={errors['TSctRaeoAppCr.crRecommendReason'] || ''}
                    />

                </Grid>
            </Grid>

            <C1HeaderTitle>Methodology</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <FormGroup style={{ "align-items": "flex-start" }}>
                        {methodologyList && methodologyList.map((item, idx) => {
                            return <FormControlLabel
                                control={<Checkbox checked={getMethExisting(item.mthdId)} onChange={handleInputChangeMeth} name={item.mthdId} color="primary" />}
                                label={item.mthdDesc} disabled={isDisabled} key={idx} labelPlacement="start"
                            />
                        })}
                    </FormGroup>
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    {methodologyList && methodologyList.map((item, idx) => {
                        return <Box display="flex" flexDirection="row" alignItems="center" className="ml-3 mr-3" key={idx}>
                            <Box>{item.mthdValidation} </Box>
                            <Box>
                                <RadioGroup row aria-label="yesOrno" name={item.mthdId} value={getMethRadioValue(item.mthdId)} onChange={handleInputChangeMethRadio}>
                                    {handleGetMethValList(item.mthdId) && handleGetMethValList(item.mthdId).map((methValid, idx) => {
                                        return <FormControlLabel disabled={isDisabled || !getMethRadioDisable(item.mthdId)} key={idx}
                                            value={methValid.mthdvVal || ""} control={<Radio color="primary" />} label={methValid.mthdvDesc} labelPlacement="start" />
                                    })}
                                </RadioGroup>
                            </Box>
                        </Box>
                    })}
                </Grid>
            </Grid>


            <C1HeaderTitle>Documents Reviewed</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} >
                    {docReviewList && docReviewList.map((item, idx) => {
                        return <Box key={idx} className="ml-3" >
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box>{item.docreviewName} </Box>
                                <Box>
                                    <RadioGroup row aria-label="yesOrno" name={item.docreviewId}
                                        value={getDocReviewValInCr(item.docreviewId) || ""} onChange={handleInputChangeDocReview}>
                                        <FormControlLabel value="Y" control={<Radio color="primary" />} label="Yes" labelPlacement="start" disabled={isDisabled} />
                                        <FormControlLabel value="N" control={<Radio color="primary" />} label="No" labelPlacement="start" disabled={isDisabled} />
                                    </RadioGroup>
                                </Box>
                            </Box>
                        </Box>
                    })}
                </Grid>
            </Grid>

            <C1HeaderTitle>Overall Risk Level (Conclusion from risk mapping exercise. Overall risk level in accordance with AEO set criteria)</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} >
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <FormControl component="fieldset"
                            error={errors['TSctRaeoAppCr.TSctRaeoMstRiskLevel'] !== undefined} >
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup row aria-label="riskLevel" name="TSctRaeoAppCr.TSctRaeoMstRiskLevel.risklvlId"
                                value={tSctRaeoAppCr.TSctRaeoMstRiskLevel?.risklvlId || ""} onChange={handleInputChangeDeepWrap}>
                                {riskLevelList && riskLevelList.map((item, idx) => {
                                    return <FormControlLabel value={item.risklvlId} control={<Radio color="primary" />}
                                        label={item.risklvlName} labelPlacement="start" disabled={isDisabled} key={idx} />
                                })}
                            </RadioGroup>
                            <FormHelperText>{errors['TSctRaeoAppCr.TSctRaeoMstRiskLevel']}</FormHelperText>
                        </FormControl>

                    </Box>
                </Grid>
            </Grid>
            <C1Container rendered={true}>

                <C1HeaderTitle></C1HeaderTitle>

                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Comments"
                            name="crComment"
                            rows={5}
                            type="input"
                            required
                            disabled={isDisabled}
                            inputProps={{ 'maxLength': 1024 }}
                            value={tSctRaeoAppCr.crComment || ""}
                            onChange={handleInputChangeCR}
                            error={errors['TSctRaeoAppCr.crComment'] !== undefined}
                            helperText={errors['TSctRaeoAppCr.crComment'] || ''}
                        />

                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Corrections / Additional Information Required"
                            name="crCorrAddinfo"
                            rows={5}
                            type="input"
                            required
                            disabled={isDisabled}
                            inputProps={{ 'maxLength': 1024 }}
                            value={tSctRaeoAppCr.crCorrAddinfo || ""}
                            onChange={handleInputChangeCR}
                            error={errors['TSctRaeoAppCr.crCorrAddinfo'] !== undefined}
                            helperText={errors['TSctRaeoAppCr.crCorrAddinfo'] || ''}
                        />
                    </Grid>
                </Grid>
            </C1Container>

            <RaeoHistory auditList={auditList}>
            </RaeoHistory>

        </React.Fragment>
    );
};

export default RaeoAppCommitteeRecommendation;