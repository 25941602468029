import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Badge,
    AppBar,
    Snackbar,
    Box,
    Card,
    Divider,
    Grid,
    Tab,
    Tabs,
    Toolbar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

import axios from 'axios';
import withErrorHandler from "app/hoc/withErrorHandler/withErrorHandler";

import C1FormButtons from "app/c1component/C1FormButtons";

import clsx from "clsx";

import sctregLogo from '../../../views/sessions/login/sctregLogo.png';
import { deepUpdateState } from "app/c1utils/stateUtils";
import CompanyStampDetails from "./CompanyStampDetails";
import RegisterDocs from "./RegisterDocs";
import { registerTabs } from "app/c1utils/const";
import C1Alert from "app/c1component/C1Alert";
import useHttp from "app/c1hooks/http";
import TraderDetail from "./TraderDetail";
import UserDetail from "./UserDetail";

const useStyles = makeStyles(({ palette, ...theme }) => ({

    cardHolder: {
        background: "#3C77D0",
        height: 979
    },

    card: {
        width: "1200!important",
        borderRadius: 12,
        margin: "5rem",
    },

    root: {
        backgroundColor: '#3C77D0',
        borderColor: palette.divider,
        display: "table",
        height: "var(--topbar-height)",
        borderBottom: "1px solid transparent",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        zIndex: 98,
        paddingLeft: "1.75rem",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1rem",
        },
    },

    brandText: {
        color: palette.primary.contrastText,
    },

    button: {
        width: 140,
        height: 40,
        fontFamily: 'sans-serif',
        fontWeight: 'bolder',
        borderRadius: 12,
        backgroundColor: 'white',
        color: '#3C77D0',
        margin: '10px 10px 20px 10px',
        border: '1px solid #3C77D0'
    },

    buttonBack: {
        width: 140,
        height: 40,
        fontFamily: 'sans-serif',
        fontWeight: 'bolder',
        borderRadius: 12,
        backgroundColor: 'white',
        color: '#3C77D0',
        margin: '10px 10px 20px 10px',
        border: '1px solid #3C77D0',
        float: "right"
    },
    buttonSubmit: {
        float: "right",
    },

    title: {
        fontFamily: 'arial',
        fontWeight: 'bolder',
        fontSize: '20px',
        margin: '10px 10px 20px 10px',
        float: "left"
    },
    error: {
        color: 'red'
    }
}));

const useTableStyle = makeStyles({
    table: {
        width: "1200!important",
        margin: "5rem",
    },
    column: {
        width: 100,
    }
});

const tab0Items = ["TMstAccnType", "accnrCompName", "accnrCompReg", "accnrTel", "accnrEmail",
    "accnrAddressLine1", "accnrAddressLine2", "accnrAddressLine3", "accnrProv", "accnrCity", "accnrPcode", "TMstCountry"];

const tab1Items = [
    "accnrAplTel", "accnrAplAddr1", "accnrAplAddr2", "accnrAplAddr3",
    "accnrAplName", "accnrAplPassNid", "accnrAplTitle", "accnrAplEmail",
    "accnrAplProv", "accnrAplCity", "TMstCountryApl", "accnrAplPcode"];

const tab2Items = ["accnDocs.TIN_CERT", "accnDocs.COMPANY_REG"];

const JwtRegister = (props) => {
    const history = useHistory();

    const [tabIndex, setTabIndex] = useState(0);
    const [isSubmitSuccess, setSubmitSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);


    const classes = useStyles();
    const [snackBarState, setSnackBarState] = useState({ open: false, vertical: 'top', horizontal: 'center', msg: '', severity: 'success' });

    //for server calls use sendRequest and responses will be stored in the corresponding deconstructed variables
    const { isLoading, isFormSubmission, res, validation, error, urlId, sendRequest } = useHttp();

    const [inputData, setInputData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const [errorTabList, setErrorTabList] = useState({});

    const handleTabChange = (e, value) => {
        setTabIndex(value);
    };

    const handleInputChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    };
    const handleDateChange = (name, e) => {
        setInputData({ ...inputData, [name]: e });
    }
    const handleInputChangeDeep = (name, value) => {
        setInputData({ ...inputData, ...deepUpdateState(inputData, name, value) });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        sendRequest("/sct/api/register/", "register", "post", inputData);
    }

    /*
        const validateForm = () => {
            let errorMsg = {};
    
            let filesFilterByType = inputData?.accnDocs?.filter(f => f.attType === "TIN_CERT");
            if (!filesFilterByType || filesFilterByType.length === 0) {
                //validationErrors, setValidationErrors
                errorMsg = { ...errorMsg, "accnDocs.TIN_CERT": "TIN Registry Certificate is required." };
            }
    
            filesFilterByType = inputData?.accnDocs?.filter(f => f.attType === "COMPANY_REG");
            if (!filesFilterByType || filesFilterByType.length === 0) {
                //validationErrors, setValidationErrors
                errorMsg = { ...errorMsg, "accnDocs.COMPANY_REG": "company registration document is required." };
            }
            console.log("errorMsg", errorMsg);
            setValidationErrors({ ...validationErrors, ...errorMsg });
    
            if (errorMsg && errorMsg.length > 0) {
                setValidationErrors({ ...validationErrors, ...errorMsg });
                return false;
            }
            return false;
        }
    */
    //for loading the supporting documents
    useEffect(() => {
        sendRequest("/sct/api/register/suppDocs", "loadSuppDocs", "get");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isLoading && !error && res && !validation) {
            switch (urlId) {
                case 'loadSuppDocs':
                    setInputData({ ...inputData, "accnDocs": res.data });
                    break;
                case 'register':
                    setSubmitSuccess(true);
                    setSnackBarState({ ...snackBarState, open: true, msg: "Registration submitted successfully, your application will be processed in 7 working days and you will receive an email notification once processed", severity: 'success' });
                    break;
                case 'upload':
                    setLoading(isLoading);
                    break;
                default: break;
            }
        }

        if (error) {
            setLoading(false);
        }

        if (validation) {

            let msg = "Validation error";
            let severity = "error";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: severity } });

            setValidationErrors({ ...validation });
            setLoading(false);
            console.log("validation", validation)
            setErrorTab(validation);
        } else {
            setErrorTab({});
        }

        // eslint-disable-next-line
    }, [isLoading, res, error, urlId, validation])

    const setErrorTab = (validation) => {
        let e0 = 0;
        let errTabJson = {};
        tab0Items.forEach(function (value, index, array) {
            if (validation.hasOwnProperty(value)) {
                e0++;
            }
        });
        if (e0 > 0) {
            errTabJson = { ...errTabJson, "0": e0 };
        }
        /////
        let e1 = 0;
        tab1Items.forEach(function (value, index, array) {
            if (validation.hasOwnProperty(value)) {
                e1++;
            }
        });
        if (e1 > 0) {
            errTabJson = { ...errTabJson, "1": e1 };
        }
        /////
        let e2 = 0;
        tab2Items.forEach(function (value, index, array) {
            if (validation.hasOwnProperty(value)) {
                e2++;
            }
        });
        if (e2 > 0) {
            errTabJson = { ...errTabJson, "2": e2 };
        }
        setErrorTabList({ ...errTabJson });
        /////
    }

    const getIcon = (idxTab) => {
        if (errorTabList[idxTab]) {
            return <Badge color="error" badgeContent={errorTabList[idxTab]} max={100}>{registerTabs[idxTab].icon}</Badge>;
        }
        return registerTabs[idxTab].icon;
    }


    const handleClose = () => {
        setSnackBarState({ ...snackBarState, open: false });
        if ("success" === snackBarState.severity) {
            history.push("/session/signin");
        }
    };

    let bcLabel = 'Company Registration Details';
    let snackBar = null;
    if (isSubmitSuccess) {

        const anchorOriginV = snackBarState.vertical;
        const anchorOriginH = snackBarState.horizontal;

        snackBar = <Snackbar
            anchorOrigin={{ vertical: anchorOriginV, horizontal: anchorOriginH }}
            open={snackBarState.open}
            onClose={handleClose}
            autoHideDuration={3000}
            key={anchorOriginV + anchorOriginH
            }>
            <C1Alert onClose={handleClose} severity={snackBarState.severity}>
                {snackBarState.msg}
            </C1Alert>
        </Snackbar>;

    }
    return (
        <React.Fragment>
            {snackBar}
            <div className={clsx("relative w-full", classes.root)}>
                <div className="flex justify-between items-center h-full">
                    <div className="flex items-center h-full">
                        <img
                            className="h-32"
                            src={sctregLogo}
                            alt=""
                        />
                        <span className={clsx("font-medium text-24 mx-4", classes.brandText)}>EAC SCT</span>
                    </div>
                </div>
            </div>

            <Card className={classes.card}>
                <div style={{ width: '100%', height: '70px' }}>
                    <Box display="flex" p={1} >
                        <Box p={1} flexGrow={1} >
                            <div className={classes.title}>
                                {bcLabel}
                            </div>
                        </Box>
                        <Box p={1} >
                            <C1FormButtons options={{
                                back: {
                                    show: true,
                                    eventHandler: () => history.goBack()
                                },
                                submitOnClick: {
                                    show: true,
                                    eventHandler: handleSubmit
                                }
                            }}
                            />
                        </Box>
                    </Box>
                </div>

                <Grid container>
                    <Grid item xs={12}>
                        <Tabs
                            className="mt-4"
                            value={tabIndex}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {registerTabs.map((item, ind) => (
                                <Tab className="capitalize" value={ind} label={item.text} key={ind}
                                    icon={getIcon(ind)} />
                            ))}
                        </Tabs>
                        <Divider className="mb-6" />

                        {tabIndex === 0 && <TraderDetail handleSubmit={handleSubmit}
                            data={data}
                            inputData={inputData}
                            props={props}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            handleInputChangeDeep={handleInputChangeDeep}
                            errors={validationErrors}
                            isDisabled={false}
                            isSubmitting={loading} />
                        }

                        {tabIndex === 1 && <UserDetail handleSubmit={handleSubmit}
                            data={data}
                            inputData={inputData}
                            props={props}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            handleInputChangeDeep={handleInputChangeDeep}
                            errors={validationErrors}
                            isDisabled={false}
                            isSubmitting={loading} />
                        }

                        {false && tabIndex === 3 && <CompanyStampDetails />}

                        {tabIndex === 2 && <RegisterDocs
                            inputData={inputData}
                            isDisabled={false}
                            errors={validationErrors}
                        />}

                    </Grid>
                </Grid>
                {/* <div>
                    <button className={classes.buttonBack} onClick={clickHandler}>Back To Login</button>
                    <button className={clsx(classes.button, classes.buttonSubmit)}>Submit</button>
                </div> */}
            </Card>

            <AppBar position="static">
                <Toolbar className="footer">

                    <span className="m-auto"></span>
                    <p className="m-0">
                        Developed and Powered by <a href="https: guud.company/" target="blank">GUUD International</a>
                    </p>
                </Toolbar>
            </AppBar>

        </React.Fragment>

    );
};

export default withErrorHandler(JwtRegister, axios);