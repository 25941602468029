import React from "react";
import {
    Grid,
} from "@material-ui/core";

import { titleTab, useStyles } from "app/c1utils/styles";

const C1HeaderTitle = ({
    children
}) => {

    const classes = useStyles();
    const titleClasses = titleTab();

    return (
        <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
            <Grid container item xs={12} className={titleClasses.root}>
                {children}
            </Grid>
        </Grid>
    );
};

export default C1HeaderTitle;