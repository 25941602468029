import axios from 'axios.js';
import { navigations } from "../../navigations";
export const SET_USER_NAVIGATION = "SET_USER_NAVIGATION";

const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth) {
      if (nav.auth.includes(role)) {
        array.push(nav);
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role);
        array.push(nav);
      } else {
        array.push(nav);
      }
    }
    return array;
  }, []);
};

export function getNavigationByUser() {
  return (dispatch, getState) => {
    let state = getState();
    let { user } = getState();
    console.log("getNavigationByUser 1 ", user.role, user, state);
    let filteredNavigations = getfilteredNavigations(navigations, user.role);
    // user.role
    console.log("getNavigationByUser 2", filteredNavigations );
    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations]
    });
  };
}


/**Retrieves the menu from backend. */
export function getMenuByUser(user) {
  return (dispatch, getState) => {
    axios({
      method: 'get',
      url: `/api/auth/menu`,
    }).then(response => {

      let menu = response.data;


      dispatch({
        type: SET_USER_NAVIGATION,
        payload: menu
      });

    }, (error) => {
      console.log("error", error);
    }).catch(error => {
      console.log("caught error", error);
    });


  };
}
