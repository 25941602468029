import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { useSelector } from "react-redux";
import useAuth from "app/hooks/useAuth";


const MatxHorizontalNav = ({ max }) => {
  let navigation = useSelector(({ navigations }) => navigations);
  const { user } = useAuth();

  if (!navigation || !navigation.length) {
    return null;
  }

  if (max && navigation.length > max) {
    let childItem = {
      name: "More",
      icon: "more_vert",
      children: navigation.slice(max, navigation.length),
    };
    navigation = navigation.slice(0, max);
    navigation.push(childItem);
  }

  function renderLevels(levels) {
    return levels.map((item, key) => {

      /*
      if(item.authorities !== undefined){
        console.log(item.authorities, user.role);
        let isAuthFound = item.authorities.filter(function (v) { return user.role.split(",").includes(v);}).length > 0;
        console.log(user.role, isAuthFound);
        if(!isAuthFound) return null;
      }
      */
      if(item.auth !== undefined){
        //console.log(item.auth, user.role);
        let isAuthFound = item.auth.filter(function (v) { return user.role.split(",").includes(v);}).length > 0;
        //console.log(user.role, isAuthFound);
        if(!isAuthFound) return null;
      }


      if (item.type === "label") return null;
      if (item.children) {
        return (
          <li key={key}>
            <a href="#">
              {item.icon && (
                <Icon className="text-18 align-middle">{item.icon}</Icon>
              )}
              {item.name}
            </a>
            <ul style={{marginTop:"-20px"}}>{renderLevels(item.children)}</ul>
          </li>
        );
      } else {
        return (
          <li key={key}>
            <NavLink to={item.path}>
              {item.icon && (
                <Icon className="text-18 align-middle">{item.icon}</Icon>
              )}
              {item.name}
            </NavLink>
          </li>
        );
      }
    });
  }

  return (
    <div className={"horizontal-nav"}>
      <ul className={"menu"}>{renderLevels(navigation)}</ul>
    </div>
  );
};

export default MatxHorizontalNav;
