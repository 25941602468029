export function isRaeoOperator(roles) {
  return (
    roles.split(",").includes("RAEO_OPERATOR") ||
    roles.split(",").includes("RAEO_OPERATOR_ADMIN")
  );
}

export function getRaeoEditMode(status, roleId) {
  return (
    isRaeoOperator(roleId) && (status === "Draft" || status === "Returned")
  );
}
