import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AuxDiv from "../AuxDiv/AuxDiv";
import classes from "./withErrorHandler.module.css";
import axios, { sessionTimeout, noPermission } from "axios.js";

const withErrorHandler = (WrappedComponent) => {

    return class extends Component {

        state = {
            error: null,
            open: false
        }

        handleOpen = () => {
            console.log("opening modal");
            this.setState({ open: true });
        }

        handleClose = () => {
            console.log("closing modal");
            this.setState({ open: false });
        }

        constructor(props) {
            super(props);
            this.reqInterceptor = axios.interceptors.request.use(req => {
                //this is to not close the modal if some fields (e.g. select) rerenders and request http
                if (!this.state.error) {
                    this.setState({ error: null, open: false });
                }

                return req;
            });

            this.resInterceptor = axios.interceptors.response.use(res => res, error => {
                if (error.status !== 'VALIDATION_FAILED') {
                    this.setState({ error: error, open: true });
                }
                return Promise.reject(
                    (error) || "Something went wrong!"
                );
            });
        }

        //This will remove the interceptors, cleaning up so that if we reuse this in other parts in the application
        //it will not create more interceptors
        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        errorConfirmedHandler = () => {
            this.setState({ error: null });
        }


        render() {
            if (this.state.error && this.state.error.err && sessionTimeout === this.state.error.err.msg) {
                return <Redirect to='/session/timeout' />;
            }
            if (this.state.error && this.state.error.err && noPermission === this.state.error.err.msg) {
                return <Redirect to='/session/nopermission' />;
            }
            return (
                <AuxDiv>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        onClose={this.handleClose}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{ top: '30%', width: '70%', left: '15%' }}
                    >
                        <Fade in={this.state.open}>
                            <div className={classes.ModalPaper}>
                                <h2 id="transition-modal-title">Error</h2>
                                <p id="transition-modal-description">{this.state.error && this.state.error.err ? this.state.error.err.msg : 'Unable to reach the URL'}</p>
                            </div>
                        </Fade>
                    </Modal>
                    <WrappedComponent {...this.props} />
                </AuxDiv>

            );
        }

    }
}

export default withErrorHandler;