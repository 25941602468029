import React, { useState } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
  Avatar,
  MenuItem,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Tooltip,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import history from "history.js";
import clsx from "clsx";
import useAuth from 'app/hooks/useAuth';

import LanguageIcon from '@material-ui/icons/Language';
import PortEDI_BackImage from './PortEDI_BackImage.jpg';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    //background: `url("https://image.shutterstock.com/image-photo/aerial-view-container-ship-business-600w-784314790.jpg")`,
    backgroundImage: `url(${PortEDI_BackImage})`,
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  card: {
    maxWidth: 550,
    borderRadius: 12,
    margin: "1rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

let LoginStatus = {};

const LoginStatusArray = [
  "AUTHORIZED_REG",
  "AUTHORIZED_IGNORE",
  "UNAUTHENTICATED",
  "UNAUTHORIZED",
  "AUTHORIZED_LOGIN",
  "AUTHORIZED_LOGIN_CHNG_PW_RQD",
  "ACCOUNT_SUSPENDED",
  "ACCOUNT_INACTIVE",
  "USER_SESSION_EXISTING",
  "PASSWORD_RESET_SUCCESS",
  "SESSION_EXPIRED_OR_INACTIVE",
];

LoginStatusArray.map((status) => LoginStatus = { ...LoginStatus, [status]: status });

const JwtLogin = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({ email: "", password: '' });
  const [message, setMessage] = useState('');
  const { login } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...userInfo };

    if (name === 'email') {
      // username to uppercase
      temp[name] = value.toUpperCase();
    } else {
      temp[name] = value;
    }

    setUserInfo(temp);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormSubmit = async (event) => {
    setLoading(true);
    try {
      let data = await login(userInfo.email, userInfo.password);
      switch (data.loginStatus) {
        case LoginStatus.AUTHORIZED_LOGIN: {
          history.push("/");
          break;
        }
        case LoginStatus.AUTHORIZED_LOGIN_CHNG_PW_RQD:
          history.push("/session/force-change-password");
          break;
        default:
          setMessage(data.err);
          setLoading(false);
          break;
      }
    } catch (e) {
      console.log(e);
      setMessage(e.message);
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);
  

  return (

    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Grid container justify="flex-end">
            <IconButton
              aria-label="account of current user"
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Tooltip title="Language" aria-label="add">
                <LanguageIcon />
              </Tooltip>
            </IconButton>
          </Grid>
          <Menu id="fade-menu" anchorEl={anchorEl} keepMounted
            open={open}
            onClose={handleClose}>
            <MenuItem value="Pontoon" key="1">  <Avatar className="w-30 h-30" src={'https://www.countryflags.io/KH/shiny/32.png'} />
              <div className="ml-3">
                <h5 className="my-0 text-15">Khmer</h5>
              </div> </MenuItem>
            <MenuItem value="Pontoon" key="2">  <Avatar className="w-30 h-30" src={'https://www.countryflags.io/GB/shiny/32.png'} />
              <div className="ml-3">
                <h5 className="my-0 text-15">English</h5>
              </div> </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div
        className={clsx(
          "flex justify-end items-center  min-h-full-screen",
          classes.cardHolder
        )}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex justify-center items-center h-full">
                <img
                  className="w-200"
                  src={`${process.env.PUBLIC_URL}/assets/images/logos/SCT_LOGO.png`}
                  alt=""
                />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-8 h-full bg-light-gray relative">
                <ValidatorForm onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="medium"
                    label="User ID/Email"
                    onChange={handleChange}
                    type="text"
                    name="email"
                    value={userInfo.email}
                    validators={["required"]}
                    errorMessages={[
                      "this field is required",
                    ]}
                  />
                  <TextValidator
                    className="mb-3 w-full"
                    label="Password"
                    variant="outlined"
                    size="medium"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={userInfo.password}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <FormControlLabel
                    className="mb-3 min-w-288"
                    name="agreement"
                    onChange={handleChange}
                    control={
                      <Checkbox
                        size="small"
                        onChange={({ target: { checked } }) =>
                          handleChange({
                            target: { name: "agreement", value: checked },
                          })
                        }
                        checked={userInfo.agreement || true}
                      />
                    }
                    label="Remember me"
                  />

{message && <p className="text-error">{message.includes("Cannot read properties of undefined") ? "Please check your internet connection or contact SCT support team" : message}</p>}

                  <div className="flex flex-wrap items-center mb-4">
                    <div className="relative">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        type="submit"
                      >
                        Sign in
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <span className="mr-2 ml-5">or</span>
                    <Button
                      className="capitalize"
                      onClick={() => history.push("/session/signup")}
                    >
                      Sign up
                    </Button>
                  </div>
                  <Button
                    className="text-primary"
                    onClick={() => history.push("/session/forgot-password")}
                  >
                    Forgot password?
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
      <AppBar position="static">
        <Toolbar className="footer">
          <span className="m-auto"></span>
          <p className="m-0">
            Developed and Powered by <a href="https://guud.company/" target="_blank">GUUD International</a>
          </p>
        </Toolbar>
      </AppBar>
      {/* <Card className={classes.card}>
      <h1 style={{ textAlign: "center" }}>News And Announcement</h1>
      <div className="App">
        <Carousel breakPoints={breakPoints}>
        {items.map(item => <Item>{item.id}</Item>)}
        </Carousel>
      </div>
      </Card> */}
    </React.Fragment>
  );
};

export default JwtLogin;
