import React, { useState } from "react";
import {
    Grid,
    Box,
} from "@material-ui/core";

import C1InputField from "app/c1component/C1InputField";
import C1DateField from "app/c1component/C1DateField";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";

import { useStyles } from "app/c1utils/styles";
import useAuth from "app/hooks/useAuth";

const RaeoAppRegulatoryAgency = ({
    inputData,
    handleInputChange,
    handleDateChange,
    handleInputChangeDeep,
    isDisabled,
    errors,
    isSubmitting }) => {

    const [refreshPage, setRefreshPage] = useState(0);

    const classes = useStyles();
    const { user } = useAuth();

    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        setRefreshPage(refreshPage + 1);
    }

    if (!isDisabled && !(inputData?.TSctRaeoAppReg?.regAgency)) {
        // default agent name.
        //inputData.aeoAgencyName = user.coreAccn?.accnName;
        //handleInputChangeDeep("TSctRaeoAppReg.TCoreAccn.accnId", user.coreAccn?.accnId); 
        //handleInputChangeDeep("TSctRaeoAppReg.TCoreAccn.accnName", user.coreAccn?.accnName); 
        handleInputChangeDeep("TSctRaeoAppReg.regAgency", user.coreAccn?.accnName); 
    }

    const defaultUserName = (!isDisabled) ? user.name : "";
    
    return (
        <React.Fragment>
            <C1HeaderTitle>Header Information</C1HeaderTitle>
            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1DateField
                        label="Regulated Date"
                        name="aeoReviewedDate"
                        value={inputData?.TSctRaeoAppReg?.regDtLupd || ""}
                        required
                        disabled
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Regulated By"
                        name="usrName"
                        value={inputData?.TSctRaeoAppReg?.TCoreUsr?.usrName || defaultUserName}
                        required
                        disabled
                    />
                </Grid>
            </Grid>
            <C1HeaderTitle>Details</C1HeaderTitle>
            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} >

                    <C1InputField
                        label="Agency Name"
                        name="TSctRaeoAppReg.regAgency"
                        required
                        disabled={isDisabled}
                        inputProps={{ 'maxLength': 1024 }}
                        value={inputData?.TSctRaeoAppReg?.regAgency || ""}
                        onChange={handleInputChangeDeepWrap}
                        error={errors['TSctRaeoAppReg.regAgency'] !== undefined}
                        helperText={errors['TSctRaeoAppReg.regAgency'] || ''}
                    />
                    <C1InputField
                        multiline
                        label="Report Summary"
                        name="TSctRaeoAppReg.regRptSummary"
                        rows={5}
                        required
                        disabled={isDisabled}
                        inputProps={{ 'maxLength': 1024 }}
                        value={inputData?.TSctRaeoAppReg?.regRptSummary || ""}
                        onChange={handleInputChangeDeepWrap}
                        error={errors['TSctRaeoAppReg.regRptSummary'] !== undefined}
                        helperText={errors['TSctRaeoAppReg.regRptSummary'] || ''}
                    />
                </Grid>
            </Grid>
            <Box width={1}>&nbsp;</Box>
        </React.Fragment >
    );
};

export default RaeoAppRegulatoryAgency;