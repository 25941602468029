import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Divider, TextField, Box } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import { MatxLoading } from "matx";
import C1Alert from "app/c1component/C1Alert";
import C1Container from "app/c1component/C1Container";

import ConfirmationDialog from "matx/components/ConfirmationDialog";
import { useStyles, titleTab } from "app/c1component/C1Styles";
import C1SelectField from "app/c1component/C1SelectField";
import C1InputField from "app/c1component/C1InputField";
import C1PopUp from "app/c1component/C1PopUp";
import { isEmpty } from "app/c1utils/utility"
import MenuItem from "@material-ui/core/MenuItem";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import moment from "moment";
import { Icon, IconButton } from "@material-ui/core";
import useHttp from "app/c1hooks/http";
import useAuth from "../hooks/useAuth";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#3C77D0',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const C1Query = ({
    isEndWorkflow,
    appId,
    sectionDb,
    props,
    isDisabled,
    handleValidate,
}) => {


    const { user } = useAuth();
    let roles = new Set(user.authorities.map(el => el.authority));

    const [inputData, setInputData] = useState({});
    const [subSectionArray, setSubSectionArray] = useState([]);
    const [rows, setRows] = useState([]);

    const [openPopUp, setOpenPopUp] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [replyMsg, setReplyMsg] = useState("");
    const [errors, setErrors] = useState({});

    const gridClass = useStyles();
    const titleTabClasses = titleTab();

    const [isSubmitSuccess, setSubmitSuccess] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);

    const [snackBarState, setSnackBarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        msg: '',
        severity: 'success'
    });

    const [operation, setOperation] = useState("");
    const [refreshList, setRefreshList] = useState(0);
    const { isLoading, isFormSubmission, res, error, sendRequest } = useHttp();
    const [deletePopup, setDeletePopup] = useState(false);
    const [qurId, setQurId] = useState("");

    const URL = "/sct/api/entity/query";


    const isEnableCreateDelete = () => {
        //console.log(!isEndWorkflow, appId, !roles.has("RAEO_OPERATOR"), !roles.has("RAEO_OPERATOR_ADMIN"));
        if (!isEndWorkflow && appId && !roles.has("RAEO_OPERATOR") && !roles.has("RAEO_OPERATOR_ADMIN")) {
            return true;
        }
        return false;
    }

    const confirmDelete = (id) => {
        setDeletePopup(true)
        setQurId(id);
    }

    const handleDelete = () => {
        setOperation("Delete");
        sendRequest(`${URL}/${qurId}`, "id", "Delete");
    }

    //fetch  list;
    useEffect(() => {
        if (appId && appId.length > 0) {
            setOperation("List");
            sendRequest(`/api/sct/query/list/${sectionDb.moduleName}/${appId}`);
        }
    }, [sendRequest, sectionDb.moduleName, appId, refreshList]);

    useEffect(() => {
        let msg = "";
        let severity = "success";

        if ("Add" === operation) {
            msg = "Create query successfully!";
        } else if ("Delete" === operation) {
            msg = "Delete query successfully!";
        } else if ("Reply" === operation) {
            msg = "Reply query successfully!";
        }

        if (!isLoading && !error && res) {
            setFormLoading(false);
            if ("List" === operation) {
                setRows(res.data);
            } else {
                setRefreshList(refreshList + 1);
            }
        } else if (error) {
            msg = "Error encountered whilte trying to fetch remarks!";
            severity = "error";
        }

        if ((("Add" === operation) || ("Delete" === operation) || ("Reply" === operation)) && !isLoading) {
            setDeletePopup(false);
            setSubmitSuccess(true);
            setOpenPopUp(false);
            setReplyMsg("");
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: severity } });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, error, res, isFormSubmission]);
    //////////////////////////

    const handleInputChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    };
    const handleInputChangeReplyMsg = (e) => {
        setReplyMsg(e.target.value);
    };
    const handleBtnAdd = (e) => {
        e.preventDefault();

        if (!isEmpty(handleFormValidate())) {
            return;
        }

        inputData.qurApp = sectionDb.moduleName;
        inputData.qurAppId = appId;

        setOperation("Add");
        sendRequest(`${URL}`, "id", "post", { ...inputData });

        setInputData({ qurSection: '' });
        setInputData({ qurSubSection: '' });
        setInputData({ qurMessage: '' });
    }

    const handleBtnReply = (e) => {
        e.preventDefault();
        setFormLoading(true);
        setOperation("Reply");
        selectedRow.qurRpyMessage = replyMsg;
        sendRequest(`/api/sct/query/reply/${selectedRow.qurId}`, "id", "put", { ...selectedRow });
    }

    const handlePopUpReplyWindow = (qurId) => {
        setSelectedRowWrap(qurId);
        setOpenPopUp(true);
    }

    const setSelectedRowWrap = (qurId) => {

        const rowFilter = rows.filter(qry => (qry.qurId === qurId));

        setSelectedRow(rowFilter[0]);
    }

    //
    let sectionArray = [];
    for (var i = 0, len = sectionDb.sections.length; i < len; i++) {
        for (var key in sectionDb.sections[i]) {
            sectionArray[i] = key;
        }
    }

    useEffect(() => {
        if (inputData.section) {
            for (var i = 0, len = sectionDb.sections.length; i < len; i++) {
                for (var key in sectionDb.sections[i]) {
                    if (inputData.section === key) {
                        setSubSectionArray(sectionDb.sections[i][key]);
                    }
                }
            }
        }
    }, [inputData.section, sectionDb.sections]);

    const handleFormValidate = () => {
        const errors = {};

        if (!inputData.qurSection) {
            errors.qurSection = 'Required'
        }
        if (sectionDb.isDisplaySubSection) {
            if (!inputData.qurSubSection) {
                errors.qurSubSection = 'Required'
            }
        }
        if (!inputData.qurMessage) {
            errors.qurMessage = 'Required'
        }
        /*
        else {
            if (inputData.message.length < 50) {
                errors.message = 'At least 50 characters.'
            } else if (inputData.message.length > 1000) {
                errors.message = 'At most 1000 characters.'
            }
        }
        */
        setErrors(errors);

        return errors;
    }

    const handleClose = () => {
        setSnackBarState({ ...snackBarState, open: false });
    };

    let snackBar = null;
    if (isSubmitSuccess) {
        const anchorOriginV = snackBarState.vertical;
        const anchorOriginH = snackBarState.horizontal;

        snackBar = <Snackbar
            anchorOrigin={{ vertical: anchorOriginV, horizontal: anchorOriginH }}
            open={snackBarState.open}
            onClose={handleClose}
            autoHideDuration={3000}
            key={anchorOriginV + anchorOriginH
            }>
            <C1Alert onClose={handleClose} severity={snackBarState.severity}>
                {snackBarState.msg}
            </C1Alert>
        </Snackbar>;
    }


    const isReply = (qurRpyMessage) => {
        if (isEnableCreateDelete()) {
            return false; // not operator
        }

        if (qurRpyMessage && qurRpyMessage.length > 0) {
            return false // replied
        }
        if (isEndWorkflow) {
            return false // end workflow.
        }
        return true;
    }

    return (
        <React.Fragment>
            {isLoading && <MatxLoading />}
            {snackBar}
            <Box className="p-3">
                <C1Container rendered={isEnableCreateDelete()}>
                    <Grid container spacing={1}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start" >
                        <Grid item xs={3}>
                            <C1SelectField
                                label="Section"
                                name="qurSection"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={inputData.qurSection || ''}
                                disabled={isDisabled}
                                optionsMenuItemArr={
                                    sectionArray.map((item, ind) => (
                                        <MenuItem value={item} key={ind}>{item}</MenuItem>
                                    ))}
                                error={(errors && errors.qurSection)}
                                helperText={(errors && errors.qurSection)} />
                        </Grid>
                        {sectionDb.isDisplaySubSection && <Grid item xs={3}>
                            <C1SelectField
                                label="Sub Section"
                                name="qurSubSection"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={inputData.qurSubSection || ''}
                                disabled={isDisabled}
                                optionsMenuItemArr={
                                    subSectionArray.map((item, ind) => (
                                        <MenuItem value={item} key={ind}>{item}</MenuItem>
                                    ))}
                                error={(errors && errors.qurSubSection)}
                                helperText={(errors && errors.qurSubSection)} />
                        </Grid>}
                        {sectionDb.isDisplaySubSection && <Grid item xs={6}>

                        </Grid>}
                        <Grid item xs={6}>
                            <C1InputField
                                multiline
                                label="Query"
                                name="qurMessage"
                                rows={"3"}
                                required
                                type="input"
                                disabled={isDisabled}
                                onChange={(e) => { handleInputChange(e) }}
                                value={inputData.qurMessage}
                                error={(errors && errors.qurMessage)}
                                helperText={(errors && errors.qurMessage)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Box m={2}>
                                <Button type="button"
                                    disabled={isDisabled}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    m={2}
                                    onClick={handleBtnAdd}>
                                    <AddBoxIcon viewBox="1 -1 30 30"></AddBoxIcon>
                                    Add
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center" className={gridClass.gridContainer}>
                        <Grid item xs={12} className={titleTabClasses.root}>
                            Query history
                        </Grid>
                    </Grid>
                    <Divider className="mb-6" />
                </C1Container>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Section</StyledTableCell>
                                {sectionDb.isDisplaySubSection && <StyledTableCell align="left">Sub Section</StyledTableCell>}
                                <StyledTableCell align="left">Agency</StyledTableCell>
                                <StyledTableCell align="left">User</StyledTableCell>
                                <StyledTableCell align="left">Query</StyledTableCell>
                                <StyledTableCell align="left">Query Date</StyledTableCell>
                                <StyledTableCell align="left">Response Date</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row, ind) => (
                                <TableRow key={row.qurId} >
                                    <StyledTableCell align="left">{row.qurSection}</StyledTableCell>
                                    {sectionDb.isDisplaySubSection && <StyledTableCell align="left">{row.qurSubSection}</StyledTableCell>}
                                    <StyledTableCell align="left">{row.TCoreAccnByQurAcctId.accnName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.TCoreUsrByQurUsrId.usrName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.qurMessage}</StyledTableCell>
                                    <StyledTableCell>{moment(row.qurDtIssue).format('DD/MM/YYYY hh:mm:ss')}</StyledTableCell>
                                    <StyledTableCell>
                                        {row.qurDtReply ? (moment(row.qurDtReply).format('DD/MM/YYYY hh:mm:ss')) : ""}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton type="button" color="primary" onClick={() => handlePopUpReplyWindow(row.qurId)}>
                                            <Icon>visibility</Icon>
                                        </IconButton>
                                        {(isEnableCreateDelete() && !row.qurDtReply) && <IconButton type="button" color="primary" onClick={() => confirmDelete(row.qurId)}>
                                            <Icon>delete</Icon>
                                        </IconButton>}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                            {
                                (!rows || rows.length === 0) && <TableRow key={1} >
                                    <StyledTableCell colSpan="8"><p style={{ textAlign: "center" }}>Sorry, no matching records found</p></StyledTableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <ConfirmationDialog open={deletePopup}
                    onConfirmDialogClose={() => setDeletePopup(false)}
                    text="Are you confirm delete?"
                    title="Prompt"
                    onYesClick={(e) => handleDelete(e)} />

                <C1PopUp
                    title="Reply Query"
                    openPopUp={openPopUp}
                    setOpenPopUp={setOpenPopUp}>

                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={6}>
                            <C1InputField
                                label="Section"
                                name="qurSection"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.qurSection || ''}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <C1InputField
                                label="Sub Section"
                                name="qurSubSection"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.qurSubSection || ''}
                                disabled={true} />
                        </Grid>

                        <Grid item xs={6}>
                            <C1InputField
                                label="Agency"
                                name="account"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.TCoreAccnByQurAcctId ? selectedRow.TCoreAccnByQurAcctId.accnName : ''}
                                disabled={true} />
                        </Grid>
                        <Grid item xs={6}>
                            <C1InputField
                                label="User Name"
                                name="userName"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.TCoreUsrByQurUsrId ? selectedRow.TCoreUsrByQurUsrId.usrName : ''}
                                disabled={true} />
                        </Grid>
                        <Grid item xs={6}>
                            <C1InputField
                                label="Date Time"
                                name="date time"
                                required
                                onChange={(e) => { handleInputChange(e) }}
                                value={moment(selectedRow.qurDtIssue).format('DD/MM/YYYY hh:mm:ss') || ''}
                                disabled={true} />
                        </Grid>
                        <Grid item xs={6}>
                            <C1InputField
                                label="Reply Date Time"
                                name="date time"
                                onChange={(e) => { handleInputChange(e) }}
                                value={(selectedRow.qurDtReply) ? (moment(selectedRow.qurDtReply).format('DD/MM/YYYY hh:mm:ss')) : ''}
                                disabled={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <C1InputField
                                multiline
                                label="Query"
                                name="qurMessage"
                                rows={"3"}
                                type="input"
                                disabled={true}
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.qurMessage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {(selectedRow.qurRpyMessage) && <C1InputField
                                multiline
                                label="Reply Query"
                                name="message"
                                rows={3}
                                type="input"
                                disabled={true}
                                onChange={(e) => { handleInputChange(e) }}
                                value={selectedRow.qurRpyMessage}
                            />}

                            {isReply(selectedRow.qurRpyMessage) && <TextField
                                label="Reply Query"
                                name="message"
                                multiline
                                fullWidth
                                type="input"
                                variant="outlined"
                                onChange={(e) => { handleInputChangeReplyMsg(e) }}
                                value={replyMsg}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                rows={4}
                            />}
                        </Grid>
                        <Grid item xs={9}>
                        </Grid>
                        <Grid item xs={3}>
                            {isReply(selectedRow.qurRpyMessage) && <Button variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={(e) => handleBtnReply(e)}>Reply</Button>}
                            {(false) && <Button variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                onClick={(e) => setOpenPopUp(false)}>Close</Button>}
                        </Grid>
                    </Grid>


                </C1PopUp>
            </Box>
        </React.Fragment>

    );
};

export default C1Query;

