import React from "react";
import { authRoles } from "../../auth/authRoles";

const workBenchRoutes = [
    {
        path: "/workbench/:id?",
        component: React.lazy(() => import("./WorkBenchPanel")),
        auth: authRoles.admin,
    },
    {
        path: "/raeoWorkbench/:id?",
        component: React.lazy(() => import("./RaeoWorkBenchPanel")),
        auth: authRoles.admin,
    },
];

export default workBenchRoutes;
