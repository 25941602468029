import React, { useCallback, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";

import axios from 'axios.js';
import { debounce } from "lodash";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, IconButton, Tooltip } from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useTranslation } from "react-i18next";
import { green, red} from '@material-ui/core/colors';

/**
 * @description CamelOne custom datatable implementation.
 * 
 * @param isServer - required boolean value to flag if datatable records are to be retrieved from server or a supplied array of records
 * @param url - URL to retrieve the records from. Required if isServer = true
 * @param dbName -  object contains {list: []} of records to be displayed in datatable. Required if isServer = false
 * @param title - title to be displayed on the upper left of the datatable
 * @param columns - required array of columns to be displayed in the datatable
 * @param defaultOrder - column name to be sorted 
 * @param defaultOrderDirection - default is 'asc'
 * @param isShowToolbar - boolean value to display or not the toolbar located on the upper right of the datatable
 * @param showTemplate - when set should have {downloadHandler: func, uploadHandler: func} for download and upload handler of templates
 * @param showCustomDownload - when set should have {title: string, handler: func} for custom implementation of download of datatable records
 * @param showAdd - when set should have {type: ['popUp', 'redirect'], path: string, popUpHandler: func}. Path is required if type='redirect'
 * @param isShowFilter - boolean value to display filter icon
 * @param isShowFilterChip - boolean value to display filter chip (text display on the upper part of datatable when filtered)
 * @param isShowViewColumns - boolean value to display view columns option
 * @param isShowPrint - boolean value to display print icon
 * @param isShowDownload - boolean value to display download icon
 * @param isRowSelectable - boolean value if rows can be selected
 * @param isShowPagination - boolean value to display datatable pagination
 * @param filterBy - collection of key-value pair (attribute-value) to filter the datatable to
 */
const C1DataTable = ({
    isServer = true,
    url,
    extraParams,
    dbName,
    isRefresh = false,
    title,
    columns,
    defaultOrder,
    defaultOrderDirection = 'asc',
    isShowToolbar = true,
    showTemplate,
    showCustomDownload,
    showCustomFilterToolbar,
    customFilterToolbar,
    statusFieldName,
    showToolbar,
    isEmpty,
    showAdd,
    isShowFilter = true,
    isShowFilterChip = false,
    isRowsSelectable,
    isShowViewColumns = true,
    isShowPrint = true,
    isShowDownload = true,
    isRowSelectable = true,
    isShowPagination = true,
    filterBy
}) => {

    const { t } = useTranslation(["common"]);

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableFilterList: {
                chip: {
                    display: isShowFilterChip === true ? 'display' : 'none'
                }
            },
            MuiToolbar: {
                root: {
                    display: title || isShowToolbar ? 'flex' : 'none'
                },
                regular: {
                    minHeight:"auto !important"
                }
            },
            MuiTablePagination: {
                toolbar: {
                    //to show pagination if isShowPagination or title is set
                    display: !isShowPagination ? 'none' : title || isShowPagination ? 'flex' : 'none'
                }
            },
            MuiTableCell: {
                root: {
                    padding: 1
                },
                head: {
                    backgroundColor: 'rgb(60, 119, 208)',
                    color: 'white',
                    padding: 6
                },
            },
            MUIDataTableSelectCell: {
                headerCell: {
                    backgroundColor: 'rgb(60, 119, 208)',
                },
            },
            MUIDataTableHeadCell: {
                sortActive: {
                    paddingLeft: 16,
                    color: 'white'
                },
            },
            MUIDataTableToolbar: {
                filterPaper: {
                    maxWidth: "25%"
                },
            },
            MuiGridListTile: {
                root:{
                    paddingTop:"3px !important" ,
                    paddingBottom:"3px !important",
                }
            }
        }
    });


    const [c1DtState, setC1DtState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        previousPageNo: 0,
        sortOrder: { name: defaultOrder, direction: defaultOrderDirection === undefined ? 'asc' : defaultOrderDirection },
        data: [["Loading Data..."]],
        isLoading: false
    });

    const [clolumnsWithStatus, setClolumnsWithStatus] = useState(columns);

     //delay for filter search
    const debouncedFilterSearch = useCallback(debounce((tableState) => search(tableState), 1000, { maxWait: 2000 }), []);

    useEffect(() => {
        if (statusFieldName) {

            const statusColumn =
            {
                name: statusFieldName,
                label: t("list.header.status"),
                options: {
                    filter: true,
                    filterType: 'dropdown',
                    filterOptions: {
                        names: ["A", "I", "S"],
                        renderValue: v => {
                            if (v === "A") {
                                return "Active";
                            } else if (v === "I" || v === "S") {
                                return "InActive";
                            }
                        }
                    },
                    customFilterListOptions: {
                        render: v => {
                            if (v === "A") {
                                return "Active";
                            } else if (v === "I" || v === "S") {
                                return "InActive";
                            }
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === 'A')
                            return <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                        Active
                            </small>;
                        else if (value === 'I' || value === "S")
                            return <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                        Inactive
                            </small>;
                    },
                },
            };
            columns.splice(-1, 0, statusColumn);
            setClolumnsWithStatus(columns);
        }

    }, [columns, statusFieldName, t]);





    const setData = (res, isLoading) => {
        setC1DtState({
            isLoading: isLoading,
            data: res.data,
            count: res.count,
            page: res.page,
        });
    }

    const getData = (tableState) => {
        setC1DtState({ ...c1DtState, isLoading: true });
        apiRequest(tableState).then(res => {
            setData(res, false);
        });
    }

    /*Called when action from onTableChange is sort */
    const sort = (tableState) => {
        setC1DtState({ ...c1DtState, isLoading: true });
        apiRequest(tableState).then(res => {
            setData(res, false);
        });
    }

    const changePage = (tableState) => {
        setC1DtState({ ...c1DtState, isLoading: false });
        apiRequest(tableState).then(res => {
            setData(res, false);
        });
    }

    const search = (tableState) => {
        setC1DtState({ isLoading: false });
        apiRequest(tableState).then(res => {
            setData(res, false);
        });
    }

    const reset = (tableState) => {
        setC1DtState({ isLoading: false });
        tableState.filterList = [];
        apiRequest(tableState).then(res => {
            setData(res, false);
        });
    }

    const apiRequest = (tableState) => {
        //The base url from set in props
        if (!url) {
            setC1DtState({ ...c1DtState, isLoading: true });

        }

        let baseRequestUrl = url;
        return new Promise((resolve, reject) => {
            let displayStart = getDisplayStart(tableState);
            let postRequesturl = '/list?sEcho=3&iDisplayStart=' + displayStart
                + '&iDisplayLength=' + tableState.rowsPerPage
                + getSortParam(columns, tableState)
                + getQueryFieldParams(columns, tableState)
                + extraParams;

            if (isServer) {
                let requestUrl = baseRequestUrl + postRequesturl;
                setC1DtState({ ...c1DtState, previousPageNo: tableState.rowsPerPage });

                axios.get(requestUrl)
                    .then(result => {
                        resolve({
                            data: result.data.aaData,
                            page: displayStart > 0 ? tableState.page : 0,
                            count: result.data.iTotalDisplayRecords,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        //
                        alert("System busy, please try again! ");
                    });
            } else {
                if (dbName) {
                    resolve({
                        data: dbName.list,
                        page: displayStart > 0 ? tableState.page : 0,
                        count: (dbName && dbName.list) ? dbName.list.length : 0
                    });
                }
            }
        });
    }

    const getDisplayStart = (tableState) => {
        if (c1DtState.previousPageNo === tableState.page) {
            return 0;
        }

        return tableState.page * tableState.rowsPerPage;
    }

    const getQueryFieldParams = (columns, tableState) => {
        let idx = 0;
        let [sortFieldName, sortDirection] = getSortFieldNameAndDirection(columns, tableState);

        let fieldParams = '';

        if (sortDirection) {
            fieldParams = '&mDataProp_' + idx + '=' + sortFieldName;
            idx++;
        }

        //prioritize filterBy?
        if (filterBy) {
            for (let i = 0; i < filterBy.length; i++) {
                let { attribute, value } = filterBy[i];

                if (attribute && value) {
                    fieldParams += '&mDataProp_' + idx + '=' + attribute + '&sSearch_' + idx + '=' + value;
                    idx++;
                }


            }
        }

        if (tableState.filterList) {
            for (let i = 0; i < tableState.filterList.length; i++) {
                let filter = tableState.filterList[i];

                if (filter.length > 0) {
                    fieldParams += '&mDataProp_' + idx + '=' + tableState.columns[i].name + '&sSearch_' + idx + '=' + filter;
                    idx++;
                }
            }
        }


        fieldParams = fieldParams + '&iColumns=' + idx;
        return fieldParams;
    }

    const getSortParam = (columns, tableState) => {
        // eslint-disable-next-line
        let [sortFieldName, sortDirection] = getSortFieldNameAndDirection(columns, tableState);
        if (sortDirection) {
            return '&iSortCol_0=0&sSortDir_0=' + sortDirection + '&iSortingCols=1';
        }

        return '&iSortCol_0=0&sSortDir_0=asc&iSortingCols=0';
    }

    const getSortFieldNameAndDirection = (columns, tableState) => {
        if (tableState.sortOrder && tableState.sortOrder.name) {
            setC1DtState({ ...c1DtState, sortOrder: { name: tableState.sortOrder.name, direction: tableState.sortOrder.direction } });
            return [tableState.sortOrder.name, tableState.sortOrder.direction];
        }

        return getDefaultSortFieldNameAndDirection(columns);
    }

    const getDefaultSortFieldNameAndDirection = (columns) => {

        let columnsTmp = columns;
        columnsTmp = columnsTmp.filter(column => {
            return !column.options.sort;
        });

        if (columnsTmp) {
            return [columnsTmp[0].field, columnsTmp[0].options.sort];
        }

        return null;
    }

    const CustomTableToolbar = (props) => {

        const onFilterClick = (e, p, val) => {

            let statusIndexField = 0;
            for (let i = 0; i < p.columns.length; i++) {
                let obj = p.columns[i];
                if (obj.name === statusFieldName) {
                    statusIndexField = i;
                    break;
                }
            }

            if (val === 'all' || val === 'ALL' || val === 'All' || val === '')
                p.filterList[statusIndexField] = [];
            else
                p.filterList[statusIndexField] = [val];
            //trigger this action to table
            p.setTableAction('filterChange');
        }

        if (showCustomFilterToolbar && customFilterToolbar && customFilterToolbar.length > 0) {
            return (
                <div style={{ textAlign: 'right', paddingTop: '10px', paddingBottom: '10px' }}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        {
                            customFilterToolbar.map((tb, index) =>
                                <Button key={index} startIcon={tb.startIcon} size="small" onClick={(e) => onFilterClick(e, props, tb.value)}>{tb.label}</Button>
                            )
                        }
                    </ButtonGroup>
                </div>
            );

        } else {
            return null;
        }
    }

    return (
        <div id={title}>
        <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
                title={title}
                data={c1DtState.data || [["Loading Data..."]]}
                columns={clolumnsWithStatus}
                components={{
                    TableToolbar: showCustomFilterToolbar && CustomTableToolbar,

                }}
                options={{
                    customToolbar: () => {
                        let elTemplate = [];
                        if (showAdd) {
                            if (showAdd.type === "popUp") {
                                elTemplate.push(<Tooltip title="Add" aria-label="add" key="Add">
                                    <Button onClick={showAdd.popUpHandler}><AddBoxIcon viewBox="0 0 24 24" color="primary" /></Button>
                                </Tooltip>);
                            } else if(showAdd.type === "popUp1"){
                                elTemplate.push(<Tooltip title="Add" aria-label="add" key="Add">
                                    <Button onClick={   showAdd.popUpHandler}><AddBoxIcon viewBox="0 0 24 24" color="primary" /></Button>
                                </Tooltip>);
                            }
                            else {
                                elTemplate.push(<Link to={showAdd.path} key="AddPath">
                                    <Tooltip title="Add" aria-label="add">
                                        <Button><AddBoxIcon viewBox="0 0 24 24" color="primary"></AddBoxIcon></Button>
                                    </Tooltip></Link>);
                            }
                        }
                        /*
                        if (showAdd) {
                            return <Link to={showAdd.path}>
                                <Tooltip title="Add" aria-label="add">
                                    <Button><AddBoxIcon viewBox="0 0 24 24" color="primary"></AddBoxIcon></Button>
                                </Tooltip></Link>;
                        }
                        */
                        return <React.Fragment>
                            {elTemplate.map(el => el)}
                        </React.Fragment>

                    },
                    fixedHeader: false,
                    count: c1DtState.count,
                    page: c1DtState.page,
                    rowsPerPage: c1DtState.rowsPerPage,
                    jumpToPage: isShowPagination,
                    sortOrder: c1DtState.sortOrder,
                    download: isShowDownload === undefined ? true : isShowDownload,
                    filter: isShowFilter === undefined ? true : isShowFilter,
                    print: isShowPrint === undefined ? true : isShowPrint,
                    viewColumns: isShowViewColumns === undefined ? true : isShowViewColumns,
                    selectableRows: isRowSelectable === undefined ? 'multiple' : 'none',
                    filterType: "textField",
                    responsive: "standard",
                    enableNestedDataAccess: ".",
                    // selectableRows: "none", // set checkbox for each row
                    // search: false, // set search option
                    // filter: false, // set data filter option
                    // download: false, // set download option
                    // print: false, // set print option
                    // pagination: true, //set pagination option
                    // viewColumns: false, // set column option
                    elevation: 0,
                    rowsPerPageOptions: [10, 20, 40, 80, 100],
                    //disables the search icon in the toolbar
                    search: false,
                    serverSide: isEmpty ? false : true,
                    // customSort: (data, colIndex, order, meta) => {

                    //     return data.sort((a, b) => {
                    //         return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    //     });
                    // },
                    onTableChange: (action, tableState) => {

                        // a developer could react to change on an action basis or
                        // examine the state as a whole and do whatever they want
                        // console.log('Table Action: ', action, tableState);
                        switch (action) {
                            case 'changePage':
                                changePage(tableState);
                                break;
                            case 'sort':
                                sort(tableState);
                                break;
                            case 'changeRowsPerPage':
                                getData(tableState);
                                break;
                            case 'filterChange':
                                debouncedFilterSearch(tableState);
                                break;
                            case 'resetFilters':
                                reset(tableState);
                                break;
                            default:
                                // console.log('action not handled.');
                                break;
                        }

                    },
                    onTableInit: (action, tableState) => {
                        getData(tableState);

                    }
                }}
            />
        </MuiThemeProvider>
        </div>

    );

};

C1DataTable.propTypes = {
    isServer: PropTypes.bool.isRequired,
    dbName: PropTypes.exact({
        list: PropTypes.array
    }),
    url: PropTypes.string,
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    defaultOrder: PropTypes.string,
    defaultOrderDirection: PropTypes.oneOf(['asc', 'desc']),
    showTemplate: PropTypes.exact({
        downloadHandler: PropTypes.func,
        uploadhandler: PropTypes.func
    }),
    showCustomDownload: PropTypes.exact({
        title: PropTypes.string,
        handler: PropTypes.func
    }),
    showAdd: PropTypes.shape({
        type: PropTypes.oneOf(["popUp", "redirect"]),
        path: PropTypes.string,
        popUpHandler: PropTypes.func,
    }),
    isShowToolbar: PropTypes.bool,
    isShowFilter: PropTypes.bool,
    isShowFilterChip: PropTypes.bool,
    isShowViewColumns: PropTypes.bool,
    showCustomFilterToolbar: PropTypes.bool,
    isShowDownload: PropTypes.bool,
    isShowPrint: PropTypes.bool,
    isRowSelectable: PropTypes.bool,
    isShowPagination: PropTypes.bool,
    filterBy: PropTypes.array
}

export default C1DataTable;