import React from "react";

const applicationRoutes = [

    //
    {
        path: "/welcome",
        component: React.lazy(() => import("./welcome/Welcome")),
    },

    //Licensed CFA
    {
        path: "/applications/cfa/list",
        component: React.lazy(() => import("./cfa/CfaList")),
    },
    {
        path: "/applications/cfa/view/:cflTincflNo",
        component: React.lazy(() => import("./cfa/CfaFormDetails")),
    },

    //Inbound Enquiry
    {
        path: "/applications/inboundEnquiry/list",
        component: React.lazy(() => import("./enquiry/InboundEnquiryList")),
    },
    
    {
        path: "/applications/inboundEnquiry/:viewType/:paramId",
        component: React.lazy(() => import("./enquiry/InboundEnquiryFormDetails")),
    },

    {
        path: "/applications/inboundMessage/:viewType/:paramId",
        component: React.lazy(() => import("./enquiry/InboundEnquiryFormDetails")),
    },

    //Outbound Enquiry
    {
        path: "/applications/outboundEnquiry/list",
        component: React.lazy(() => import("./enquiry/OutboundEnquiryList")),
    },
    
    {
        path: "/applications/outboundEnquiry/:viewType/:paramId",
        component: React.lazy(() => import("./enquiry/OutboundEnquiryFormDetails")),
    },

    {
        path: "/applications/outboundMessage/:viewType/:paramId",
        component: React.lazy(() => import("./enquiry/OutboundEnquiryFormDetails")),
    },

    //Raeo App
    {
        path: "/applications/raeoApp/list",
        component: React.lazy(() => import("./raeo/RaeoList")),
    },
    
    {
        path: "/applications/raeoApp/:viewType/:paramId",
        component: React.lazy(() => import("./raeo/RaeoAppFormDetails")),
    },

    //Raeo
    
    {
        path: "/applications/raeo/:viewType/:paramId",
        component: React.lazy(() => import("./raeo/RaeoFormDetails")),
    },
    
    //Raeo Monitoring
    {
        path: "/applications/raeoMonitoring/:viewType/:paramId",
        component: React.lazy(() => import("./raeo/RaeoMonitoringFormDetails")),
    },

    {
        path: "/applications/raeoAppeal/:viewType/:paramId",
        component: React.lazy(() => import("./raeo/RaeoAppealFormDetails")),
    },

    {
        path: "/applications/raeoWithdrawal/:viewType/:paramId",
        component: React.lazy(() => import("./raeo/RaeoWithdrawalFormDetails")),
    },
    
];

export default applicationRoutes;

