import React, { useState } from "react";
import {
    FormHelperText,
    Button,
    Paper,
    Snackbar,
    FormControl,
} from "@material-ui/core";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { uploadFileType,uploadFileTypeRegister } from "app/c1utils/const";
import C1Alert from "app/c1component/C1Alert";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#3C77D0',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useTableStyle = makeStyles({
    table: {
        minWidth: 450,
        tableLayout: "auto"
    },
    column: {
        align: "left",
    },
});

const MAX_FILE_SIZE = 10; //10M


const RegisterDocs = ({
    handleSubmit,
    data,
    inputData,
    handleInputChange,
    handleValidate,
    viewType,
    isSubmitting,
    isDisabled,
    errors,
    props }) => {

    const tableCls = useTableStyle();


    const [isSubmitSuccess, setSubmitSuccess] = useState(false);

    const [snackBarState, setSnackBarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        msg: '',
        severity: 'success'
    });


    const [refreshList, setRefreshList] = useState(0);

    if (!inputData.accnDocs) {
        inputData.accnDocs = [];
    }

    const onLocalFileChangeHandler = (e, idx) => {
        e.preventDefault();

        let file = e.target.files[0];
        if (!file) {
            // click <Cancel> button, didn't select file,
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);
        console.log("e.target.files[0]", e.target.files[0]);

        fileReader.onload = e => {
            console.log("e.target.result", e.target.result);
            let uint8Array = new Uint8Array(e.target.result);
            if (!validteFile(uint8Array, file.name)) {
                return;
            }

            let buff = new Buffer.from(uint8Array);
            let base64data = buff.toString('base64');
            //console.log("length", imgStr.length, base64Sign.length, buff.length, base64data.length );
            //let tmp = { attType, "attName": file.name, "attData": base64data }
            //console.log("tmp", tmp);
            let tmpAccnDoc = {...inputData.accnDocs[idx],  "attName": file.name, "attData": base64data};
            inputData.accnDocs[idx] = tmpAccnDoc;
            console.log("inputData.accnDocs", inputData.accnDocs);
            setRefreshList(refreshList + 1);
        };
    };

    const validteFile = (uint8Array, fileName) => {
        console.log("uint8Array.length 1" + uint8Array.length);
        if (!uint8Array || uint8Array.length === 0) {
            console.log("uint8Array.length 2" + uint8Array.length);
            let msg = "File size is too small";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        if (uint8Array.length > MAX_FILE_SIZE * 1024 * 1024) {
            let msg = `File size can't greater than ${MAX_FILE_SIZE}MB.`;
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        //
        if (fileName.length > 128) {
            let msg = "File name shoule less than 128 characters.";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        return true;
    }

    const handleClose = () => {
        setSnackBarState({ ...snackBarState, open: false });
    };

    let snackBar = null;
    if (isSubmitSuccess) {
        const anchorOriginV = snackBarState.vertical;
        const anchorOriginH = snackBarState.horizontal;

        snackBar = <Snackbar
            anchorOrigin={{ vertical: anchorOriginV, horizontal: anchorOriginH }}
            open={snackBarState.open}
            onClose={handleClose}
            autoHideDuration={3000}
            key={anchorOriginV + anchorOriginH
            }>
            <C1Alert onClose={handleClose} severity={snackBarState.severity}>
                {snackBarState.msg}
            </C1Alert>
        </Snackbar>;
    }
    return (
        <React.Fragment>
            {snackBar}

            <TableContainer component={Paper}>
                <Table className={tableCls.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className={tableCls.column} style={{ "width": "10%", "textAlign": "center" }}>No.</StyledTableCell>
                            <StyledTableCell className={tableCls.column} style={{ "width": "50%" }}>Document Type</StyledTableCell>
                            <StyledTableCell className={tableCls.column} style={{ "width": "10%" }}>Required</StyledTableCell>
                            <StyledTableCell className={tableCls.column}>File</StyledTableCell>
                            {/* <StyledTableCell className={tableCls.column}>Action</StyledTableCell> */}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inputData.accnDocs && inputData.accnDocs.map((row, index) => (
                            <TableRow key="1">
                                <TableCell className={tableCls.column} style={{ "textAlign": "center" }}>{index + 1}</TableCell>
                                <TableCell className={tableCls.column}>{row.attDesc}</TableCell>
                                <TableCell className={tableCls.column}>Mandatory</TableCell>
                                <TableCell className={tableCls.column}>
                                    <FormControl component="fieldset"
                                        error={errors[`accnDocs.${row.attType}`] !== undefined}
                                        helperText={errors[`accnDocs.${row.attType}`] || ''}>
                                        <label htmlFor={`upload-multiple-file-${row.attType}`}>
                                            <Button
                                                type="button"
                                                disabled={isDisabled}
                                                color="primary"
                                                component="span"
                                                variant="contained"
                                                size="large" >
                                                <CloudUploadIcon viewBox="1 -1 30 30"></CloudUploadIcon>
                                                {row.attName}
                                            </Button>
                                        </label>
                                        <input
                                            className="hidden"
                                            onChange={(e) => onLocalFileChangeHandler(e, index)}
                                            id={`upload-multiple-file-${row.attType}`}
                                            disabled={isDisabled}
                                            type="file"
                                            single="true"
                                            accept={uploadFileTypeRegister}
                                        />
                                        <FormHelperText>{errors[`accnDocs.${row.attType}`]}</FormHelperText>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div>
                                <p> <b>Document Specification </b><br />
                                    * Must be .pdf, .jpg, .jpeg, or .png <br />
                                    * Max file size 10MB <br />
                                    * File name can not be more than 128 characters</p>
                            </div>
            </TableContainer>

        </React.Fragment >
    );
};

export default RegisterDocs;