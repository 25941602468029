import React from "react";
import moment from "moment";
import C1DateField from "app/c1component/C1DateField";
import C1InputField from "app/c1component/C1InputField";



//TODO to add more logic e.g. activate, deactivate, etc.
export function isEditable(viewType, isLoading) {
  let isDisabled = true;
  if (viewType === 'new')
    isDisabled = false;
  else if (viewType === 'edit' && !isLoading)
    isDisabled = false;
  else if (viewType === 'view')
    isDisabled = true;

  if (isLoading) {
    isDisabled = true;
  }

  return isDisabled;
}

export function formatDate(date, isTime) {

  let dateFormat = `DD/MM/YYYY ${isTime ? 'HH:mm:ss' : ''}`;

  if (!date) {
    return '';
  } else if (date instanceof Date) {
    // date is Date class
    return moment(date).format(dateFormat);

  } else if (date.length === 13) {
    // 1543593600000
    return moment(new Date(date)).format(dateFormat);

  } else if (date.length === 10 && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    // 2020-09-11
    return moment(date, 'YYYY-MM-DD').format(dateFormat);

  } else if (date.length === 10 && date.match(/^\d{2}-\d{2}-\d{4}$/)) {
    // 09-11-2020
    return moment(date, 'DD-MM-YYYY').format(dateFormat);

  } else if (date instanceof String && date.indexOf("T") > 0) {
    // "2020-02-20T12:30:45",
    return moment(date).format(dateFormat);

  }
  return moment(date).format(dateFormat);
}

export function formatTime(date) {
  
  let dateStr = formatDate(date, true);

  if (dateStr.length > 10) {
    return dateStr.substring(10, dateStr.length);
  }
  return dateStr;
}

/**
 * Convert Uint8Array To String, use to upload file;
 * @param {*} fileData 
 */
export const Uint8ArrayToString = (fileData) => {
  var dataString = "";
  for (var i = 0; i < fileData.length; i++) {
    dataString += String.fromCharCode(fileData[i]);
  }

  return dataString
}

/**
 * 
 * @param {*} fileName 
 * @param {*} base64String 
 */
export const downloadFile = (fileName, base64String) => {
  //console.log(`fileName = ${fileName}`);
  const downloadLink = document.createElement("a");
  downloadLink.href = `data:application/octet-stream;base64,${base64String}`;
  downloadLink.download = `${fileName}`;
  downloadLink.click();
}

const b64toBlob = (base64str, contentType = '') => {
  // decode base64 string, remove space for IE compatibility
  var binary = atob(base64str.replace(/\s/g, ''));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type: contentType });
}

export const previewPDF = (fileName, base64String) => {
  // get file extension
  const fileExt = fileName.split(".").pop();

  let blob;
  if (["jpg", "jpeg", "png"].includes(fileExt)) {
    blob = b64toBlob(base64String, "image/png");
  } else if (fileExt === "pdf") {
    blob = b64toBlob(base64String, "application/pdf");
  } else {
    return downloadFile(fileName, base64String);
  }

  window.open(URL.createObjectURL(blob), "_blank");
}

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const isArrayNotEmpty = (value) => {
  return value && Array.isArray(value) && value.length > 0;
}

export function getEvalValue(inputData, evalStr) {

  try {
    //console.log(evalStr);
    // eslint-disable-next-line no-eval
    let rst = eval(evalStr);
    //console.log(rst);
    return rst;
  }
  catch (err) {
    //console.log(err.message);
    return "";
  }
}


//To make the tabs scrollable if it's long
export function tabScroll(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


export function getExtension(filename) {
  let pos = filename.lastIndexOf(".");

  if (pos < 1)  // if file name is empty or ...
    return ""; //  `.` not found (-1) or comes first (0)

  return filename.slice(pos + 1).toLowerCase(); // extract extension ignoring `.`
}
const actions = ["customs", "save", "submit", "acknowledge", "approve", "verify", "return", "reject", "duplicate", "activate", "deactivate",
  "print", "reVerify", "appPreview", "uploadTemplate", "exit"]

export function getActionButton(initialButtons, controls, eventHandler) {
  controls.map(ctr => {
    const ctrAction = ctr.ctrlAction;
    const filter = actions.filter(a => a.toLowerCase() === ctrAction.toLowerCase())

    if (filter.length > 0) {
      let act = filter[0];
      if (act.toLowerCase() === "submit")
        act = "submitOnClick";

      else if (act.toLowerCase() === "exit")
        act = "back";

      Object.assign(initialButtons, {
        [act]: {
          show: true,
          eventHandler: () => eventHandler(ctrAction)
        },
      });
    }
  })

  return initialButtons;
}

/**Created this function to convert a string date into a date object for C1DateField. */
const createDateFromString = (strDate) => {
  if (strDate) {
    var dParts = strDate.split("/");
    return new Date(dParts[2], dParts[1] - 1, dParts[0]);
  }

  return '';

}

export const customFilterDateDisplay = (filterList, onChange, index, column) => {
  return <C1DateField
    label={column.label}
    name={column.name}
    onChange={(name, date) => {
      filterList[index].push(formatDate(date, false));//displayd as DD/MM/YYYY
      onChange(filterList[index], index, column);
    }}
    value={createDateFromString(filterList[index][0]) || ''} />
}

export const customFilterTimeDisplay = (filterList, onChange, index, column) => {
  return <C1InputField
    label={column.label}
    name={column.name}
    onChange={event => {
      filterList[index][0] = event.target.value;
      onChange(filterList[index], index, column);
    }}
    value={filterList[index][0] || ""} />
  // return <C1TimeField
  //   label={column.label}
  //   ampm={false}
  //   name={column.name}
  //   onChange={(name, date) => {
  //     console.log(date);
  //     filterList[index][0] = formatDate(date, true, true);
  //     onChange(filterList[index], index, column);
  //   }}
  //   value={filterList[index][0] || ""} />
}
