import React, { useState } from "react";
import {
    Grid,
    Box,
    MenuItem,
} from "@material-ui/core";

import C1InputField from "app/c1component/C1InputField";
import C1SelectField from "app/c1component/C1SelectField";
import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";
import cacheDataService from "app/services/cacheDataService"
import RaeoHistory from "./RaeoHistory";

const RaeoAppDetails = ({
    inputData,
    props,
    handleInputChange,
    handleDateChange,
    handleInputChangeDeep,
    viewType,
    auditList,
    isDisabled,
    errors,
    isSubmitting }) => {

    const [refreshPage, setRefreshPage] = useState(0);

    const classes = useStyles();

    let sctRaeoAppDtl = inputData.TSctRaeoAppDtl || {};

    let businessNatureList = cacheDataService.getItemData("sctRaeoMstBzNature");
    let countryList = cacheDataService.getItemData("country");
    //console.log("countryList", countryList);

    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        setRefreshPage(refreshPage + 1);
    }

    return (
        <React.Fragment>

            <C1HeaderTitle>Header Information</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Tracking Reference Number"
                        name="appId"
                        value={inputData.appId || ""}
                        required
                        disabled
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="RAEO Identifier Number"
                        name="aeoNo"
                        value={inputData?.TSctRaeo?.aeoNo || ""}
                        required
                        disabled
                    />
                </Grid>
            </Grid>

            <C1HeaderTitle>Company Details</C1HeaderTitle>

            <Grid container alignItems="flex-start" spacing={3} className={classes.gridContainer} >
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="TIN / PIN"
                        name="TSctRaeoAppDtl.appdTin"
                        value={sctRaeoAppDtl.appdTin || ""}
                        required
                        disabled
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 35 }}
                        error={errors['TSctRaeoAppDtl.appdTin'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdTin'] || ''}
                    />
                    <C1InputField
                        label="Company Full Name"
                        name="TSctRaeoAppDtl.appdCompanyName"
                        value={sctRaeoAppDtl.appdCompanyName || ""}
                        required
                        disabled
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 255 }}
                        error={errors['TSctRaeoAppDtl.appdCompanyName'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdCompanyName'] || ''}
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1SelectField
                        label="Nature of Business"
                        name="TSctRaeoAppDtl.TSctRaeoMstBzNature.bnId"
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        value={(sctRaeoAppDtl?.TSctRaeoMstBzNature?.bnId) || ""}
                        error={errors['TSctRaeoAppDtl.TSctRaeoMstBzNature'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.TSctRaeoMstBzNature'] || ''}>
                        {businessNatureList?.map((item, ind) => (
                            <MenuItem value={item.bnId} key={ind}>{item.bnName}</MenuItem>
                        ))}
                    </C1SelectField>
                    <C1DateField
                        label="Business Start Date"
                        name="TSctRaeoAppDtl.appdDtBzStart"
                        value={sctRaeoAppDtl?.appdDtBzStart}
                        required
                        disabled={isDisabled}
                        onChange={handleDateChange}
                        disableFuture={true}
                        error={errors['TSctRaeoAppDtl.appdDtBzStart'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdDtBzStart'] || ''}
                    />
                    <C1InputField
                        label="Turnover"
                        name="TSctRaeoAppDtl.appdTurnover"
                        value={sctRaeoAppDtl?.appdTurnover + "" || ""}
                        required
                        type="number"
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                        }}
                        inputProps={{ 'maxLength': 8, "min": "0", "max": "99999999.99", placeHolder: 'Transactions Volume In Tons Per Annum' }}
                        error={errors['TSctRaeoAppDtl.appdTurnover'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdTurnover'] || ''}
                    />

                </Grid>
            </Grid>

            <C1HeaderTitle>Applicant Details</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Name of Applicant"
                        name="TSctRaeoAppDtl.appdApplicantName"
                        value={sctRaeoAppDtl?.appdApplicantName || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdApplicantName'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdApplicantName'] || ''}
                    />
                    <C1InputField
                        label="Position of Applicant"
                        name="TSctRaeoAppDtl.appdApplicantPosition"
                        value={sctRaeoAppDtl?.appdApplicantPosition || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdApplicantPosition'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdApplicantPosition'] || ''}
                    />
                    <C1InputField
                        label="Email Address"
                        name="TSctRaeoAppDtl.appdEmail"
                        value={sctRaeoAppDtl?.appdEmail || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdEmail'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdEmail'] || ''}
                    />
                    <C1InputField
                        label="Telephone Number"
                        name="TSctRaeoAppDtl.appdTel"
                        value={sctRaeoAppDtl?.appdTel || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 35 }}
                        error={errors['TSctRaeoAppDtl.appdTel'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdTel'] || ''}
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Physical Address 1"
                        name="TSctRaeoAppDtl.appdAddr1"
                        value={sctRaeoAppDtl?.appdAddr1 || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdAddr1'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdAddr1'] || ''}
                    />
                    <C1InputField
                        label="Physical Address 2"
                        name="TSctRaeoAppDtl.appdAddr2"
                        value={sctRaeoAppDtl?.appdAddr2 || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdAddr2'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdAddr2'] || ''}
                    />
                    <C1InputField
                        label="Physical Address 3"
                        name="TSctRaeoAppDtl.appdAddr3"
                        value={sctRaeoAppDtl?.appdAddr3 || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 125 }}
                        error={errors['TSctRaeoAppDtl.appdAddr3'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.appdAddr3'] || ''}
                    />
                    <C1SelectField
                        label="Country"
                        name="TSctRaeoAppDtl.TMstCountry.ctyCode"
                        value={sctRaeoAppDtl?.TMstCountry?.ctyCode || ""}
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        isServer={true}
                        error={errors['TSctRaeoAppDtl.TMstCountry'] !== undefined}
                        helperText={errors['TSctRaeoAppDtl.TMstCountry'] || ''}>
                        {countryList && countryList.map((item, ind) => (
                            <MenuItem value={item.ctyCode} key={ind}>{item.ctyDescription}</MenuItem>
                        ))}
                    </C1SelectField>
                </Grid>
            </Grid>

            <RaeoHistory auditList={auditList} >
            </RaeoHistory>


            <Box width={1}>&nbsp;</Box>
        </React.Fragment>
    );
};

export default RaeoAppDetails;