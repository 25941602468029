import React from "react";
import {
    Grid,
    Box,
} from "@material-ui/core";

import C1InputField from "app/c1component/C1InputField";
import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import PageviewIcon from '@material-ui/icons/Pageview';
import IconButton from "@material-ui/core/IconButton";
import useAuth from "app/hooks/useAuth";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";
import { previewPDF } from "app/c1utils/utility";

const RaeoAppActivation = ({
    inputData,
    handleInputChange,
    handleDateChange,
    viewType,
    errors,
    isDisabled,
    isSubmitting }) => {
       
    console.log("isDisabled", isDisabled);

    const classes = useStyles();
    const { user } = useAuth();

    const viewFile = (fileName, data) => {
        console.log("fileName", fileName, data);
        previewPDF(fileName+".pdf", data);
    };
    
    const defaultUserName = (!isDisabled) ? user.name : "";
    console.log("defaultUserName", isDisabled, defaultUserName);
    
    return (
        <React.Fragment>
            <C1HeaderTitle>Header Information</C1HeaderTitle>


            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={4} md={6} xs={12} >
                    <C1InputField
                        label="RAEO Identification Number"
                        name="mssgId"
                        required
                        value={inputData?.TSctRaeo?.aeoNo || ""}
                        disabled
                        onChange={handleInputChange}
                        errors={errors && errors.mssgId ? true : false}
                        helperText={errors && errors.mssgId ? errors.mssgId : null}
                    />
                </Grid>
                <Grid item lg={4} md={6} xs={12} >
                    <C1DateField
                        label="Activated Date"
                        name="mssgId"
                        required
                        disabled
                        value={inputData.TSctRaeoAppAct?.actDtLupd || ""}
                    />
                </Grid>
                <Grid item lg={4} md={6} xs={12} >
                    <C1InputField
                        label="Activated By"
                        name="mssgId"
                        required
                        disabled
                        value={ inputData?.TSctRaeoAppAct?.TCoreUsr?.usrName || defaultUserName}
                    />
                </Grid>
            </Grid>

            <C1HeaderTitle>Certificate</C1HeaderTitle>

            {
                inputData.appWkflStatus === "Activated" &&
                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12} >
                        View Certificate
                        <IconButton aria-label="Preview" type="button"
                                color="primary" onClick={(e) => viewFile(inputData.appId, inputData.appCert)}>
                            <PageviewIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }
            <Box width={1}>&nbsp;</Box>
        </React.Fragment >
    );
};

export default RaeoAppActivation;