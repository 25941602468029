import React, { useState } from "react";
import {
    Grid, Box
} from "@material-ui/core";

import C1InputField from "app/c1component/C1InputField";
import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import useAuth from "app/hooks/useAuth";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";
import C1Container from "app/c1component/C1Container"
import { isRaeoOperator } from "app/sctcomponent/SctUtilities";

const RaeoAppReviewAcceptance = ({
    inputData,
    handleInputChange,
    handleInputChangeDeep,
    auditList,
    viewType,
    isDisabled,
    errors,
    isSubmitting }) => {

    const classes = useStyles();
    const { user } = useAuth();

    const [refreshPage, setRefreshPage] = useState(0);

    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        setRefreshPage(refreshPage + 1);
    }

    console.log(user);

    const defaultUserName = (!isDisabled) ? user.name : "";

    return (
        <React.Fragment>
            <C1HeaderTitle>Header Information</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1DateField
                        label="Reviewed Date"
                        name="aeoReviewedDate"
                        value={inputData.TSctRaeoAppRa?.raDtLupd || ""}
                        required
                        disabled
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Reviewed By"
                        name="usrName"
                        value={inputData?.TSctRaeoAppRa?.TCoreUsr?.usrName || defaultUserName}
                        required
                        disabled
                    />
                </Grid>
            </Grid>

            <C1Container rendered={true}>

                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            label="Comments"
                            name="TSctRaeoAppRa.raReason"
                            value={inputData?.TSctRaeoAppRa?.raReason || ""}
                            multiline
                            rows={10}
                            required
                            disabled={isDisabled}
                            onChange={handleInputChangeDeepWrap}
                            inputProps={{ 'maxLength': 1024 }}
                            error={errors['TSctRaeoAppRa.raReason'] !== undefined}
                            helperText={errors['TSctRaeoAppRa.raReason'] || ''}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            label="Corrections / Additional Information Required"
                            name="TSctRaeoAppRa.raCorrection"
                            value={inputData?.TSctRaeoAppRa?.raCorrection || ""}
                            multiline
                            rows={10}
                            required
                            disabled={isDisabled}
                            onChange={handleInputChangeDeepWrap}
                            inputProps={{ 'maxLength': 1024 }}
                            error={errors['TSctRaeoAppRa.raCorrection'] !== undefined}
                            helperText={errors['TSctRaeoAppRa.raCorrection'] || ''}
                        />
                    </Grid>
                </Grid>
            </C1Container>

            <Box width={1}>&nbsp;</Box>

        </React.Fragment>
    );
};

export default RaeoAppReviewAcceptance;