import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    Paper,
    Box,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Snackbar,
} from "@material-ui/core";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import pako from "pako";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { MatxLoading } from "matx";
import C1Alert from "app/c1component/C1Alert";
import C1InputField from "app/c1component/C1InputField";
import C1Container from "app/c1component/C1Container";
import C1SelectField from "app/c1component/C1SelectField";

import { downloadFile, isEmpty } from "app/c1utils/utility";
import { useStyles } from "app/c1utils/styles";
import useHttp from "app/c1hooks/http";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#3C77D0',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useTableStyle = makeStyles({
    table: {
        minWidth: 450,
        tableLayout: "auto",
    },
    column: {
        width: 50,
    },
});

const MAX_FILE_SIZE = 10; //10M

const isCompress = false; // don't compress,

const SupportingDocs = ({
    docTypeArray,
    fileType,
    inputData,
    isDisabled,
    handleInputChangeAttachment,
    attachList,
    appType,
    appId,
    props }) => {


    const tableCls = useTableStyle();
    const fieldClass = useStyles();

    let url = "/api/sct/attach";

    const [rows, setRows] = useState(attachList || []);
    const initUploadFile = { fileType: fileType, appType, appId, extAttr: {}, "fileName": "Select file" };
    const [uploadFile, setUploadFile] = useState(initUploadFile);

    const [isSubmitSuccess, setSubmitSuccess] = useState(false);
    const [, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [snackBarState, setSnackBarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        msg: '',
        severity: 'success'
    });
    const [refreshList, setRefreshList] = useState(0);
    const [docTypes,] = useState(docTypeArray);
    const { isLoading, isFormSubmission, res, urlId, error, sendRequest } = useHttp();

    let uploadFileType = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain";
    uploadFileType = uploadFileType + ", application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    uploadFileType = uploadFileType + ", application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    uploadFileType = uploadFileType + ", application/pdf, image/*";

    const handleInputChange = (e) => {
        setUploadFile({ ...uploadFile, "extAttr": { ...uploadFile.extAttr, [e.target.name]: e.target.value } });
    };

    const onLocalFileChangeHandler = (e) => {
        e.preventDefault();

        let file = e.target.files[0];
        if (!file) {
            // click <Cancel> button, didn't select file,
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);
        console.log("e.target.files[0]", e.target.files[0]);

        fileReader.onload = e => {
            console.log("e.target.result", e.target.result);
            let uint8Array = new Uint8Array(e.target.result);
            if (!validteFile(uint8Array, file.name)) {
                return;
            }
            if (isCompress) {
                let originalLength = uint8Array.length;
                uint8Array = pako.deflate(uint8Array);
                console.log("length:", uint8Array.length - originalLength, originalLength);
            }
            //let imgStr = Uint8ArrayToString(uint8Array);
            //let base64Sign = btoa(imgStr);
            let buff = new Buffer.from(uint8Array);
            let base64data = buff.toString('base64');
            //console.log("length", imgStr.length, base64Sign.length, buff.length, base64data.length );
            let tmp = { ...uploadFile, "compress": isCompress, "fileName": file.name, "data": base64data }
            setUploadFile(tmp);
        };
    };

    const validteFile = (uint8Array, fileName) => {
        console.log("uint8Array.length 1" + uint8Array.length);
        if (!uint8Array || uint8Array.length === 0) {
            console.log("uint8Array.length 2" + uint8Array.length);
            let msg = "File size is too small";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        if (uint8Array.length > MAX_FILE_SIZE * 1024 * 1024) {
            let msg = `File size can't greater than ${MAX_FILE_SIZE}M.`;
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        //
        let filterByFileName = rows.filter(file => file.fileName === fileName);
        if (fileName.length > 128) {
            let msg = "File name shoule less than 128 characters.";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }
        if (filterByFileName.length > 0) {
            let msg = "Don't upload duplicate file name.";
            setSubmitSuccess(true);
            setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: "error" } });
            return false;
        }

        return true;
    }

    const setWrapRows = (files) => {
        if(handleInputChangeAttachment) {
            handleInputChangeAttachment("", files);
        }
        console.log("files:", files);

        setRows(files);
    }

    const handleBtnAddFile = (e) => {
        e.preventDefault();
        console.log("setUploadFile", uploadFile, appType, appId);

        if (!isEmpty(handleFormValidate())) {
            return;
        }
        console.log("setUploadFile2", uploadFile.compress);

        sendRequest(`${url}`, "Upload", "Post", uploadFile);
        //setUploadFile({ attUcrNo: '', attUid: '', attSeq: '', attType: '', attReferenceid: '', attName: '', attDesc: '', attData: '' });
    }

    const resetForm = () => {
        setUploadFile(initUploadFile);
    }

    const handleDeleteFile = (e, attId) => {
        e.preventDefault();
        sendRequest(`${url}/${attId}`, "Delete", "Delete");
    }

    const handleViewFile = (e, attId) => {
        sendRequest(`${url}/${attId}`, "Download");
    };

    const viewFile = (fileName, data) => {
        console.log("fileName", fileName, data);
        downloadFile(fileName, data);
    };

    /////////////////
    const handleFormValidate = () => {
        const errors = {};

        if (!uploadFile.extAttr.docType) {
            errors.docType = 'Required'
        }
        if (!uploadFile.extAttr.refNo) {
            errors.refNo = 'Required'
        }
        if (!uploadFile.data) {
            errors.data = 'Required'
        }
        setErrors(errors);

        return errors;
    }
    /////////////////
    //fetch  list;

    useEffect(() => {

        console.log("attachList docs", attachList);
        if ( (appId && appId.length > 0 && !attachList )
            || refreshList > 0 ) {
            sendRequest(`${url}/${appType}/${appId}/${fileType}`, "List");
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendRequest, appType, appId, refreshList]);

    /////////////////
    useEffect(() => {
        let msg = "";
        let severity = "success";

        if ("Upload" === urlId) {
            msg = "Upload file successfully!";
        } else if ("Delete" === urlId) {
            msg = "Delete file successfully!";
        }

        if (!isLoading && !error && res) {
            setFormLoading(false);
            if ("List" === urlId) {
                let files = res.data;
                setWrapRows(files);

            } else if ("Download" === urlId) {
                viewFile(res.data.fileName, res.data.data);
            } else {
                setRefreshList(refreshList + 1);
            }

            if (("Upload" === urlId) || ("Delete" === urlId)) {
                setSubmitSuccess(true);
                setSnackBarState(sb => { return { ...sb, open: true, msg: msg, severity: severity } });
            }

            if ("Upload" === urlId) {
                resetForm();
            }
        } else if (error) {
            msg = "Error encountered whilte trying to fetch data!";
            severity = "error";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, error, res, urlId, isFormSubmission]);


    const handleClose = () => {
        setSnackBarState({ ...snackBarState, open: false });
    };

    let snackBar = null;
    if (isSubmitSuccess) {
        const anchorOriginV = snackBarState.vertical;
        const anchorOriginH = snackBarState.horizontal;

        snackBar = <Snackbar
            anchorOrigin={{ vertical: anchorOriginV, horizontal: anchorOriginH }}
            open={snackBarState.open}
            onClose={handleClose}
            autoHideDuration={3000}
            key={anchorOriginV + anchorOriginH
            }>
            <C1Alert onClose={handleClose} severity={snackBarState.severity}>
                {snackBarState.msg}
            </C1Alert>
        </Snackbar>;
    }

    return (
        <React.Fragment>
            {isLoading && <MatxLoading />}
            {snackBar}
            <Box width={1}>
                <C1Container rendered={!isDisabled}>
                    <Grid container alignItems="flex-start" spacing={3} className={fieldClass.gridContainer}>
                        <Grid item xs={3}>
                            <C1SelectField
                                label="Doc Type"
                                name="docType"
                                required
                                onChange={handleInputChange}
                                value={uploadFile.extAttr.docType || ""}
                                disabled={isDisabled}
                                optionsMenuItemArr={
                                    docTypes.map((item, ind) => (
                                        <MenuItem value={item.desc} key={ind}>{item.desc}</MenuItem>
                                    ))}
                                error={(errors && errors.docType) !== undefined}
                                helperText={(errors && errors.docType) || ''} />
                        </Grid>
                        <Grid item xs={3}>
                            <C1InputField
                                required
                                label="Doc Ref No."
                                name="refNo"
                                type="input"
                                disabled={isDisabled}
                                onChange={handleInputChange}
                                inputProps={{ 'maxLength': 100 }}
                                value={uploadFile.extAttr.refNo || ""}
                                error={(errors && errors.refNo) !== undefined}
                                helperText={(errors && errors.refNo) || ''} />
                        </Grid>
                        <Grid item xs={6}>
                            <Box mt={2}>
                                <label htmlFor="upload-multiple-file">
                                    <Button
                                        type="button"
                                        disabled={isDisabled}
                                        color="primary"
                                        component="span"
                                        variant="contained"
                                        size="large" >
                                        <CloudUploadIcon viewBox="1 -1 30 30"></CloudUploadIcon>{uploadFile?.fileName}
                                    </Button>
                                </label>
                                <input
                                    className="hidden"
                                    onChange={onLocalFileChangeHandler}
                                    id="upload-multiple-file"
                                    disabled={isDisabled}
                                    type="file"
                                    single="true"
                                    accept={uploadFileType}
                                />
                                <Button type="button"
                                    ml={3}
                                    style={{ "marginLeft": "16px" }}
                                    disabled={isDisabled} color="primary" variant="contained"
                                    size="large" onClick={handleBtnAddFile}>
                                    <AddBoxIcon viewBox="1 -1 30 30"></AddBoxIcon>Add
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </C1Container>
            </Box>

            <Box className="m-3 pt-3">
                <TableContainer component={Paper}>
                    <Table className={tableCls.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">No.</StyledTableCell>
                                <StyledTableCell align="center">Document Type</StyledTableCell>
                                <StyledTableCell align="center">Document Name</StyledTableCell>
                                <StyledTableCell align="center">Document Ref No</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, idx) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{idx + 1}</TableCell>
                                    <TableCell align="center">{row.extAttr ? row.extAttr.docType : ""}</TableCell>
                                    <TableCell align="center">{row.fileName}</TableCell>
                                    <TableCell align="center">{row.extAttr ? row.extAttr.refNo : ""}</TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="Preview" type="button"
                                            color="primary" onClick={(e) => handleViewFile(e, row.id)}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                        {!isDisabled && <IconButton aria-label="Delete" type="button"
                                            color="primary" onClick={(e) => handleDeleteFile(e, row.id)}>
                                            <DeleteIcon />
                                        </IconButton>}

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment >
    );
};

export default SupportingDocs;

SupportingDocs.defaultProps = {
    docTypeArray: [],
    fileType: "SUP"
};