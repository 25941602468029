
import React from "react";
import { green, orange, blue, red, purple, cyan, grey } from '@material-ui/core/colors';
import { yellow } from "@material-ui/core/colors";

const C1StatusLabel = ({ value }) => {
    //console.log("value=C1StatusLabel= " + value, JSON.stringify(value));

    switch (value) {        
        case 'DA Issued':
        case 'DA-Issued':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: grey[999] }}>
                    DA-Issued
                </small>
            );
        case 'Reviewed':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: grey[200], color: grey[999] }}>
                    Reviewed
                </small>
            );
        case 'Closed':
        case 'C':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: grey[200], color: yellow[900] }}>
                    Closed
                </small>
            );
        case 'Draft':
        case 'D':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: blue[200], color: cyan[900] }}>
                    Draft
                </small>
            );
        case 'Submit':
        case 'Submitted':
        case 'Pending Verification':
        case 'Pending Acknowledgement':
        case 'Pending':
        case 'P':
        case 'S':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: orange[200], color: orange[900] }}>
                    Submitted
                </small>
            );
        case 'Pending Approval':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: blue[200], color: blue[800] }}>
                    Verified
                </small>
            );
        case 'Approved':
        case 'A':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                    Approved
                </small>
            );
        case 'Acknowledged':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                    Acknowledged
                </small>
            );
        case 'Rejected':
        case 'R':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                    Rejected
                </small>
            );
        case 'Active':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                    Active
                </small>
            );
        case 'Inactive':
        case 'I':
            return (
                <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                    Inactive
                </small>
            );        
        case 'CFA-A':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                Active
            </small>
        );
        case 'CFA-S':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Suspended
            </small>
        );
        case 'CFA-E':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Expired
            </small>
        );
        case 'CFA-D':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Deregistered
            </small>
        );
        case 'Accepted':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: orange[200], color: orange[800] }}>
                Accepted
            </small>
        );
        case 'Regulated':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: orange[200], color: orange[800] }}>
                Regulated
            </small>
        );
        case 'Validated':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: orange[200], color: orange[800] }}>
                Validated
            </small>
        );
        case 'Recommended':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: orange[200], color: orange[800] }}>
                Recommended
            </small>
        );        
        case 'Activated':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: green[200], color: green[800] }}>
                Activated
            </small>
        );       
        case 'Withdrawn':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Withdrawn
            </small>
        ); 
        case 'Suspended':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Suspended
            </small>
        ); 
        case 'Revoked':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Revoked
            </small>
        ); 
        case '1':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Successful
            </small>
        );       
        case '0':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                General error
            </small>
        );       
        case '-1':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                UnrecoverableKeyException
            </small>
        );       
        case '-2':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                UnrecoverableKeyException
            </small>
        );       
        case '-3':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                NoSuchAlgorithmException
            </small>
        );       
        case '-4':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                KeyStoreException
            </small>
        );       
        case '-5':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                SignatureException
            </small>
        );       
        case '-6':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                CertificateException
            </small>
        );       
        case '-7':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                InvalidKeySpecException
            </small>
        );       
        case '-8':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Source cannot be authenticated
            </small>
        );       
        case '-10':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Request IP does not match source
            </small>
        );       
        case '-11':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Message tampered with
            </small>
        );  
        case '-12':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Unrecognized message type
            </small>
        );       
        case '-13':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                JAXBException (Error creating JAXBContext)
            </small>
        );       
        case '-14':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                JAXBException (Error creating umarshaller)
            </small>
        );       
        case '-15':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Error unmarshalling object
            </small>
        );       
        case '-16':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Duplicate record
            </small>
        );       
        case '-17':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Error persisting object
            </small>
        );       
        case '-18':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Missing Mandatory Fields
            </small>
        );       
        case '-19':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Unknown Sender
            </small>
        );       
        case '-20':
        return (
            <small className="px-1 py-2px border-radius-4" style={{ backgroundColor: red[200], color: red[800] }}>
                Message can't be understood e.g. null value for recieved parameter
            </small>
        );       
        case '-21':
        return (
            <small className="px-1 py-2px border-radius-10" style={{ backgroundColor: red[200], color: red[800] }}>
                Unknown reference document
            </small>
        );       
        default: return <div> {value}</div>;
    }
}

export default C1StatusLabel;