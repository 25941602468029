import React from "react";

const masterTableRoutes = [
  // for Account Types
  {
    path: "/master/accounttypes/list",
    component: React.lazy(() => import("./masters/accounttypes/AccountTypeList")),
  },

  {
    path: "/master/accounttypes/:viewType/:atypId",
    component: React.lazy(() => import("./masters/accounttypes/AccountTypesFormDetails")),
  },

  // for Account Types
  {
    path: "/master/attachmenttypes/list",
    component: React.lazy(() => import("./masters/attachmenttypes/AttachmentTypesList")),
  },

  {
    path: "/master/attachmenttypes/:viewType/:mattId",
    component: React.lazy(() => import("./masters/attachmenttypes/AttachmentTypeFormDetails")),
  },

  // for Contact Types
  {
    path: "/master/contacttypes/list",
    component: React.lazy(() => import("./masters/contacttypes/ContactTypesList")),
  },

  {
    path: "/master/contacttypes/:viewType/:ctypId",
    component: React.lazy(() => import("./masters/contacttypes/ContactTypeFormDetails")),
  },

  // for Country
  {
    path: "/master/country/list",
    component: React.lazy(() => import("./masters/country/CountryList")),
  },

  {
    path: "/master/country/:viewType/:ctryCode",
    component: React.lazy(() => import("./masters/country/CountryFormDetails")),
  },

  //for currency
  {
    path: "/master/currency/list",
    component: React.lazy(() => import("./masters/currency/CurrencyList")),
  },
  {
    path: "/master/currency/:viewType/:ccyCode?",
    component: React.lazy(() =>
      import("./masters/currency/CurrencyFormDetails")
    ),
  },

  //for Harmonized
  {
    path: "/master/hsCode/list",
    component: React.lazy(() => import("./masters/hsCode/HsCodeList")),
  },
  {
    path: "/master/hsCode/:viewType/:hsCode?",
    component: React.lazy(() => import("./masters/hsCode/HsCodeFormDetails")),
  },

  //for port
  {
    path: "/master/port/list",
    component: React.lazy(() => import("./masters/port/PortList")),
  },
  {
    path: "/master/port/:viewType/:portCode?",
    component: React.lazy(() => import("./masters/port/PortFormDetails")),
  },

  //for UOM
  {
    path: "/master/uom/list",
    component: React.lazy(() => import("./masters/uom/UOMCodesList")),
  },
  {
    path: "/master/uom/:viewType/:uomCode",
    component: React.lazy(() => import("./masters/uom/UOMCodeFormDetails")),
  },

  //for UOM Conversion
  {
    path: "/master/uomconversion/list",
    component: React.lazy(() => import("./masters/uomconversion/UOMConversionList")),
  },
  {
      path: "/master/uomconversion/:viewType/:id",
      component: React.lazy(() => import("./masters/uomconversion/UOMConversionFormDetails")),
  },

  //for BORDER POST
  {
    path: "/master/borderpost/list",
    component: React.lazy(() => import("./masters/borderpost/BorderPostsList")),
  },
  {
      path: "/master/borderpost/:viewType/:id",
      component: React.lazy(() => import("./masters/borderpost/BorderPostFormDetails")),
  },

  //for Currency Exchange Rate List
  {
      path: "/master/currencyFxConfig/list",
      component: React.lazy(() => import("./masters/currencyfxconfig/CurrencyFxConfigList")),
  },
  {
      path: "/master/currencyFxConfig/:viewType/:cfxcId?",
      component: React.lazy(() => import("./masters/currencyfxconfig/CurrencyFxConfigFormDetails")),
  },
  
  //for Incoterms 
  {
    path: "/master/incoTerms/list",
    component: React.lazy(() => import("./masters/incoTerms/IncoTermsList")),
  },
  {
      path: "/master/incoTerms/:viewType/:incoCode?",
      component: React.lazy(() => import("./masters/incoTerms/IncoTermsFormDetails")),
  },

  //for package
  {
      path: "/master/package/list",
      component: React.lazy(() => import("./masters/package/PackageList")),
  },
  {
      path: "/master/package/:viewType/:pkgCode?",
      component: React.lazy(() => import("./masters/package/PackageFormDetails")),
  },

  //for container
  {
      path: "/master/container/list",
      component: React.lazy(() => import("./masters/container/ContainerList")),
  },
  {
      path: "/master/container/:viewType/:cntCode?",
      component: React.lazy(() => import("./masters/container/ContainerFormDetails")),
  },

    //for Benefit Type
  {
    path: "/master/benefitType/list",
    component: React.lazy(() => import("./masters/benefitType/BenefitTypeList")),
  },
   {
    path: "/master/benefitType/:viewType/:beneId",
    component: React.lazy(() => import("./masters/benefitType/BenefitTypeFormDetails")),
   },

   //for Complaint Type
  {
    path: "/master/complaintType/list",
    component: React.lazy(() => import("./masters/complaintType/ComplaintTypeList")),
  },
   {
    path: "/master/complaintType/:viewType/:cmptId",
    component: React.lazy(() => import("./masters/complaintType/ComplaintTypeFormDetails")),
   },

   //for Offence Nature
  {
    path: "/master/offenceNature/list",
    component: React.lazy(() => import("./masters/offenceNature/OffenceNatureList")),
  },
   {
    path: "/master/offenceNature/:viewType/:offnId",
    component: React.lazy(() => import("./masters/offenceNature/OffenceNatureFormDetails")),
   },

   //for Offence Type
  {
    path: "/master/offenceType/list",
    component: React.lazy(() => import("./masters/offenceType/OffenceTypeList")),
  },
   {
    path: "/master/offenceType/:viewType/:offtId",
    component: React.lazy(() => import("./masters/offenceType/OffenceTypeFormDetails")),
   },

     //for withdrawalReason
  {
    path: "/master/withdrawalReason/list",
    component: React.lazy(() => import("./masters/withdrawalReason/WithdrawalreasonList")),
  },
   {
    path: "/master/withdrawalReason/:viewType/:widrId",
    component: React.lazy(() => import("./masters/withdrawalReason/WithdrawalreasonFormDetails")),
   },

   //for Business Nature
   {
    path: "/master/businessnature/list",
    component: React.lazy(() => import("./masters/businessnature/BusinessNatureList")),
  },
   {
    path: "/master/businessnature/:viewType/:bnId",
    component: React.lazy(() => import("./masters/businessnature/BusinessNatureFormDetails")),
   },

   //for Doc Review
   {
    path: "/master/docreview/list",
    component: React.lazy(() => import("./masters/docreview/DocReviewList")),
  },
   {
    path: "/master/docreview/:viewType/:docreviewId",
    component: React.lazy(() => import("./masters/docreview/DocReviewFormDetails")),
   },

   //for ICT Equipment Type
   {
    path: "/master/icteqtype/list",
    component: React.lazy(() => import("./masters/icteqtype/IcTeqTypeList")),
  },
   {
    path: "/master/icteqtype/:viewType/:icteqtId",
    component: React.lazy(() => import("./masters/icteqtype/IcTeqTypeFormDetails")),
   },

   //for ICTS Level
   {
    path: "/master/ictslevel/list",
    component: React.lazy(() => import("./masters/ictslevel/IctsLevelList")),
  },
   {
    path: "/master/ictslevel/:viewType/:ictslId",
    component: React.lazy(() => import("./masters/ictslevel/IctsLevelFormDetails")),
   },

   //for Methodology 
   {
    path: "/master/methodology/list",
    component: React.lazy(() => import("./masters/methodology/MethodologyList")),
  },
   {
    path: "/master/methodology/:viewType/:mthdId",
    component: React.lazy(() => import("./masters/methodology/MethodologyFormDetails")),
   },

   //for Method Valid 
   {
    path: "/master/methodvalid/list",
    component: React.lazy(() => import("./masters/methodvalid/MethodValidList")),
  },
   {
    path: "/master/methodvalid/:viewType/:mthdvId",
    component: React.lazy(() => import("./masters/methodvalid/MethodValidFormDetails")),
   },

   //for Premises Ownership 
   {
    path: "/master/premown/list",
    component: React.lazy(() => import("./masters/premown/PremOwnList")),
  },
   {
    path: "/master/premown/:viewType/:permowntId",
    component: React.lazy(() => import("./masters/premown/PremOwnFormDetails")),
   },

   //for Recommendation Type
   {
    path: "/master/recomtype/list",
    component: React.lazy(() => import("./masters/recomtype/RecomTypeList")),
  },
   {
    path: "/master/recomtype/:viewType/:recomtId",
    component: React.lazy(() => import("./masters/recomtype/RecomTypeFormDetails")),
   },

   //for Risk Level
   {
    path: "/master/risklevel/list",
    component: React.lazy(() => import("./masters/risklevel/RiskLevelList")),
  },
   {
    path: "/master/risklevel/:viewType/:risklvlId",
    component: React.lazy(() => import("./masters/risklevel/RiskLevelFormDetails")),
   },

];



export default masterTableRoutes;
