import {
    Box,
    Divider, Grid, Icon, Paper, Tab, Tabs
} from "@material-ui/core";
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DetailsIcon from '@material-ui/icons/Details';
import GavelIcon from '@material-ui/icons/Gavel';

import RateReviewIcon from '@material-ui/icons/RateReview';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import axios from 'axios';
import { MatxLoading } from "matx";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import RaeoAuditTab from "./RaeoAuditTab";
import C1FormButtons from "app/c1component/C1FormButtons";

import C1FormDetailsPanel from "app/c1component/C1FormDetailsPanel";
import C1Query from "app/c1component/C1Query";
import C1TabContainer from "app/c1component/C1TabContainer";
import withErrorHandler from "app/hoc/withErrorHandler/withErrorHandler";
import useAuth from "app/hooks/useAuth";

import { tabScroll } from "app/c1utils/styles";

import RaeoAppActivation from "./RaeoAppActivation.jsx";
import RaeoAppApproval from "./RaeoAppApproval.jsx";
import RaeoAppAssessmentValidation from "./RaeoAppAssessmentValidation.jsx";
import RaeoAppCommitteeRecommendation from "./RaeoAppCommitteeRecommendation.jsx";
import RaeoAppDetails from "./RaeoAppDetails.jsx";
import RaeoAppDigitalMou from "./RaeoAppDigitalMou.jsx";
import RaeoAppRegulatoryAgency from "./RaeoAppRegulatoryAgency.jsx";
import RaeoAppReviewAcceptance from "./RaeoAppReviewAcceptance.jsx";

import SupportingDocs from "app/sctcomponent/SupportingDocs";
import * as RaeoTabUtils from "../RaeoTabUtils";
import cacheDataService from "app/services/cacheDataService"


import useHttp from "app/c1hooks/http";

const tabList = [
    { idx: 0, text: "AEO Application", icon: <DetailsIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_DETAIL },  // 0
    { idx: 1, text: "Supporting Documents", icon: <AttachFileIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_DETAIL },
    { idx: 2, text: "Review & Acceptance", icon: <RateReviewIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_REVIEW },
    { idx: 3, text: "Regulatory Agency", icon: <GavelIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_REGULATORY },
    { idx: 4, text: "Assessment & Validation", icon: <AssessmentIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_VALIDATION },
    { idx: 5, text: "Committee Recommendation", icon: <SupervisorAccountIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_COMMITTEE },  // 5
    { idx: 6, text: "AEO Approval", icon: <SpellcheckIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_APPROVAL },
    { idx: 7, text: "Digital MOU", icon: <VerifiedUserIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_MOU },
    { idx: 8, text: "AEO Activation", icon: <TouchAppIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_ACTIVATION }, // 8
    //{ idx:9, text: "Query", icon: <LiveHelpIcon />, permTab: RaeoTabUtils.RAEO_APP_TAB_QUERY },
    //{ idx: 9, text: "Audits", icon: <Icon>schedule</Icon>, permTab: RaeoTabUtils.RAEO_APP_TAB_AUDIT },
];

var parent_window = window.opener;
console.log("parent_window", parent_window);
if( parent_window) {
    // Get cacheDataService data from Parent window.
    let parentCacheDataService = parent_window.getSctCacheDataService();
    cacheDataService.setAllItem(parentCacheDataService.getAllItem());
    console.log(cacheDataService.getAllItem());
}

const RaeoAppHistory = () => {

    let { paramId, viewType = "view" } = useParams();
    console.log("begin: ", paramId, viewType);

    const { user } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const [tabContainerIndex, setTabContainerIndex] = useState(0);


    const [, setIsAlive] = useState(true);
    const [inputData, setInputData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    // paramId is existing and not euqal 0.
    // eslint-disable-next-line eqeqeq
    const [historyId, setHistoryId] = useState((paramId && (paramId != 0) && paramId.length > 5) ? paramId : null);

    const [loading, setLoading] = useState(false);

    const [, setFormLoading] = useState(false);
    const { isLoading, isFormSubmission, res, validation, error, urlId, sendRequest } = useHttp();
    const defaultSnackbarValue = {
        success: false,
        successMsg: "",
        error: false,
        errorMsg: "",
        redirectPath: ""
    }
    const [snackBarOptions, setSnackBarOptions] = useState(defaultSnackbarValue);

    const [permissionTabs, setPermissionTabs] = useState([tabList[0], tabList[1]]);

    useEffect(() => {
        setLoading(false);
        // like:/view/appId, 
        console.log("historyId", historyId);
        if (historyId && historyId.length > 5) {
            sendRequest(`/sct/api/entity/raeoAppHistory/${historyId}`, 'doFetch');
        }

        return () => setIsAlive(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyId, viewType]);

    useEffect(() => {

        if (!isLoading && !error && res && !validation) {
            setFormLoading(false);
            switch (urlId) {
                case "doFetch": {
                    console.log(typeof res.data.aeohBase);

                    setInputData(JSON.parse(res.data.aeohBase));
                    setPermissionTabs(RaeoTabUtils.getPermissionTabs(user.role, tabList, res.data.appWkflStatus));
                    break;
                }
                default:
            }
        } else if (error) {
            console.error(error);
            setLoading(false);
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, error, res, urlId, isFormSubmission, validation]);

    const handleTabChange = (e, value) => {
        console.log("handleTabChange " + value);
        setTabIndex(value);
        let containerIdx = permissionTabs[value].idx;
        setTabContainerIndex(containerIdx);
    };

    const handleValidate = () => {
        console.log("Validate handler");
    };

    const handleInputChange = (e) => {
        //console.log("handleInputChangeCurrent", e.target.name, e.currenttarget.name);
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    };

    const handleInputChangeAV = (e) => {
    };

    const handleInputChangeCR = (e) => {
    };
    const handleInputChangeDeep = (name, value) => {
    };

    const handleDateChange = (name, e) => {
    }

    const handleInputChangeAttachment = (name, e) => {
    }

    const isDisableTab = (tabName) => {

        return true;
    }

    const isOperatorEdit = (tabName) => {

        return false;
    }


    let formButtons = <C1FormButtons options={{
        back: {
            show: true,
            eventHandler: () => { window.close(); }
        }
    }}>

    </C1FormButtons>;

    //console.log("!isDisableTab(tabList[tabIndex].permTab) ", tabIndex, tabList[tabIndex].permTab, !isDisableTab(tabList[tabIndex].permTab));

    /////////////////////////
    let reviewAudits = [];
    let validationAudits = [];
    let recommendAudits = [];
    let approveAudits = [];
    let auditList = inputData.TSctRaeoAppHistories;
    if (auditList && auditList.length > 0) {
        reviewAudits = auditList.filter(item => item.aeohWorkflowPath === "Accept");
        validationAudits = auditList.filter(item => item.aeohWorkflowPath === "Validate");
        recommendAudits = auditList.filter(item => item.aeohWorkflowPath === "Recommend");
        approveAudits = auditList.filter(item => item.aeohWorkflowPath === "Approve");
    }
    //console.log(reviewAudits);
    //console.log(validationAudits);
    //console.log(recommendAudits);
    //console.log(approveAudits);
    /////////////////////////

    let bcLabel = 'RAEO Application';
    let sectionDb = {
        moduleName: "RAEOAPP",
        isDisplaySubSection: false,
        sections: [
            { "AEO Application": [] },
            { "Supporting Documents": [] },
            { "Review & Acceptance": [] },
            { "Assessment & Validation": [] },
            { "Regulatory Agency": [] },
            { "Committee Recommendation": [] },
            { "AEO Approval": [] },
            { "Digital MOU": [] },
            { "AEO Activation": [] },
        ]
    };

    let docTypeArray = [{ value: "PremisesLayout", desc: "Premises Layout" },
    { value: "OrganizationlBusiness", desc: "Organizationl/Business Structure" },
    { value: "previousFinancialStatement", desc: "1-2 years previous Financial Statement" },
    { value: "Others", desc: "Others" }];

    return (
        <React.Fragment>
            {loading && <MatxLoading />}
            <C1FormDetailsPanel

                title={bcLabel}
                titleEx={inputData?.appWkflStatus}
                formButtons={formButtons}
                initialValues={{ ...inputData }}
                values={{ ...inputData }}
                onValidate={handleValidate}
                snackBarOptions={snackBarOptions}
                isLoading={loading}>
                {(props) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Tabs
                                    className="mt-4"
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {permissionTabs && permissionTabs.map((item, ind) => {
                                        return <Tab className="capitalize" value={ind} label={item.text} key={ind} icon={item.icon} {...tabScroll(ind)} />
                                    })}
                                </Tabs>
                                <Divider className="mb-6" />
                                <C1TabContainer>
                                    <Box width={1}>
                                        {tabContainerIndex === 0 && <RaeoAppDetails
                                            inputData={inputData}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(!isOperatorEdit(tabList[0].permTab))} />}
                                        {tabContainerIndex === 1 && <SupportingDocs
                                            data={inputData} inputData={inputData}
                                            attachList={inputData.attachList}
                                            handleInputChangeAttachment={handleInputChangeAttachment}
                                            docTypeArray={docTypeArray}
                                            isSubmitting={loading}
                                            appType={"RAEOAPP"}
                                            appId={historyId}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[1].permTab))} />}
                                        {tabContainerIndex === 2 && <RaeoAppReviewAcceptance
                                            inputData={inputData}
                                            viewType={viewType}
                                            auditList={reviewAudits}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[2].permTab))} />}
                                        {tabContainerIndex === 3 && <RaeoAppRegulatoryAgency
                                            inputData={inputData}
                                            viewType={viewType}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[3].permTab))} />}
                                        {tabContainerIndex === 4 && <RaeoAppAssessmentValidation
                                            inputData={inputData}
                                            viewType={viewType}
                                            auditList={validationAudits}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeAV={handleInputChangeAV}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[4].permTab))} />}
                                        {tabContainerIndex === 5 && <RaeoAppCommitteeRecommendation
                                            inputData={inputData}
                                            viewType={viewType}
                                            auditList={recommendAudits}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeCR={handleInputChangeCR}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[5].permTab))} />}
                                        {tabContainerIndex === 6 && <RaeoAppApproval
                                            inputData={inputData}
                                            viewType={viewType}
                                            auditList={approveAudits}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[6].permTab))} />}
                                        {tabContainerIndex === 7 && <RaeoAppDigitalMou
                                            inputData={inputData}
                                            viewType={viewType}
                                            errors={validationErrors}
                                            handleInputChange={handleInputChange} />}
                                        {tabContainerIndex === 8 && <RaeoAppActivation
                                            inputData={inputData}
                                            viewType={viewType}
                                            handleInputChange={handleInputChange}
                                            handleInputChangeDeep={handleInputChangeDeep}
                                            errors={validationErrors}
                                            isDisabled={(isDisableTab(tabList[8].permTab))} />}

                                    </Box>
                                </C1TabContainer>
                            </Paper>

                        </Grid>
                    </Grid>
                )}

            </C1FormDetailsPanel >
        </React.Fragment >
    );


};

export default withErrorHandler(RaeoAppHistory, axios);
