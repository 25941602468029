import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import { MatxLoading } from "matx";
import { setUserData } from "app/redux/actions/UserActions";
import { useDispatch } from "react-redux";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  token: null,
  profile: null
};

const isValidToken = (accessToken) => {

  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  return decodedToken.exp > Date.now();
};

const setSession = (accessToken) => {

  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setProfile = (profile) => {
  if (profile) {
    localStorage.setItem("profile", JSON.stringify(profile));
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        token: action.payload.token

      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "PROFILE": {
      const { profile } = action.payload;

      return {
        ...state,
        profile
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  getProfile: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchRedux = useDispatch();

  const login = async (email, password) => {

    const response = await axios.post("/api/sctLogin", { id: email, password: password });
    
    const { user, token } = response.data;
    setSession(token);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        token: token
      },
    });

    dispatchRedux(setUserData(user));
    return response.data;
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    axios.post("/api/logout")
      .then(res => {
        setSession(null);
        dispatch({
          type: "LOGOUT",
          payload: {
            token: null,
            isAuthenticated: false,
            user: null,
          },
        });
      })
      .catch(err => {
        console.log(err);
      });

  };

  const getProfile = async () => {

    const response = await axios.get("/api/getPrincipal");
    const profile = response.data;
    setProfile(profile);

    dispatch({
      type: "PROFILE",
      payload: {
        profile
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get("/api/co/cac/profile/");

          if (response && response.err) {
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: false,
              },
            });
          } else {
            const { user } = response.data;

            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }

        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        getProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
