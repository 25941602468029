import React from "react";
import {
    Grid, Box
} from "@material-ui/core";

import C1InputField from "app/c1component/C1InputField";
import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import C1Container from "app/c1component/C1Container"
import { isRaeoOperator } from "app/sctcomponent/SctUtilities";

import useAuth from "app/hooks/useAuth";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";

const RaeoAppApproval = ({
    inputData,
    auditList,
    handleInputChange,
    handleInputChangeDeep,
    handleDateChange,
    viewType,
    errors,
    isDisabled,
    isSubmitting }) => {

    const classes = useStyles();
    const { user } = useAuth();



    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        //setRefreshPage(refreshPage + 1);
    }

    const defaultUserName = (!isDisabled) ? user.name : "";

    return (
        <React.Fragment>

            <C1HeaderTitle>Header Information</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1DateField
                        label="Approved Date"
                        name="message"
                        value={inputData.TSctRaeoAppApr?.aprDtLupd || ""}
                        type="input"
                        required
                        disabled
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Approved By"
                        name="message"
                        type="input"
                        value={inputData?.TSctRaeoAppApr?.TCoreUsr?.usrName || defaultUserName}
                        required
                        disabled
                    />
                </Grid>
            </Grid>

            <C1Container rendered={true}>

                <C1HeaderTitle></C1HeaderTitle>

                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Comments"
                            name="TSctRaeoAppApr.aprComments"
                            value={inputData?.TSctRaeoAppApr?.aprComments || ""}
                            rows={10}
                            type="input"
                            required
                            disabled={isDisabled}
                            onChange={handleInputChangeDeepWrap}
                            inputProps={{ 'maxLength': 1024 }}
                            error={errors['TSctRaeoAppApr.aprComments'] !== undefined}
                            helperText={errors['TSctRaeoAppApr.aprComments'] || ''}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Corrections / Additional Information Required"
                            name="TSctRaeoAppApr.aprCorrAddinfo"
                            value={inputData?.TSctRaeoAppApr?.aprCorrAddinfo || ""}
                            rows={10}
                            type="input"
                            required
                            disabled={isDisabled}
                            onChange={handleInputChangeDeepWrap}
                            inputProps={{ 'maxLength': 1024 }}
                            error={errors['TSctRaeoAppApr.aprCorrAddinfo'] !== undefined}
                            helperText={errors['TSctRaeoAppApr.aprCorrAddinfo'] || ''}
                        />
                    </Grid>
                </Grid>

            </C1Container>

            <Box width={1}>&nbsp;</Box>

        </React.Fragment >
    );
};

export default RaeoAppApproval;