import React from 'react';
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import HistoryIcon from '@material-ui/icons/History';

import PropTypes from 'prop-types';


const useStyles = makeStyles({
    btn: {
        padding: "5px",
    }
});
/**
 * @param previewEventHandler - Preview applications details
 * @param downloadDocsEventHandler - Download supporting documents
 * @param printPreviewEventHandler - Preview application documents (document produced when approved)
 * @param editPath - Edit application details
 * @param viewPath - View application details
 * @param copyPath - Duplicate application details
 * @param activatePath - Activate application details
 * @param deactivatePath - Deactivate application details
 * @param removeEventHandler - Delete application detail
 * 
 */
const C1DataTableActions = ({
    previewEventHandler,
    downloadDocsEventHandler,
    printPreviewEventHandler,
    retriggerEventHandler,
    viewResponseEventHandler,
    editPath,
    viewPath,
    copyPath,
    activatePath,
    deactivatePath,
    removeEventHandler,
    viewHistoryEventHandler
}) => {

    const classes = useStyles();

    return (
        < div className="flex items-center" >
            <div className="flex-grow"></div>
            {previewEventHandler && <Tooltip title="Preview Details">
                <IconButton aria-label="Preview Details" type="button" color="primary" onClick={previewEventHandler}>
                    <DescriptionIcon />
                </IconButton>
            </Tooltip>}

            {downloadDocsEventHandler && <Tooltip title="Download Supporting Documents">
                <IconButton aria-label="Download Supporting Documents" type="button" color="primary" onClick={downloadDocsEventHandler} >
                    <AttachmentIcon />
                </IconButton>
            </Tooltip>}

            {printPreviewEventHandler && <Tooltip title="Preview">
                <IconButton aria-label="Preview" type="button" color="primary" onClick={printPreviewEventHandler} >
                    <GetAppIcon />
                </IconButton>
            </Tooltip>
            }

            {editPath && <Link to={editPath}>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </Link>
            }

            {viewPath && <Link to={viewPath}>
                <Tooltip title="View">
                    <IconButton className={classes.btn}>
                        <VisibilityIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </Link>
            }

            {copyPath && <Link to={copyPath}>
                <Tooltip title="Duplicate">
                    <IconButton>
                        <FileCopyIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </Link>}

            {activatePath && <Link to={activatePath}>
                <Tooltip title="Deactivate">
                    <IconButton>
                        <LinkIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </Link>}

            {deactivatePath && <Link to={deactivatePath}>
                <Tooltip title="Deactivate">
                    <IconButton>
                        <LinkOffIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </Link>}


            {removeEventHandler && <Tooltip title="Delete">
                <IconButton onClick={removeEventHandler} >
                    <DeleteIcon color="primary" />
                </IconButton>
            </Tooltip>
            }

            {retriggerEventHandler &&
                <Tooltip title="Retrigger">
                    <IconButton onClick={retriggerEventHandler} className={classes.btn}>
                        <ReplayIcon color="primary" />
                    </IconButton>
                </Tooltip>
            }

            {viewResponseEventHandler &&
                <Tooltip title="View Response">
                    <IconButton onClick={viewResponseEventHandler}>
                        <DescriptionIcon color="primary" />
                    </IconButton>
                </Tooltip>
            }
            {viewHistoryEventHandler && <Tooltip title="Preview">
                <IconButton aria-label="Preview" type="button" color="primary" onClick={viewHistoryEventHandler} >
                    <HistoryIcon />
                </IconButton>
            </Tooltip>
            }

        </div >);
}

C1DataTableActions.propTypes = {
    previewEventHandler: PropTypes.func,
    downloadDocsEventHandler: PropTypes.func,
    printPreviewEventHandler: PropTypes.func,
    editPath: PropTypes.string,
    viewPath: PropTypes.string,
    copyPath: PropTypes.string,
    activatePath: PropTypes.string,
    deactivatePath: PropTypes.string,
    removeEventHandler: PropTypes.func
}

export default C1DataTableActions;