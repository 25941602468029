import React, { useState } from "react";
import {
    Grid,
    MenuItem,
    Button,
    Paper,
    Tooltip,
    IconButton,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    FormHelperText,
} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import C1InputField from "app/c1component/C1InputField";
import C1SelectField from "app/c1component/C1SelectField";
import { useStyles } from "app/c1utils/styles";
import C1DateField from "app/c1component/C1DateField";
import useAuth from "app/hooks/useAuth";
import C1HeaderTitle from "app/c1component/C1HeaderTitle";
import RaeoHistory from "./RaeoHistory";
import SupportingDocs from "app/sctcomponent/SupportingDocs";
import cacheDataService from "app/services/cacheDataService"
import C1Container from "app/c1component/C1Container"
import { isRaeoOperator } from "app/sctcomponent/SctUtilities";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#3C77D0',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const RaeoAppAssessmentValidation = ({
    inputData,
    auditList,
    handleInputChange,
    handleInputChangeDeep,
    viewType,
    isDisabled,
    errors,
    isSubmitting }) => {

    const classes = useStyles();

    const { user } = useAuth();

    let tSctRaeoAppAv = inputData.TSctRaeoAppAv;
    let tradingPartners = tSctRaeoAppAv?.TSctRaeoAppAvTradePartns;
    let ictEqs = tSctRaeoAppAv?.TSctRaeoAppAvIctEqps;
    let icts = tSctRaeoAppAv?.TSctRaeoAppAvIctSystems;

    const [selectedRowIctEq, setSelectedRowIctEq] = useState({});
    const [selectedRowIct, setSelectedRowIct] = useState({});
    const [selectedRowTp, setSelectedRowTp] = useState({});
    const [refreshPage, setRefreshPage] = useState(0);


    let ownershipPremises = cacheDataService.getItemData("sctRaeoMstPermownType");
    if (ownershipPremises) {
        ownershipPremises.sort((a, b) => a.permowntSeq - b.permowntSeq);
    }
    //console.log("ownershipPremises", ownershipPremises);

    let ictLevels = cacheDataService.getItemData("sctRaeoMstIctsLevel");
    if(ictLevels) {
        ictLevels.sort((a, b) => a.ictslSeq - b.ictslSeq);
    }
    //console.log("ictLevels", ictLevels);

    let ictEqTypes = cacheDataService.getItemData("sctRaeoMstIcteqType");
    if(ictEqTypes) {
        ictEqTypes.sort((a, b) => a.icteqSeq - b.icteqSeq);
    }
    console.log("ictEqTypes", ictEqTypes);

    const docTypes = [
        { value: 'RM', desc: "Records Management" },
        { value: 'CR', desc: "Complaince Records" },
        { value: 'VN', desc: "Volume and Nature of Business" },
        { value: 'FS', desc: "Financial Solvency" },
    ];
    /*
        const handleBtnViewICT = (idx) => {
            setIctAction("View");
            setSelectedRow(icts[idx]);
            setIctOpenPopUp(true);
        }
    
        const handleBtnAddICT = (e) => {
            console.log("handleBtnAddICT");
            setIctAction("Add");
            setSelectedRow({});
            setIctOpenPopUp(true);
        }
    */

    ////////////////////////////////////
    const handleChangeTp = (e) => {
        console.log("handleChangeIctTP", e.target.name, e.target.value);
        setSelectedRowTp({ ...selectedRowTp, [e.target.name]: e.target.value });
    }

    const handleSaveTp = (e) => {
        if (selectedRowTp.tpName !== undefined || selectedRowTp.tpAddress !== undefined) {
            //console.log("handleSaveIct", selectedRowIct);
            tradingPartners.push(selectedRowTp);
            //console.log("icts", icts);
            setSelectedRowTp({});
            setRefreshPage(refreshPage + 1);
        }
    }

    const handleDeleteTp = (e, idx) => {
        console.log("handleDeleteTp", idx);
        tradingPartners.splice(idx, 1);
        setRefreshPage(refreshPage + 1);
    }

    ////////////////////////////////////
    const handleChangeIctEq = (e) => {
        console.log("handleChangeIct", e.target.name, e.target.value);
        setSelectedRowIctEq({ ...selectedRowIctEq, [e.target.name]: e.target.value });
    }

    const handleSaveIctEq = (e) => {
        //console.log("handleSaveIct", selectedRowIct);
        if (selectedRowIctEq.icteqtId !== undefined || selectedRowIctEq.icteqDesc !== undefined) {
            let mstIctEq = ictEqTypes.filter(r => r.icteqtId === selectedRowIctEq.icteqtId)[0];
            let avIctEq = { "icteqDesc": selectedRowIctEq.icteqDesc, "TSctRaeoMstIcteqType": mstIctEq };
            ictEqs.push(avIctEq);
            //console.log("icts", icts);
            setSelectedRowIctEq({});
            setRefreshPage(refreshPage + 1);
        }
    }

    const handleDeleteIctEq = (e, idx) => {
        console.log("handleDeleteIctEq", idx);
        ictEqs.splice(idx, 1);
        setRefreshPage(refreshPage + 1);
    }
    ////////////////////////////////////
    const handleChangeIct = (e) => {
        console.log("handleChangeIct", e.target.name, e.target.value);
        setSelectedRowIct({ ...selectedRowIct, [e.target.name]: e.target.value });
    }

    const handleSaveIct = (e) => {
        //console.log("handleSaveIct", selectedRowIct);
        let mstIctLevel = ictLevels.filter(r => r.ictslId === selectedRowIct.ictslId)[0];
        let avIctSystem = { "ictsName": selectedRowIct.ictsName, "TSctRaeoMstIctsLevel": mstIctLevel };
        icts.push(avIctSystem);
        //console.log("icts", icts);
        setSelectedRowIct({});
        setRefreshPage(refreshPage + 1);
    }

    const handleDeleteIct = (e, idx) => {
        console.log("handleDeleteIct", idx);
        icts.splice(idx, 1);
        setRefreshPage(refreshPage + 1);
    }
    ////////////////////////////////////

    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        setRefreshPage(refreshPage + 1);
    }
    const handleInputChangeAttachment = (name, e) => {
        tSctRaeoAppAv.attachList = e;
    }

    const defaultUserName = (!isDisabled) ? user.name : "";

    return (
        <React.Fragment>

            <C1HeaderTitle>Header Information</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={6} md={6} xs={12} >
                    <C1DateField
                        label="Validated Date"
                        name="aeovValidatedDate"
                        value={tSctRaeoAppAv?.avDtLupd || ""}
                        required
                        disabled
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    <C1InputField
                        label="Validated By"
                        name="usrName"
                        value={tSctRaeoAppAv?.TCoreUsr?.usrName || defaultUserName}
                        required
                        disabled
                    />
                </Grid>
            </Grid>


            <C1HeaderTitle>Details</C1HeaderTitle>

            <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item lg={12} md={12} xs={12} >
                    <C1InputField
                        label="Security and Safety of the Premises"
                        name="TSctRaeoAppAv.avPremSecurity"
                        multiline
                        rows={16}
                        required
                        inputProps={{ 'maxLength': 1024 }}
                        value={tSctRaeoAppAv.avPremSecurity || ""}
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        error={errors['TSctRaeoAppAv.avPremSecurity'] !== undefined}
                        helperText={errors['TSctRaeoAppAv.avPremSecurity'] || ''}
                    />
                    <C1SelectField
                        label="Ownership of the Premises"
                        name="TSctRaeoAppAv.TSctRaeoMstPermownType.permowntId"
                        required
                        disabled={isDisabled}
                        onChange={handleInputChangeDeepWrap}
                        inputProps={{ 'maxLength': 1024 }}
                        value={tSctRaeoAppAv?.TSctRaeoMstPermownType?.permowntId || ""}
                        error={errors['TSctRaeoAppAv.TSctRaeoMstPermownType'] !== undefined}
                        helperText={errors['TSctRaeoAppAv.TSctRaeoMstPermownType'] || ''}>
                        {ownershipPremises?.map((item, ind) => (
                            <MenuItem value={item.permowntId} key={ind}>{item.permowntName}</MenuItem>
                        ))}
                    </C1SelectField>
                </Grid>
            </Grid>

            <C1HeaderTitle>Trading Partners </C1HeaderTitle>

            {(!isDisabled) && <Box className="m-3">
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={5}>
                        <C1InputField
                            label="Partner Name"
                            name="tpName"
                            onChange={handleChangeTp}
                            inputProps={{ 'maxLength': 35 }}
                            value={selectedRowTp.tpName || ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <C1InputField
                            label="Partner Address"
                            name="tpAddress"
                            onChange={handleChangeTp}
                            inputProps={{ 'maxLength': 125 }}
                            value={selectedRowTp.tpAddress || ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box >
                            <Tooltip title="Add" aria-label="add">
                                <Button type="button" disabled={isDisabled} color="primary" variant="contained"
                                    size="large" onClick={handleSaveTp}>
                                    <AddBoxIcon viewBox="1 -1 30 30"></AddBoxIcon>Add
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>}

            <Box className="m-3 pt-3">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sl. No.</StyledTableCell>
                                <StyledTableCell align="left">Partner Name</StyledTableCell>
                                <StyledTableCell align="left">Partner Address</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tradingPartners && tradingPartners.map((row, ind) => (
                                <TableRow key={ind}>
                                    <StyledTableCell align="left">{ind + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row.tpName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.tpAddress}</StyledTableCell>
                                    <StyledTableCell>
                                        {!isDisabled && <IconButton aria-label="Delete" type="button"
                                            color="primary" onClick={(e) => handleDeleteTp(e, ind)}>
                                            <DeleteIcon />
                                        </IconButton>}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                            {
                                (!tradingPartners || tradingPartners.length === 0) && <TableRow key={1} >
                                    <StyledTableCell colSpan="8"><p style={{ textAlign: "center" }}>Sorry, no matching records found</p></StyledTableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormControl component="fieldset" error={errors['TSctRaeoAppAv.TSctRaeoAppAvTradePartns'] !== undefined} >
                    <FormHelperText>{errors['TSctRaeoAppAv.TSctRaeoAppAvTradePartns']}</FormHelperText>
                </FormControl>
            </Box>

            <C1HeaderTitle>ICT Equipments </C1HeaderTitle>

            {(!isDisabled) && <Box className="m-3">
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={5}>
                        <C1SelectField
                            label="Equipment Type"
                            name="icteqtId"
                            required
                            disabled={isDisabled}
                            onChange={handleChangeIctEq}
                            value={selectedRowIctEq.icteqtId || ''}>
                            {ictEqTypes?.map((item, ind) => (
                                <MenuItem value={item.icteqtId} key={ind}>{item.icteqtName}</MenuItem>
                            ))}
                        </C1SelectField>
                    </Grid>
                    <Grid item xs={5}>
                        <C1InputField
                            label="Equipment Description"
                            name="icteqDesc"
                            onChange={handleChangeIctEq}
                            inputProps={{ 'maxLength': 125 }}
                            value={selectedRowIctEq.icteqDesc || ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box >
                            <Tooltip title="Add" aria-label="add">
                                <Button type="button" disabled={isDisabled} color="primary" variant="contained"
                                    size="large" onClick={handleSaveIctEq}>
                                    <AddBoxIcon viewBox="1 -1 30 30"></AddBoxIcon>Add
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>}

            <Box className="m-3 pt-3">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sl. No.</StyledTableCell>
                                <StyledTableCell align="left">Equipment Type</StyledTableCell>
                                <StyledTableCell align="left">Equipment Description</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ictEqs && ictEqs.map((row, ind) => (
                                <TableRow key={ind}>
                                    <StyledTableCell align="left">{ind + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row.TSctRaeoMstIcteqType?.icteqtName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.icteqDesc}</StyledTableCell>
                                    <StyledTableCell>
                                        {!isDisabled && <IconButton aria-label="Delete" type="button"
                                            color="primary" onClick={(e) => handleDeleteIctEq(e, ind)}>
                                            <DeleteIcon />
                                        </IconButton>}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                            {
                                (!ictEqs || ictEqs.length === 0) && <TableRow key={1} >
                                    <StyledTableCell colSpan="8"><p style={{ textAlign: "center" }}>Sorry, no matching records found</p></StyledTableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormControl component="fieldset" error={errors['TSctRaeoAppAv.TSctRaeoAppAvIctEqps'] !== undefined} >
                    <FormHelperText>{errors['TSctRaeoAppAv.TSctRaeoAppAvIctEqps']}</FormHelperText>
                </FormControl>
            </Box>

            <C1HeaderTitle>ICT Systems </C1HeaderTitle>

            {(!isDisabled) && <Box className="m-3">
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={5}>
                        <C1InputField
                            label="System"
                            name="ictsName"
                            onChange={handleChangeIct}
                            inputProps={{ 'maxLength': 125 }}
                            value={selectedRowIct.ictsName || ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <C1SelectField
                            label="Level of System"
                            name="ictslId"
                            required
                            disabled={isDisabled}
                            onChange={handleChangeIct}
                            value={selectedRowIct.ictslId || ''}>
                            {ictLevels?.map((item, ind) => (
                                <MenuItem value={item.ictslId} key={ind}>{item.ictslName}</MenuItem>
                            ))}
                        </C1SelectField>
                    </Grid>
                    <Grid item xs={2}>
                        <Box >
                            <Tooltip title="Add" aria-label="add">
                                <Button type="button" disabled={isDisabled} color="primary" variant="contained"
                                    size="large" onClick={handleSaveIct}>
                                    <AddBoxIcon viewBox="1 -1 30 30"></AddBoxIcon>Add
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>}
            <Box className="m-3 pt-3">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sl. No.</StyledTableCell>
                                <StyledTableCell align="left">System</StyledTableCell>
                                <StyledTableCell align="left">Level of System</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {icts && icts.map((row, ind) => (
                                <TableRow key={ind}>
                                    <StyledTableCell align="left">{ind + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row.ictsName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.TSctRaeoMstIctsLevel?.ictslName}</StyledTableCell>
                                    <StyledTableCell>
                                        {!isDisabled && <IconButton aria-label="Delete" type="button"
                                            color="primary" onClick={(e) => handleDeleteIct(e, ind)}>
                                            <DeleteIcon />
                                        </IconButton>}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                            {
                                (!icts || icts.length === 0) && <TableRow key={1} >
                                    <StyledTableCell colSpan="8"><p style={{ textAlign: "center" }}>Sorry, no matching records found</p></StyledTableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormControl component="fieldset" error={errors['TSctRaeoAppAv.TSctRaeoAppAvIctSystems'] !== undefined} >
                    <FormHelperText>{errors['TSctRaeoAppAv.TSctRaeoAppAvIctSystems']}</FormHelperText>
                </FormControl>
            </Box>

            <C1HeaderTitle>Documents</C1HeaderTitle>

            <SupportingDocs
                docTypeArray={docTypes}
                attachList={tSctRaeoAppAv?.attachList}
                viewType={viewType}
                isEndWorkflow={false}
                isDisabled={isDisabled}
                fileType="VALIDATION_DOC"
                handleInputChangeAttachment={handleInputChangeAttachment}
                appType={"RAEOAPP"}
                appId={inputData.appId}
            />
            <Box className="m-3 pt-3">
                <FormControl component="fieldset" error={errors['TSctRaeoAppAv.VALIDATION_DOC'] !== undefined} >
                    <FormHelperText>{errors['TSctRaeoAppAv.VALIDATION_DOC']}</FormHelperText>
                </FormControl>
            </Box>

            <C1Container rendered={true}>

                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Comments"
                            name="TSctRaeoAppAv.avComment"
                            rows={10}
                            type="input"
                            required
                            disabled={isDisabled}
                            inputProps={{ 'maxLength': 1024 }}
                            value={tSctRaeoAppAv.avComment || ""}
                            onChange={handleInputChangeDeepWrap}
                            error={errors['TSctRaeoAppAv.avComment'] !== undefined}
                            helperText={errors['TSctRaeoAppAv.avComment'] || ''}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <C1InputField
                            multiline
                            label="Corrections / Additional Information Required"
                            name="TSctRaeoAppAv.avCorrAddinfo"
                            rows={10}
                            type="input"
                            required
                            disabled={isDisabled}
                            inputProps={{ 'maxLength': 1024 }}
                            value={tSctRaeoAppAv.avCorrAddinfo || ""}
                            onChange={handleInputChangeDeepWrap}
                            error={errors['TSctRaeoAppAv.avCorrAddinfo'] !== undefined}
                            helperText={errors['TSctRaeoAppAv.avCorrAddinfo'] || ''}
                        />
                    </Grid>
                </Grid>

            </C1Container>

            <RaeoHistory auditList={auditList}>
            </RaeoHistory>

        </React.Fragment>
    );
};

export default RaeoAppAssessmentValidation;