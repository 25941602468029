

export const navigations = [

  {
    name: "WorkBench",
    path: "/workbench",
    icon: "dashboard",
  },
  {
    name: "Administrator Services",
    icon: "build",
    auth: ["SYS_SUPER_ADMIN"],
    children: [
      {
        name: "Manage Account",
        path: "/accounts/manageAccount/list",
      }, 
      {
        name: "Account onboarding",
        path: "/accounts/onboarding/list",
      }, 
      {
        name: "Manage Users",
        path: "/accountUser/ManageUsers/list",
      },
      {
        name: "Configuration",
        children: [
          {
            name: "System",
            path: "/core/sysparam/list",        
          }, 
          {
            name: " C1 Master Tables",
            children: [
              {
                name: "Account Types",
                path: "/master/accounttypes/list",
              },
              {
                name: "Attachment Types",
                path: "/master/attachmenttypes/list",
              },
              {
                name: "UOM Codes",
                path: "/master/uom/list",
              },
              {
                name: "UOM Conversion",
                path: "/master/uomconversion/list",
              },
              {
                name: "IncoTerms Codes",
                path: "/master/incoTerms/list",
              },
              {
                name: "Package Types",
                path: "/master/package/list",
              },
              {
                name: "Contact Types",
                path: "/master/contacttypes/list",
              },              
              {
                name: "Container Types",
                path: "/master/container/list",
              },
              {
                name: "Country Codes",
                path: "/master/country/list",
              },
              {
                name: "Port Codes",
                path: "/master/port/list",
              },
              {
                name: "Currency Codes",
                path: "/master/currency/list",
              },
              {
                name: "Exchange Codes",
                path: "/master/currencyFxConfig/list",
              },
              {
                name: "Harmonized Sytem Codes",
                path: "/master/hsCode/list",
              },
              {
                name: "Border Posts",
                path: "/master/borderpost/list",
              },
              
            ],        
          },
          {
            name: " RAEO Master Tables",
            children: [
              {
                name: "Benefit Type",
                path: "/master/benefitType/list",
              },
              {
                name: "Business Nature",
                path: "/master/businessnature/list",
              },
              {
                name: "Complaint Type",
                path: "/master/complaintType/list",
              },
              {
                name: "Document Review",
                path: "/master/docreview/list",
              },
              {
                name: "ICT Equipment Type",
                path: "/master/icteqtype/list",
              },
              {
                name: "ICTS Level",
                path: "/master/ictslevel/list",
              },
              {
                name: "Methodology Options",
                path: "/master/methodvalid/list",
              },
              {
                name: "Methodology",
                path: "/master/methodology/list",
              },
              {
                name: "Offence Nature",
                path: "/master/offenceNature/list",
              },
              {
                name: "Offence Type",
                path: "/master/offenceType/list",
              },
              {
                name: "Premises Ownership",
                path: "/master/premown/list",
              },
              {
                name: "Recommendation Type",
                path: "/master/recomtype/list",
              },
              {
                name: "Risk Level",
                path: "/master/risklevel/list",
              },
              {
                name: "Withdrawal Reason",
                path: "/master/withdrawalReason/list",
              },
              
            ],        
          },

        ],        
      }
    ],
  },

  {
    name: "Administrator Services",
    icon: "build",
    auth: ["RAEO_OPERATOR_ADMIN", "PA_OFFICER_ADMIN", "RAEO_REGULATORY_AGENCY_ADMIN"],
    children: [
      {
        name: "Manage Account",
        path: "/accounts/manageAccount/list",
      }, 
      {
        name: "Manage Users",
        path: "/accountUser/ManageUsers/list",
      },
    ],
  },

  {
    name: "Administrator Services",
    icon: "build",
    auth: ["RA_OFFICER_ADMIN","RAEO_REVIEW_OFFICER"],
    children: [
      {
        name: "Manage Account",
        path: "/accounts/manageAccount/list",
        auth: ["RA_OFFICER_ADMIN"],
      }, 
      {
        name: "Manage Users",
        path: "/accountUser/ManageUsers/list",
        auth: ["RA_OFFICER_ADMIN"],
      },
      {
        name: "Account onboarding",
        path: "/accounts/onboarding/list",
        auth: ["RA_OFFICER_ADMIN","RAEO_REVIEW_OFFICER"],
      }, 
    ],
  },

];