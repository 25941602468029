import React from 'react';
import {
    Grid,
    Divider,
} from "@material-ui/core";

import { titleTab, useStyles } from "app/c1utils/styles";
import PageviewIcon from '@material-ui/icons/Pageview';
import IconButton from "@material-ui/core/IconButton";
import { previewPDF } from "app/c1utils/utility";

const RaeoAppDigitalMou = ({
    inputData,
    handleInputChange,
    handleDateChange,
    viewType,
    errors,
    isSubmitting }) => {

    let isDisabled = true;
    const classes = useStyles();
    const titleClasses = titleTab();

    if (viewType === 'new')
        isDisabled = false;
    else if (viewType === 'edit')
        isDisabled = false;
    else if (viewType === 'view')
        isDisabled = true;

    if (isSubmitting || inputData.status !== 'Approved')
        isDisabled = true;

    const viewFile = (fileName, data) => {
        console.log("fileName", fileName, data);
        previewPDF(fileName + ".pdf", data);
    };

    return (
        <React.Fragment>

            <Grid item lg={12} md={12} xs={12}>
                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid container item xs={12} className={titleClasses.root}>
                        MOU
                        <Divider className="mb-6" />
                    </Grid>
                </Grid>
            </Grid>
            {(inputData.appWkflStatus === 'Approved' || inputData.appWkflStatus === 'Activated') &&
                <Grid item lg={12} md={12} xs={12}>
                    <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                        <Grid container item xs={12} className={classes.root}>
                            <Grid item xs={12} >
                                View MOU
                                <IconButton aria-label="Preview" type="button"
                                    color="primary" onClick={(e) => viewFile(inputData.appId, inputData.appMou)}>
                                    <PageviewIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            <br/>
        </React.Fragment>
    );
};

export default RaeoAppDigitalMou;