import React from "react";
import {
    Grid,
    MenuItem,
} from "@material-ui/core";
import { useStyles } from "app/c1utils/styles";
import C1InputField from "app/c1component/C1InputField";
import C1SelectField from "app/c1component/C1SelectField";
import { countryList } from "app/sctcomponent/SctConstant";

const ManageOwnUserDetail = ({
    handleSubmit,
    data,
    inputData,
    handleInputChange,
    handleDateChange,
    handleInputChangeDeep,
    handleValidate,
    viewType,
    props,
    isDisabled,
    errors,
    isSubmitting }) => {

    const classes = useStyles();


    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        //setRefreshPage(refreshPage + 1);
    }

    return (
        <Grid container alignItems="flex-start" spacing={3} className={classes.gridContainer}>
            <Grid item lg={4} md={6} xs={12} >
                <C1InputField
                    label="Full Name"
                    name="accnrAplName"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 35, placeHolder: 'Full Name' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplName}
                    error={errors['accnrAplName'] !== undefined}
                    helperText={errors['accnrAplName'] || ''}
                />
                <C1InputField
                    label="ID/Passport No."
                    name="accnrAplPassNid"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 20, placeHolder: 'ID/Passport Number' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplPassNid}
                    error={errors['accnrAplPassNid'] !== undefined}
                    helperText={errors['accnrAplPassNid'] || ''}
                />
                <C1InputField
                    label="Position"
                    name="accnrAplTitle"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 35, placeHolder: 'Position of User' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplTitle}
                    error={errors['accnrAplTitle'] !== undefined}
                    helperText={errors['accnrAplTitle'] || ''}
                />
                <C1InputField
                    label="Email"
                    name="accnrAplEmail"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 128, placeHolder: 'User Email Address' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplEmail}
                    error={errors['accnrAplEmail'] !== undefined}
                    helperText={errors['accnrAplEmail'] || ''}
                />
            </Grid>

            <Grid item lg={4} md={6} xs={12} >
                <C1InputField
                    label="Contact No"
                    name="accnrAplTel"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 35, placeHolder: 'User Phone Number' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplTel}
                    error={errors['accnrAplTel'] !== undefined}
                    helperText={errors['accnrAplTel'] || ''}
                />
                <C1InputField
                    label="Address Line 1"
                    name="accnrAplAddr1"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder: 'Address Line 1' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplAddr1}
                    error={errors['accnrAplAddr1'] !== undefined}
                    helperText={errors['accnrAplAddr1'] || ''}
                />
                <C1InputField
                    label="Address Line 2"
                    name="accnrAplAddr2"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder: 'Address Line 2' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplAddr2}
                    error={errors['accnrAplAddr2'] !== undefined}
                    helperText={errors['accnrAplAddr2'] || ''}
                />
                <C1InputField
                    label="Address Line 3"
                    name="accnrAplAddr3"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder: 'Address Line 3' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplAddr3}
                    error={errors['accnrAplAddr3'] !== undefined}
                    helperText={errors['accnrAplAddr3'] || ''}
                />
            </Grid>

            <Grid item lg={4} md={6} xs={12} >
                <C1InputField
                    label="Province"
                    name="accnrAplProv"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 15, placeHolder: 'Province' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplProv}
                    error={errors['accnrAplProv'] !== undefined}
                    helperText={errors['accnrAplProv'] || ''}
                />

                <C1InputField
                    label="City/District"
                    name="accnrAplCity"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 15, placeHolder: 'City/District' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplCity}
                    error={errors['accnrAplCity'] !== undefined}
                    helperText={errors['accnrAplCity'] || ''}
                />

                <C1InputField
                    label="Postal Code"
                    name="accnrAplPcode"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 10, placeHolder: 'Postal Code' }}
                    onChange={handleInputChange}
                    value={inputData.accnrAplPcode}
                    error={errors['accnrAplPcode'] !== undefined}
                    helperText={errors['accnrAplPcode'] || ''}
                />
                <C1SelectField
                    label="Country"
                    name="TMstCountryApl.ctyCode"
                    required
                    disabled={isDisabled}
                    onChange={handleInputChangeDeepWrap}
                    value={inputData.TMstCountryApl?.ctyCode || ""}
                    optionsMenuItemArr={
                        countryList.map((item, ind) => (
                            <MenuItem value={item.value} key={ind}>{item.desc}</MenuItem>
                        ))}
                    error={errors['TMstCountryApl'] !== undefined}
                    helperText={errors['TMstCountryApl'] || ''} />

            </Grid>
        </Grid >



    );
};
export default ManageOwnUserDetail;