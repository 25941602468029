
export const RaeoAppWkflStatus = [
  "Draft",
  "Submitted",
  "Regulated",
  "Validated",
  "Recommended",
  "Approved",
  "Active",
];

export const RaeoWkflStatus = [
  "Activated",
  "Suspended",
  "Revoked",
  "Withdrawn",
  "Inactivated",
];

export const countryList = [
  { value: 'BI', desc: "Burundi" },
  { value: "KE", desc: "Kenya" },
  { value: "RW", desc: "Rwanda" },
  { value: "TZ", desc: "Tanzania" },
  { value: "UG", desc: "Uganda" }
];

export const RAEO_OPERATOR = "RAEO_OPERATOR";
export const RAEO_OPERATOR_ADMIN = "RAEO_OPERATOR_ADMIN";
export const RAEO_REVIEW_OFFICER = "RAEO_REVIEW_OFFICER";
export const RAEO_REGULATORY_AGENCY_ADMIN = "RAEO_REGULATORY_AGENCY_ADMIN";
export const RAEO_REGULATORY_AGENCY_USER = "RAEO_REGULATORY_AGENCY_USER";
export const RAEO_VALIDATION_OFFICER = "RAEO_VALIDATION_OFFICER";
export const RAEO_APPROVAL_OFFICER = "RAEO_APPROVAL_OFFICER";
export const RAEO_COMMISSIONER = "RAEO_COMMISSIONER";