import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from 'prop-types';

/**
 * @param label - form label for the field
 * @param name  - form name for the field
 * @param onChange - event handler
 * @param value - form value
 * @param disabled - boolean value to flag if field is disabled or not
 * @param required - boolean value to flag if field is required, it will be highlighted in yellow
 * @param type - can be file, input or date. Default is 'input'
 * @param multiline - boolean value to flag if field is multiline or a textarea
 * @param rows - no. of rows if the field is multiline
 * @param rowsMax - maximum no. of rows if the field is multiline
 * @param error - boolean value if the field has error upon validation
 * @param helperText - text to display if error occurs
 * @param select - boolean value to flag if the input field is select field
 * @param inputProps - additional props for input field
 * @param children - array of children to be passed to <TextField>, this is only applicable for select field.
 */
const C1InputField = ({
    label,
    name,
    onChange,
    onInput,
    value,
    disabled,
    required,
    type,
    multiline,
    rows,
    rowsMax,
    error,
    helperText,
    select,
    inputProps,
    style,
    size,
    children
}) => {

    return <TextField
        margin="normal"
        label={label}
        name={name}
        type={type || 'input'}
        fullWidth
        multiline={multiline || false}
        rows={rows}
        rowsMax={rowsMax}
        size={size || "small"}
        variant="outlined"
        onChange={onChange}
        onInput={onInput}
        value={value}
        disabled={disabled}
        required={required}
        InputLabelProps={{
            shrink: true
        }}
        helperText={helperText || ''}
        error={error ? error : false}
        select={select ? true : false}
        className={required ? "C1-Required" : ''}
        inputProps={inputProps}
        style={style}>
        {children}
    </TextField>;
}

C1InputField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    rowsMax: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    error: PropTypes.bool,
    helperText: PropTypes.string,
    select: PropTypes.bool,
    children: PropTypes.array
}


export default C1InputField;