import React, { useState, useEffect } from "react";
import { Breadcrumb, MatxLoading } from "matx";
import { useHistory } from "react-router-dom";
import {
    Card,
    Grid,
    Paper,
    Tabs,
    Tab,
    Divider
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Formik, Form } from "formik";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import C1Alert from "./C1Alert";
import C1StatusLabel from "app/c1component/C1StatusLabel";
import { tabScroll } from "app/c1utils/utility";

/**
 * Form component. 
 * Actions for buttons such as form save, submit, approve or reject may be done here.
 * Snackbar (prompt for successful form save/submit is placed here so that dev need not code for 
 * each specific form panels).
 */
const C1FormDetailsPanel = ({
    breadcrumbs,
    title,
    titleEx,
    formButtons,
    tabs,
    initialValues,
    values,
    onSubmit,
    onValidate,
    snackBarOptions,
    isLoading,
    children,
}) => {

    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (e, value) => {
        setTabIndex(value);
    }

    const [snackBarState, setSnackBarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        msg: '',
        severity: 'success'
    });


    const history = useHistory();

    const handleSnackBarClose = () => {
        setSnackBarState({ ...snackBarState, open: false });
        if (snackBarOptions && snackBarOptions.redirectPath && snackBarOptions.success) {
            //only redirect if it's success
            let url = snackBarOptions.redirectPath;
            snackBarOptions = null;
            history.push(url);
        }
    };

    useEffect(() => {
        //this is for prompting if user refreshes the page
        window.onbeforeunload = function () {
            return true;
        };
        //only change snackbar state if it's has snackBarOptions is present
        if (snackBarOptions) {
            let msg = "";
            let severity = "";
            let open = false;
            if (snackBarOptions.success) {
                open = true;
                msg = snackBarOptions.successMsg;
                severity = "success";
            } else if (snackBarOptions.error) {
                open = true;
                msg = snackBarOptions.errorMsg;
                severity = "error";
            }

            setSnackBarState(sb => { return { ...sb, open: open, msg: msg, severity: severity } });

        }

        //clean up
        return () => {
            window.onbeforeunload = null;
        };
    }, [snackBarOptions]);

    let snackBar = null;
    if (snackBarOptions && snackBarState && snackBarState.open) {

        const anchorOriginV = snackBarState.vertical;
        const anchorOriginH = snackBarState.horizontal;

        snackBar = <Snackbar
            anchorOrigin={{ vertical: anchorOriginV, horizontal: anchorOriginH }}
            open={snackBarState.open}
            onClose={handleSnackBarClose}
            autoHideDuration={snackBarState.severity === 'success' ? 2000 : 3000}
            key={anchorOriginV + anchorOriginH
            }>
            <C1Alert onClose={handleSnackBarClose} severity={snackBarState.severity}>
                {snackBarState.msg}
            </C1Alert>
        </Snackbar>;
    }


    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                {breadcrumbs && <Breadcrumb routeSegments={breadcrumbs} />}
            </div>
            {isLoading && <MatxLoading />}
            {snackBar}

            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                enableReinitialize={true}
                values={values}
                validate={onValidate}>
                {(props) => (
                    <Form>
                        <Card elevation={3}>
                            <Grid container spacing={0} alignItems="flex-start" justify="flex-start">
                                <Grid item lg={6} md={6} xs={12} >
                                    <Grid container item alignItems="flex-start" justify="flex-start">
                                        <div className="flex p-3">
                                            <Grid container alignItems="center">
                                                <Grid item lg={12} md={12} xs={12}>
                                                    <Typography variant="h5" style={{ marginTop: '10px' }}>
                                                        {title}  {titleEx && <C1StatusLabel value={titleEx || ""}></C1StatusLabel>}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12} >
                                    <Grid container item alignItems="flex-end" justify="flex-end">
                                        <div className="flex p-3">
                                            {formButtons}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider className="mb-2" />
                            {tabs && <Grid container>
                                <Grid item xs={12}>
                                    <Paper className="p-3">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Tabs
                                                    className="mt-4"
                                                    value={tabIndex}
                                                    onChange={handleTabChange}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    {tabs && tabs.map((item, ind) => (
                                                        <Tab className="capitalize" value={ind} label={item.text} key={ind} icon={item.icon} {...tabScroll(ind)} />
                                                    ))}
                                                </Tabs>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>}
                            {children(props, tabIndex)}
                        </Card>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

C1FormDetailsPanel.propTypes = {
    breadcrumbs: PropTypes.array,
    title: PropTypes.string,
    formButtons: PropTypes.element,
    tabs: PropTypes.array,
    initialValues: PropTypes.object,
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    onValidate: PropTypes.func,
    snackBarOptions: PropTypes.exact({
        success: PropTypes.bool,
        successMsg: PropTypes.string,
        error: PropTypes.any,
        errorMsg: PropTypes.string,
        redirectPath: PropTypes.string
    }),
    isLoading: PropTypes.bool,
    children: PropTypes.any
}

export default C1FormDetailsPanel;
