import React, { useState, useEffect } from "react";
import {
    Grid,
    MenuItem,
    Tooltip
} from "@material-ui/core";
import { useStyles } from "app/c1utils/styles";
import useAuth from "app/hooks/useAuth";
import C1InputField from "app/c1component/C1InputField";
import C1SelectField from "app/c1component/C1SelectField";
import { isRaeoOperator } from "app/sctcomponent/SctUtilities";
import useDebounce from 'app/c1hooks/useDebounce'
import useHttp from "app/c1hooks/http";
import { countryList } from "app/sctcomponent/SctConstant";

const TraderDetail = ({
    handleSubmit,
    data,
    inputData,
    handleInputChange,
    handleInputChangeDeep,
    handleValidate,
    viewType,
    props,
    errors,
    isDisabled,
    isSubmitting }) => {

    const { res, error, urlId, sendRequest } = useHttp();
    const [refreshPage, setRefreshPage] = useState(0);

    const classes = useStyles();
    const { user } = useAuth();



    const accnTypes = [
        { value: "ACC_TYPE_RAEO_OPERATOR", desc: "Trader / Economic Operator" }
    ];

    const handleInputChangeDeepWrap = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleInputChangeDeep(name, value);
        //setRefreshPage(refreshPage + 1);
    }

    const handleInputChangeAccnId = (e) => {
        const name = e.target.name;
        const value = e.target.value.toUpperCase();
        handleInputChangeDeep(name, value);
    }

    useDebounce(() => {
        if (inputData.accnrCoIntial && inputData.accnrCoIntial.length >= 3) {
            //sendRequest(`/sct/api/register/existingAccn/${inputData.accnrCoIntial}`, 'existingAccn');
        }
    }, 2000, [inputData.accnrCoIntial]);

    useEffect(() => {
        if (!error && res && "existingAccn" === urlId ) {
            if( !res.data || res.data.length ===0) {
                errors['accnrCoIntial'] = undefined;
            } else {
                errors['accnrCoIntial'] = res.data;
            }
            setRefreshPage(refreshPage+1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, res, urlId]);

    return (
        <Grid container alignItems="flex-start" spacing={3} className={classes.gridContainer}>
            <Grid item lg={3} md={6} xs={12} >
                {(user && !isRaeoOperator(user.role)) && <C1InputField
                    label="Account id"
                    name="accnrCoIntial"
                    disabled="true"
                    required
                    inputProps={{
                        maxLength: 20
                    }}
                    onChange={handleInputChangeAccnId}
                    value={inputData.accnrCoIntial || inputData.TMstCountry?.ctyCode+"_"+inputData.accnrCompReg}
                    error={errors['accnrCoIntial'] !== undefined}
                    helperText={errors['accnrCoIntial'] || ''} />}
                <C1SelectField
                    label="Account Type"
                    name="TMstAccnType.atypId"
                    required
                    disabled={isDisabled}
                    onChange={handleInputChangeDeepWrap}
                    value={inputData?.TMstAccnType?.atypId || ""}
                    optionsMenuItemArr={
                        accnTypes.map((item, ind) => (
                            <MenuItem value={item.value} key={ind}>{item.desc}</MenuItem>
                        ))}
                    error={errors['TMstAccnType'] !== undefined}
                    helperText={errors['TMstAccnType'] || ''}
                />

                <C1InputField
                    label="Company Name"
                    name="accnrCompName"
                    disabled={isDisabled}
                    required
                    onChange={handleInputChange}
                    inputProps={{ 'maxLength': 125, placeHolder:'Company Name'}}
                    value={inputData.accnrCompName || ""}
                    error={errors['accnrCompName'] !== undefined}
                    helperText={errors['accnrCompName'] || ''}
                />

                <C1InputField
                    label="TIN No"
                    name="accnrCompReg"
                    disabled={isDisabled}
                    required
                    onChange={handleInputChange}
                    inputProps={{ 'maxLength': 20, placeHolder:'TIN Number'}}
                    value={inputData.accnrCompReg || ""}
                    error={errors['accnrCompReg'] !== undefined}
                    helperText={errors['accnrCompReg'] || ''}
                />
            </Grid>

            <Grid item lg={3} md={6} xs={12} >
                <C1InputField
                    label="Contact No"
                    name="accnrTel"
                    disabled={isDisabled}
                    required
                    onChange={handleInputChange}
                    inputProps={{ 'maxLength': 35, placeHolder:'Company Phone Number'}}
                    value={inputData.accnrTel || ""}
                    error={errors['accnrTel'] !== undefined}
                    helperText={errors['accnrTel'] || ''}
                />
                <C1InputField
                    label="Company Email Address"
                    name="accnrEmail"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 125, placeHolder:'Company Email Address'}}
                    onChange={handleInputChange}
                    value={inputData.accnrEmail || ""}
                    error={errors['accnrEmail'] !== undefined}
                    helperText={errors['accnrEmail'] || ''}
                />
            </Grid>

            <Grid item lg={3} md={6} xs={12} >
                <C1InputField
                    label="Address Line 1"
                    name="accnrAddressLine1"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder:'Address Line 1'}}
                    onChange={handleInputChange}
                    value={inputData.accnrAddressLine1 || ""}
                    error={errors['accnrAddressLine1'] !== undefined}
                    helperText={errors['accnrAddressLine1'] || ''}
                />
                <C1InputField
                    label="Address Line 2"
                    name="accnrAddressLine2"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder:'Address Line 2'}}
                    onChange={handleInputChange}
                    value={inputData.accnrAddressLine2 || ""}
                    error={errors['accnrAddressLine2'] !== undefined}
                    helperText={errors['accnrAddressLine2'] || ''}
                />
                <C1InputField
                    label="Address Line 3"
                    name="accnrAddressLine3"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 64, placeHolder:'Address Line 3'}}
                    onChange={handleInputChange}
                    value={inputData.accnrAddressLine3 || ""}
                    error={errors['accnrAddressLine3'] !== undefined}
                    helperText={errors['accnrAddressLine3'] || ''}
                />
            </Grid>

            <Grid item lg={3} md={6} xs={12} >
                <C1InputField
                    label="Province"
                    name="accnrProv"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 15, placeHolder:'Province'}}
                    onChange={handleInputChange}
                    value={inputData.accnrProv || ""}
                    error={errors['accnrProv'] !== undefined}
                    helperText={errors['accnrProv'] || ''}
                />

                <C1InputField
                    label="City/District"
                    name="accnrCity"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 15, placeHolder:'City/District'}}
                    onChange={handleInputChange}
                    value={inputData.accnrCity || ""}
                    error={errors['accnrCity'] !== undefined}
                    helperText={errors['accnrCity'] || ''}
                />

                <C1InputField
                    label="Postal Code"
                    name="accnrPcode"
                    disabled={isDisabled}
                    required
                    inputProps={{ 'maxLength': 10, placeHolder:'Postal Code'}}
                    onChange={handleInputChange}
                    value={inputData.accnrPcode || ""}
                    error={errors['accnrPcode'] !== undefined}
                    helperText={errors['accnrPcode'] || ''}
                />

                <C1SelectField
                    label="Country"
                    name="TMstCountry.ctyCode"
                    required
                    disabled={isDisabled}
                    onChange={handleInputChangeDeepWrap}
                    value={inputData.TMstCountry?.ctyCode || ""}
                    optionsMenuItemArr={
                        countryList.map((item, ind) => (
                            <MenuItem value={item.value} key={ind}>{item.desc}</MenuItem>
                        ))}
                    error={errors['TMstCountry'] !== undefined}
                    helperText={errors['TMstCountry'] || ''}
                />
            </Grid>
        </Grid>
    );
};
export default TraderDetail;