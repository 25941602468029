import React from "react";
import { Redirect } from "react-router-dom";

import configurationRoutes from "./views/configurations/configurationRoutes";
import masterTableRoutes from "./views/configurations/masterTableRoutes";
import applicationRoutes from "./views/applications/applicationRoutes"

import workBenchRoutes from "./views/workbench/workbenchRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/workbench" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...applicationRoutes,
  ...masterTableRoutes,
  ...configurationRoutes,
  ...workBenchRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
