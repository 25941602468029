import React, { useState } from "react";

import {
    Grid,
    Box,
    Button,
    Paper,
    Icon
} from "@material-ui/core";

import C1HeaderTitle from "app/c1component/C1HeaderTitle";

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from "app/c1utils/styles";

import C1InputField from "app/c1component/C1InputField";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";
import C1PopUp from "app/c1component/C1PopUp";
import IconButton from "@material-ui/core/IconButton";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#3C77D0',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const RaeoHistory = ({
    auditList }) => {

    const classes = useStyles();
    const [openPopUp, setOpenPopUp] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");

    const handlePopUpReplyWindow = (id) => {
        console.log("id", id);
        setSelectedRow(auditList[id]);
        setOpenPopUp(true);
    }

    return (
        <React.Fragment>
            <C1HeaderTitle>History</C1HeaderTitle>
                <Box width={1} className="mt-3">
                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" style={{ tableLayout: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">No.</StyledTableCell>
                                        <StyledTableCell align="left">Action Date</StyledTableCell>
                                        <StyledTableCell align="left">Action By</StyledTableCell>
                                        <StyledTableCell align="left"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auditList && auditList.map((row, ind) => (
                                        <TableRow key={ind}>
                                            <StyledTableCell align="left">{ind + 1}</StyledTableCell>
                                            <StyledTableCell>{moment(row.aeohDtCreate).format('DD/MM/YYYY hh:mm:ss')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.TCoreUsr?.usrName}</StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton type="button" color="primary" onClick={() => handlePopUpReplyWindow(ind)}>
                                                    <Icon>visibility</Icon>
                                                </IconButton>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        (!auditList || auditList.length === 0) && <TableRow key={1} >
                                            <StyledTableCell colSpan="8"><p style={{ textAlign: "center" }}>Sorry, no matching records found</p></StyledTableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                </Grid>
            </Box>
            <C1PopUp
                title="Corrections"
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}>
                <Grid container spacing={1} alignItems="center" >
                    <Grid item xs={12}>
                        <C1InputField
                            label="Corrections / Additional Information"
                            name="reason"
                            multiline
                            rows={10}
                            value={selectedRow.aeohCorrection || ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3}>
                        {(false) && <Button variant="contained"
                            color="secondary"
                            size="large"
                            fullWidth
                            onClick={(e) => setOpenPopUp(false)}>Close</Button>}
                    </Grid>
                </Grid>
            </C1PopUp>

        </React.Fragment>
    );
};

export default RaeoHistory;