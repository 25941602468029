import * as SctConstant from "app/sctcomponent/SctConstant";

export const RAEO_APP_TAB_DETAIL = "Detail";
export const RAEO_APP_TAB_REVIEW = "RiviewAcceptance";
export const RAEO_APP_TAB_REGULATORY = "RegulatoryAgency";
export const RAEO_APP_TAB_VALIDATION = "AssessmentValidation";
export const RAEO_APP_TAB_COMMITTEE = "CommitteeRecommendation";
export const RAEO_APP_TAB_APPROVAL = "Approval";
export const RAEO_APP_TAB_MOU = "Mou";
export const RAEO_APP_TAB_ACTIVATION = "Activation";
export const RAEO_APP_TAB_QUERY = "Query";
export const RAEO_APP_TAB_AUDIT = "Audit";

export const RaeoAppRoleTabs = {
    [SctConstant.RAEO_REVIEW_OFFICER]: [RAEO_APP_TAB_REVIEW],
    [SctConstant.RAEO_REGULATORY_AGENCY_ADMIN]: [RAEO_APP_TAB_REVIEW, RAEO_APP_TAB_REGULATORY],
    "RAEO_REGULATORY_AGENCY_USER": [RAEO_APP_TAB_REVIEW, RAEO_APP_TAB_REGULATORY],
    "RAEO_VALIDATION_OFFICER": [RAEO_APP_TAB_REVIEW, RAEO_APP_TAB_REGULATORY, RAEO_APP_TAB_VALIDATION],
    "RAEO_APPROVAL_OFFICER": [RAEO_APP_TAB_REVIEW, RAEO_APP_TAB_REGULATORY, RAEO_APP_TAB_VALIDATION, RAEO_APP_TAB_COMMITTEE],
    "RAEO_COMMISSIONER": [RAEO_APP_TAB_REVIEW, RAEO_APP_TAB_REGULATORY, RAEO_APP_TAB_VALIDATION, RAEO_APP_TAB_COMMITTEE, RAEO_APP_TAB_APPROVAL]
};

//
export const getPermissionTabs = (role, tabList, appStatus) => {
    if (!role) {
        return [];
    }
    let roles = role.split(",");
    let tabs = [];

    //tabList.forEach((tab) => {
    for (let i = 0; i < tabList.length; i++) {
        let tab = tabList[i];
        if (RAEO_APP_TAB_DETAIL === tab.permTab
            || RAEO_APP_TAB_QUERY === tab.permTab
            || RAEO_APP_TAB_AUDIT === tab.permTab) {

            tabs.push(tab);

        } else if (RAEO_APP_TAB_MOU === tab.permTab
            || RAEO_APP_TAB_ACTIVATION === tab.permTab) {

            if ("Activated" === appStatus) {
                tabs.push(tab);
            } else if ("Approved" === appStatus && (roles.includes("RAEO_REVIEW_OFFICER")
                || roles.includes("RAEO_REGULATORY_AGENCY_ADMIN")
                || roles.includes("RAEO_REGULATORY_AGENCY_USER")
                || roles.includes("RAEO_VALIDATION_OFFICER")
                || roles.includes("RAEO_APPROVAL_OFFICER"))) {

                tabs.push(tab);

            } else {
                //roles.forEach((roleName) => {
                for (let j = 0; j < roles.length; j++) {
                    let roleName = roles[j];
                    let permTabs = RaeoAppRoleTabs[roleName];
                    if (permTabs && permTabs.includes(tab.permTab)) {
                        tabs.push(tab);
                        break;
                    }
                }
            }
        } else {
            for (let j = 0; j < roles.length; j++) {
                let roleName = roles[j];
                let permTabs = RaeoAppRoleTabs[roleName];
                if (permTabs && permTabs.includes(tab.permTab)) {
                    tabs.push(tab);
                }
            }
        }
    }
    return tabs;
}


